import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Tooltip } from '@mui/material'


const InfoBadge = ({ title, children }) => (
  <Tooltip title={title} sx={{ cursor: 'arrow' }}>
    <Badge badgeContent='?' color='info' slotProps={{ badge: { sx: { right: '-12px', cursor: 'default' } } }}>
      {children}
    </Badge>
  </Tooltip>
)


InfoBadge.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
}

export default InfoBadge
