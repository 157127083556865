import React from 'react'
import { Stack } from '@mui/material'
import OperationTable from './OperationTable'


export const OperationPanel = () => (
  <Stack spacing={2} p={2}>
    <OperationTable />
  </Stack>
)

export default OperationPanel
