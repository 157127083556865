import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Stack, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { AIRCRAFT_COLLECTION, HIL_ITEM_COLLECTION, ITEM_COLLECTION, TANK_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { pickBy } from 'lodash'
import { serverTimestamp, deleteField } from 'firebase/firestore'
import LiquidGraph from '../_library/LiquidGraph'
import DeleteButton from '../_library/DeleteButton'
import InfoBadge from '../_library/InfoBadge'
import { useFirestore } from '../../hooks/useFirestore'
import { isNil } from 'lodash/lang'
import { mergeCollections } from '../../_helpers/jsHelper'
import { AttachFile, FilePresent } from '@mui/icons-material'
import VisuallyHiddenInput from '../_library/VisuallyHiddenInput'
import { useStorage } from '../../hooks/useStorage'
import Avatar from '../_library/Avatar'


const EditActionDialog = ({ item, onClose }) => {

  const profile = useSelector(state => state.profile.data)
  const itemHooks = useFirestore(ITEM_COLLECTION)
  const tankHooks = useFirestore(TANK_COLLECTION)
  const dbTanks = tankHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const hilHooks = useFirestore(HIL_ITEM_COLLECTION)
  const hilItems = hilHooks.getDocs()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)
  const storageHook = useStorage('actions')

  const [requestTanks, setRequestTanks] = useState(null)
  const [action, setAction] = useState(item.action)
  const [actionAttachment, setActionAttachment] = useState(item.actionAttachmentUrl)
  const [fulFilledRequest, setFulFilledRequest] = useState(item.fulFilledRequest || false)
  const [openHil, setOpenHil] = useState(!!item.actionHilRef || false)
  const [hilTitle, setHilTitle] = useState(item.actionHil?.title || null)
  const [hilDescription, setHilDescription] = useState(item.actionHil?.description || null)
  const [trigram, setTrigram] = useState(null)
  const [pin, setPin] = useState(null)

  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    item.aircraftRef && hilHooks.listen({
      where: [['aircraftRef', '==', aircraftHooks.getDocRef(selectedAircraftId)]],
      orderBy: [['number', 'asc']],
    })
    return () => hilHooks.unsubscribe()
  }, [item.aircraftRef])

  useEffect(() => {
    console.info('item id', item.id)
  }, [item.id])

  useEffect(() => {
    if (dbTanks && item.type === 'fuelRequest') setRequestTanks(mergeCollections(dbTanks, item.tanks || [], 'id'))
  }, [item.tanks, item.type, dbTanks])

  const user = useMemo(() => dbUsers.find(u => u.trigram === trigram && u.pin === pin), [trigram, pin])

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitted(true)
    const obj = pickBy({
      ...item,
      action,
      actionAuthorRef: userHooks.getDocRef(user.id),
      actionDate: serverTimestamp(),
      fulFilledRequest,
    }, val => !isNil(val))
    if (actionAttachment instanceof File) obj.actionAttachmentUrl = await storageHook.uploadFileAction(actionAttachment, actionAttachment.name)
    else if (!actionAttachment) obj.actionAttachmentUrl = deleteField()
    const batch = itemHooks.getBatch()
    batch.update(itemHooks.getDocRef(item.id), { ...obj, _updatedAtTime: serverTimestamp() })
    if (fulFilledRequest)
      requestTanks.filter(tank => !!tank.request).forEach(tank => batch.update(tankHooks.getDocRef(tank.id), { quantity: tank.request }))
    if (openHil)
      batch.set(item.actionHilRef || hilHooks.getDocRef(), pickBy({
        _createdAtTime: serverTimestamp(),
        _createdByRef: userHooks.getDocRef(profile.id),
        aircraftRef: item.aircraftRef,
        description: hilDescription,
        title: hilTitle,
        openingItemRef: itemHooks.getDocRef(item.id),
      }), { merge: true })
    if (item.defectHilRef && action)
      batch.update(item.defectHilRef, {
        closureDate: serverTimestamp(),
        closureItemRef: itemHooks.getDocRef(item.id),
      })
    return batch.commit()
      .then(onClose)
      .finally(() => setSubmitted(false))
  }

  if (!item) return <Loading />
  else return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-item' component='form' onSubmit={handleSubmit}>
      <DialogTitle>
        {item.label}
        <Typography variant='caption'>Aircraft: {item.aircraft?.serial}</Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        {item.type === 'fuelRequest' && <LiquidGraph
          tanks={item.requestTanks}
          field='request'
          disabled
        />}
        {item.type !== 'fuelRequest' && hilItems && <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id='hil-select-label'>Create item from HIL</InputLabel>
          <Select
            labelId='hil-select-label'
            id='hil-select'
            value={item.defectHil?.id || ''}
            label='Create item from HIL'
            disabled
          >
            <MenuItem value=''><i>none</i></MenuItem>
            {hilItems?.map(i => <MenuItem key={i.id} value={i.id}>{i.number} - {i.title}</MenuItem>)}
          </Select>
        </FormControl>}
        <TextField
          variant='outlined'
          margin='dense'
          id='defect'
          label={item.type === 'fuelRequest' ? 'Comment' : 'Defect'}
          fullWidth
          value={item.defect || ''}
          multiline
          disabled
        />
        <Divider sx={{ my: 2 }} />
        <Stack direction='row' alignItems='center' spacing={1}>
          <TextField
            variant='outlined'
            margin='dense'
            id='trigram'
            label='Trigram'
            value={trigram || ''}
            onChange={e => setTrigram(e.target.value)}
          />
          <TextField
            variant='outlined'
            margin='dense'
            id='pin'
            label='pin'
            value={pin || ''}
            onChange={e => setPin(e.target.value)}
            type='password'
          />
          <Avatar user={user} />
        </Stack>
        {item.id && <>
          <Divider sx={{ my: 2 }} />
          {item.type === 'fuelRequest'
            ? <>
              <Switch checked={fulFilledRequest} onChange={e => setFulFilledRequest(e.target.checked)} />
              <InfoBadge title='Ticking this box will update aircraft fuel with requested values'>Fulfilled request</InfoBadge>
            </>
            : <>
              <Switch checked={openHil} onChange={e => setOpenHil(e.target.checked)} />
              <InfoBadge title='Ticking this box will create new HIL item'>Create HIL item</InfoBadge>
            </>
          }
          {actionAttachment
            ? <Tooltip title='Remove attachment'>
              <IconButton onClick={() => setActionAttachment(null)} sx={{ position: 'absolute', right: theme => theme.spacing(2) }}>
                <FilePresent />
              </IconButton>
            </Tooltip>
            : <Tooltip title='Upload attachment'>
              <IconButton component='label' sx={{ position: 'absolute', right: theme => theme.spacing(2) }}>
                <AttachFile />
                <VisuallyHiddenInput type='file' onChange={e => setActionAttachment(e.target.files[0])} />
              </IconButton>
            </Tooltip>
          }
          {openHil && <Paper elevation={0} sx={{ bgcolor: 'grey.200', p: 1 }}>
            <TextField
              variant='outlined'
              margin='dense'
              id='hilTitle'
              label='HIL Title'
              fullWidth
              value={hilTitle || ''}
              onChange={e => setHilTitle(e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='dense'
              id='hilDescription'
              label='HIL Description'
              fullWidth
              value={hilDescription || ''}
              multiline
              rows={3}
              onChange={e => setHilDescription(e.target.value)}
            />
          </Paper>}
          <TextField
            variant='outlined'
            margin='dense'
            id='action'
            label='Action'
            fullWidth
            value={action || ''}
            multiline
            rows={3}
            onChange={e => setAction(e.target.value)}
          />
        </>}
      </DialogContent>
      <DialogActions>
        {!submitted && <Button type='submit' disabled={!user}>Submit</Button>}
        {item.id && <DeleteButton deleteAction={() => itemHooks.deleteDoc(item.id).then(onClose)} text='Supprimer' />}
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditActionDialog.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditActionDialog
