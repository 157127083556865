import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { Box, Menu, MenuItem } from '@mui/material'
import withStyles from '@mui/styles/withStyles'
import hatch from '../../images/hatch.png'
import { differenceInMinutes, addHours, startOfDay, addMinutes, format } from 'date-fns'
import { useSelector } from 'react-redux'
import { Build, Create, Delete } from '@mui/icons-material'
import TestRequestDialog from '../tests/TestRequestDialog'
import { useNavigate } from 'react-router-dom'
import WriteAvailabilityDialog from './WriteAvailabilityDialog'
import { TEST_COLLECTION } from '../../_constants/globals'
import { TESTS } from '../../_constants/routes'
import { useFirestore } from '../../hooks/useFirestore'


const styles = () => ({
  line: {
    borderLeft: '1px solid #ccc',
  },
  lineStart: {
    backgroundColor: '#f2f2f2',
    borderLeftColor: 'transparent',
  },
  pseudoBorder: {
    height: 4,
    width: '100%',
    background: 'transparent',
  },
  blank4ElasticLine: {
    verticalAlign: 'bottom',
    borderLeft: '1px solid #ccc',
  },
  last: {
    borderStyle: 'solid',
    borderRightColor: '#ccc',
    borderRightWidth: 1,
  },
  unavailable: {
    background: `#f2f2f2 url(${hatch})`,
    borderLeft: '1px solid #ccc',
  },
  reserved: {
    textAlign: 'center',
    cursor: 'default',
    backgroundColor: '#BF3FA6',
  },
  myBooking: {
    cursor: 'pointer',
    backgroundColor: '#6EC402',
  },
  icons: {
    marginRight: 10,
  },
})

const makeCells = (resource, bookings, availabilities, classes, bookFlight, setAnchorEl, day, profile, navigate, type, setOpenAvailability) => {
  // Create a 48 long array of free or unavailable slots
  let arr = Array.from(Array(48)).map((val, key) =>
    ({
      slot: resource.bookable ? 'free' : 'unavailable',
      start: key,
    }),
  )
  
  // Replace free slot with bookings
  if (bookings)
    arr = bookings.reduceRight((acc, val) => {
      const startIndex = differenceInMinutes(val.startDate, addHours(startOfDay(val.startDate), 8)) / 15
      const bookLength = val.endDate
        ? differenceInMinutes(val.endDate, addHours(startOfDay(day), 8)) / 15
        : 8
      for (let i = startIndex; i < Math.min(startIndex + bookLength, 48); i++)
        acc[i] = {
          slot: 'book',
          start: startIndex,
          type: val.testType,
          testId: val.testId,
          user: val.users && val.users[0],
          userId: val.users && val.users[0] && val.users[0].id,
          id: val.id,
        }
      return acc
    }, arr)

  // Replace free slots with availabilities
  if (availabilities)
    arr = availabilities.reduceRight((acc, val) => {
      const startIndex = differenceInMinutes(val.startTime, addHours(startOfDay(val.startTime), 8)) / 15
      const bookLength = differenceInMinutes(val.endTime, val.startTime) / 15
      for (let i = Math.max(0, startIndex); i < startIndex + bookLength; i++) {
        if (acc[i] && acc[i].slot === 'unavailable')
          acc[i].slot = 'free'
      }
      return acc
    }, arr)

  // Groups timeslots
  const data = []
  let beginning
  for (let i = 0; i < arr.length; i++) {
    if (beginning !== undefined
      && arr[i].slot === arr[beginning].slot
      && arr[i].slot !== 'free'
      && (arr[i].slot === 'unavailable' || (arr[beginning].id && arr[beginning].id === arr[i].id))
    )
      data[data.length - 1].length++
    else {
      data.push({
        ...arr[i],
        length: 1,
      })
      beginning = i
    }
  }

  // Make http cells
  const cells = data.map((timeslot, index) => {
    switch (timeslot.slot){
      case 'free':
        return <Box
          component='td'
          key={timeslot.start}
          colSpan='1'
          title={type === 'aircraft' ? 'RESERVER' : null}
          onClick={() => {
            if (type === 'aircraft') bookFlight(timeslot.start)
            else {
              let firstFreeSlot = timeslot
              for (let i = index; i >= 0 && data[i].slot === 'free'; i--) firstFreeSlot = data[i]
              const slotStart = addMinutes(new Date(format(day, 'yyyy-MM-dd') + 'T08:00'), 15 * firstFreeSlot.start)
              const foundedAvailability = availabilities.find(a => a.startTime.getMilliseconds() === slotStart.getTime())
              setOpenAvailability(foundedAvailability)
            }
          }}
          sx={{
            backgroundColor: '#f2f2f2',
            borderLeft: '1px solid #FFF',
            borderColor: 'rgba(0, 0, 0, .1)',
            cursor: 'pointer',
          }}
        >&nbsp;</Box>
      case 'book':
        return <td
          key={timeslot.start}
          id={timeslot.id}
          colSpan={timeslot.length}
          onClick={e => {setAnchorEl(e.currentTarget)}}
          className={classes.reserved}
          style={{ cursor: 'pointer' }}
        >
          <Box sx={{ fontWeight: 'bold' }}>
            {timeslot.type?.slice(0, 1).toUpperCase() + timeslot.testId}
          </Box>
        </td>
      case 'unavailable':
        return <td
          key={timeslot.start}
          title='Non réservable'
          colSpan={timeslot.length}
          className={classes.unavailable}
        >&nbsp;</td>
      default:
        return null
    }
  })
  
  return cells
}

const DailyRow = ({ classes, resource, bookings, availabilities, day, type }) => {
  
  const profile = useSelector(state => state.profile.data)
  const navigate = useNavigate()
  const testHooks = useFirestore(TEST_COLLECTION)
  
  const [openQuickEdit, setOpenQuickEdit] = useState(false)
  const [startDate, setStartDate] = useState(day)
  const [selectedEditTest, selectEditTest] = useState(null)
  const [anchorEl, setAnchorEl] = useState(null)
  const [openAvailability, setOpenAvailability] = useState(null)
  
  const bookFlight = key => {
    setStartDate(addMinutes(addHours(startOfDay(day), 8 + key / 4), (key % 4) * 15))
    setOpenQuickEdit(true)
  }
  
  const cells = makeCells(resource, bookings, availabilities, classes, bookFlight, setAnchorEl, day, profile, navigate, type, setOpenAvailability)
  
  return (
    <>
      <tr className='rule before-line'>
        <th>
          <div className={classes.pseudoBorder} />
        </th>
        <td colSpan='2' className='s'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
        </td>
        <td className='line-end' colSpan='2'>
          <div className={classes.pseudoBorder} />
        </td>
      </tr>
      <tr className={classes.line}>
        <Box component='th' colSpan={3} sx={{
          position: 'relative',
          padding: '0 0 0 3px',
          background: '#f2f2f2',
        }}>
          <Box sx={{
            width: '100%',
            padding: '.3em .3em .3em 3px',
            lineHeight: '1.2em',
            textAlign: 'left',
          }}>
            {resource.serial}<br />{resource.type}
            {resource.firstname}
          </Box>
        </Box>
        {cells}
      </tr>
      <tr className='rule after-line'>
        <th>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </th>
        <td colSpan='2' className='s'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className={classes.blank4ElasticLine} colSpan='4'>
          <div className={classes.pseudoBorder} />
          <div className={classes.bottom} />
        </td>
        <td className='line-end' colSpan='2'>
          <div className={classes.pseudoBorder} />
        </td>
      </tr>
      
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={() => {
          selectEditTest(bookings.find(b => b.id === anchorEl.id))
          setOpenQuickEdit(true)
          setAnchorEl(null)
        }}>
          <Build className={classes.icons} />Setup
        </MenuItem>
        <MenuItem onClick={() => {
          navigate(TESTS + '/' + anchorEl.id)
          setAnchorEl(null)
        }}>
          <Create className={classes.icons} />Details
        </MenuItem>
        <MenuItem onClick={() => {setAnchorEl(null); return testHooks.deleteDoc(anchorEl.id)}}>
          <Delete className={classes.icons} />Supprimer
        </MenuItem>
      </Menu>
      
      {openQuickEdit && <TestRequestDialog open onClose={() => {selectEditTest(null); setOpenQuickEdit(null)}} startDateInit={startDate} aircraftInitId={resource.id} test={selectedEditTest} />}
      {openAvailability && <WriteAvailabilityDialog open onClose={() => setOpenAvailability(null)} dayInit={day} availability={openAvailability} />}
    </>
  )
}

DailyRow.propTypes = {
  classes: PropTypes.object.isRequired,
  resource: PropTypes.object,
  bookings: PropTypes.array,
  availabilities: PropTypes.array,
  day: PropTypes.object.isRequired,
  type: PropTypes.oneOf(['aircraft', 'instructor']).isRequired,
}

export default compose(
  withStyles(styles),
)(DailyRow)
