import ApiCalendar from 'react-google-calendar-api'


/**
 * You will need to enable the "Google Calendar API"(https://console.developers.google.com/flows/enableapi?apiid=calendar.)
 * You will need a clientId and ApiKey from Google(https://developers.google.com/workspace/guides/create-credentials)
 */

const apiCalendar = new ApiCalendar({
  clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  apiKey: '1:583899971384:web:d787dd0231165f36d04bcd', // process.env.REACT_APP_FIREBASE_API_KEY,
  scope: 'https://www.googleapis.com/auth/calendar',
  discoveryDocs: [
    'https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest',
  ],
})

export const signIn = apiCalendar.handleAuthClick
export const signOut = apiCalendar.handleSignoutClick
export const listCalendars = () => console.log(apiCalendar.listCalendars())
export const listEvents = () => apiCalendar
  .listUpcomingEvents(1)
  .then(({ result }) => {
    console.log(result.items.map(i => i.summary))
    console.log(result)
  })
export const createEvent = () => apiCalendar
  .createEventFromNow({
    summary: 'Poc Dev From Now',
    time: 480,
  })
  .then(console.log)
  .catch(console.error)

export const getWorkingLocations = options => apiCalendar
  .listEvents({
    eventTypes: ['workingLocation'],
    showDeleted: false,
    singleEvents: true,
    ...options,
  })
