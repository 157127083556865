import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import PlanningRow from './DailyRow'
import { AIRCRAFT_COLLECTION, AVAILABILITY_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import { endOfDay, startOfDay } from 'date-fns'
import Loading from '../_library/Loading'
import { chain } from 'lodash'
import { Box } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'


const hoursStyle = {
  textAlign: 'center',
  fontSize: '11px',
  color: '#777',
}
const pseudoBorder = {
  height: 4,
  width: '100%',
  background: 'transparent',
}
const blank4ElasticLine = {
  verticalAlign: 'bottom',
  borderLeft: '1px solid #ccc',
}
const DailyTable = ({ day }) => {

  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  const testHooks = useFirestore(TEST_COLLECTION)
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()
  const dailyAvailabilities = availabilityHooks.getDocs('dailyAvailabilities')
  const tests = testHooks.getDocs('dailyTests')

  useEffect(() => {
    availabilityHooks.listen({
      where: [['dayDate', '==', startOfDay(day)]],
      storeAs: 'dailyAvailabilities',
    })
    testHooks.listen({
      where: [
        ['startDate', '>=', startOfDay(day)],
        ['startDate', '<=', endOfDay(day)],
      ],
      orderBy: [['startDate', 'asc']],
      storeAs: 'dailyTests',
    })
    return () => {
      availabilityHooks.unsubscribe()
      testHooks.unsubscribe()
    }
  }, [day])

  const users = dailyAvailabilities && chain(dailyAvailabilities).groupBy('userRef.id').toPairs().value()

  if (!aircrafts) return <Loading />

  return (
    <Box component='table' sx={{
      tableLayout: 'fixed',
      textAlign: 'right',
      borderSpacing: 0,
      width: '100%',
      '& th': {
        fontSize: '11px',
        color: '#777',
      },
    }}>
      <thead>
        <tr>
          <Box component='th' sx={{ fontWeight: 'normal' }}>H</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>08</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>09</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>10</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>11</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>12</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>13</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>14</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>15</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>16</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>17</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>18</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>19</Box>
          <Box component='td' colSpan='4' sx={hoursStyle}>20</Box>
        </tr>
        <Box component='tr' sx={{ fontSize: 0, height: 3 }}>
          <th>
            <Box sx={pseudoBorder} />
          </th>
          <td colSpan='2' className='s'>
            <Box sx={pseudoBorder} />
          </td>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
          <Box component='td' colSpan='4' sx={blank4ElasticLine}>
            <Box sx={pseudoBorder} />
          </Box>
        </Box>
      </thead>
      <tbody>
        {aircrafts.map(aircraft =>
          <PlanningRow
            key={aircraft.id}
            resource={aircraft}
            day={day}
            bookings={tests?.filter(f => f.aircraftRef?.id === aircraft.id)}
            type='aircraft'
          />,
        )}
        <Box component='tr' sx={{ height: 30 }} />
        {users?.map(([key, availabilities]) =>
          <PlanningRow
            key={key}
            resource={{ name: availabilities[0].user?.firstname, ...availabilities[0].user }}
            availabilities={availabilities}
            day={day}
            bookings={tests?.filter(f => f.users?.some(u => u.id === key))}
            type='instructor'
          />,
        )}
      </tbody>
    </Box>
  )
}

DailyTable.propTypes = {
  day: PropTypes.object.isRequired,
}

export default DailyTable
