/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, IconButton, Tooltip } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { format } from 'date-fns'
import { Add } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION, OPERATION_COLLECTION } from '../../_constants/globals'
import EditOperationDialog from './EditOperationDialog'
import { useSelector } from 'react-redux'
import { getRecurrenceTip } from '../../_helpers/dataHelper'


const OperationTable = ({ disableAdd }) => {

  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)
  const operationHooks = useFirestore(OPERATION_COLLECTION, { storeAs: selectedAircraftId + '-ops' })
  const operations = operationHooks.getDocs()
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [selectedOperationIndex, setSelectedOperationIndex] = useState(null)

  const selectedOperation = useMemo(
    () => {
      if (selectedOperationIndex === 'new')
        return ({})
      else if (operations)
        return operations[selectedOperationIndex]
    }, [selectedAircraftId, selectedOperationIndex])

  useEffect(() => {
    if (selectedAircraftId) {
      operationHooks.listen({
        where: [['aircraftRef', '==', aircraftHooks.getDocRef(selectedAircraftId)]],
        orderBy: [['_createdAtTime', 'asc']],
      })
      return operationHooks.unsubscribe
    }
  }, [selectedAircraftId])

  const data = useMemo(() =>
    operations?.map(({ title, trigger, flightHourLimit, deadlineDate, _createdAtTime }) =>
      [title, trigger, flightHourLimit || deadlineDate, [trigger, deadlineDate, flightHourLimit], _createdAtTime],
    ), [operations])

  return (
    <Box sx={{ mx: 'auto', maxWidth: '1200px' }}>
      <MUIDataTable
        data={data}
        title='Operations'
        columns={[
          { name: 'Title' },
          { name: 'Type of deadline', options: { display: 'false' } },
          { name: 'Deadline', options: { display: 'false', customBodyRender: val => val instanceof Date ? format(val, 'dd-MM-yyyy') : `${val}h` } },
          { name: 'When', options: { customBodyRender: val => getRecurrenceTip(...val) } },
          {
            name: '_createdAtTime',
            options: {
              display: 'false',
              filter: false,
              customBodyRender: date => date && format(date, 'dd-MM-yyyy'),
            },
          },
        ]}
        options={{
          selectableRows: 'none',
          responsive: 'standard',
          onRowClick: (row, { dataIndex }) => setSelectedOperationIndex(dataIndex),
          download: false,
          search: false,
          print: false,
          selectableRowsHeader: false,
          pagination: false,
          sort: false,
          textLabels: { body: { noMatch: 'No item' } },
          setRowProps: () => ({ sx: { cursor: 'pointer' } }),
          customToolbar: () => (
            <Tooltip title='Add an operation'>
              <span>
                <IconButton color='primary' onClick={() => setSelectedOperationIndex('new')} disabled={disableAdd || readOnlyMode }>
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
          ),
        }}
      />
      {selectedOperation && <EditOperationDialog operation={selectedOperation} onClose={() => setSelectedOperationIndex(null)} />}
    </Box>
  )
}

OperationTable.propTypes = {
  disableAdd: PropTypes.bool,
}

export default OperationTable
