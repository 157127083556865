import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogTitle, DialogActions } from '@mui/material'


function ConfirmButton({ children, onClick, title, ...props }) {
  
  const [activated, setActivated] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  
  const action = () => {
    setSubmitted(true)
    // eslint-disable-next-line
    onClick().then(() => {
      setActivated(false)
      setSubmitted(false)
    })
  }
  
  return <>
    <Button onClick={() => setActivated(true)} {...props}>{children}</Button>
    <Dialog open={activated} onClose={() => setActivated(false)}>
      <DialogTitle>{title || 'Are you sure ?'}</DialogTitle>
      <DialogActions>
        {!submitted && <Button onClick={action} color='success'>Yes</Button>}
        <Button onClick={() => setActivated(false)} color='error'>No</Button>
      </DialogActions>
    </Dialog>
  </>
}

ConfirmButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string,
}

export default ConfirmButton
