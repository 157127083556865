export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const WARNING = 'WARNING'
export const INFO = 'INFO'
export const LOADING = 'LOADING'

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_ERROR = 'LOGIN_ERROR'
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const LISTEN_PROFILE = 'LISTEN_PROFILE'
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS'
export const PASSWORD_RESET_ERROR = 'PASSWORD_RESET_ERROR'
export const SIGNIN_EMAIL_SUCCESS = 'SIGNIN_EMAIL_SUCCESS'
export const SIGNIN_EMAIL_ERROR = 'SIGNIN_EMAIL_ERROR'
export const CONFIRM_EMAIL_SUCCESS = 'CONFIRM_EMAIL_SUCCESS'
export const CONFIRM_EMAIL_ERROR = 'CONFIRM_EMAIL_ERROR'
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS'
export const PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR'
export const RESET_PASSWORD_EMAIL_SUCCESS = 'RESET_PASSWORD_EMAIL_SUCCESS'
export const RESET_PASSWORD_EMAIL_ERROR = 'RESET_PASSWORD_EMAIL_ERROR'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'

export const HIDE_NOTIFICATIONS = 'HIDE_NOTIFICATIONS'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'
export const UPLOAD_ERROR = 'UPLOAD_ERROR'
export const UPLOAD_COMPLETE = 'UPLOAD_COMPLETE'

export const LISTEN_COLLECTION = 'LISTEN_COLLECTION'
export const AUTH_ERROR = 'AUTH_ERROR'

export const RESET_CACHE = 'RESET_CACHE'
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
export const DELETE_NOTIFICATION = 'DELETE_NOTIFICATION'
export const NOTIFICATION = 'NOTIFICATION'

export const SELECT_USER = 'SELECT_USER'
export const TOGGLE_MENU_DRAWER = 'TOGGLE_MENU_DRAWER'
export const EMAIL_SENT = 'EMAIL_SENT'
export const EMAIL_ERROR = 'EMAIL_ERROR'

export const SELECT_AIRCRAFT = 'SELECT_AIRCRAFT'

export const LISTEN_TESTS = 'LISTEN_TESTS'
export const SELECT_TEST = 'SELECT_TEST'

export const TOGGLE_ADMIN_MODE = 'TOGGLE_ADMIN_MODE'
export const TOGGLE_READ_ONLY_MODE = 'TOGGLE_READ_ONLY_MODE'
