import React from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, Box, useTheme, useMediaQuery } from '@mui/material'
import TestRequestForm from './TestRequestForm'


export const TestRequestDialog = ({ open, onClose, startDateInit, aircraftInitId, test }) => {

  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      sx={{
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 700,
        },
        '& .MuiInput-underline::before': {
          borderBottomColor: 'rgba(0,0,0,.1)',
        },
      }}
    >
      <DialogTitle id='form-dialog-title'>Test request</DialogTitle>
      <Box p={1} pb={{ xs: '25px', md: 1 }}>
        <TestRequestForm onClose={onClose} startDateInit={startDateInit} aircraftInitId={aircraftInitId} test={test} />
      </Box>
    </Dialog>
  )
}

TestRequestDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  startDateInit: PropTypes.object,
  aircraftInitId: PropTypes.string,
  test: PropTypes.object,
}

export default TestRequestDialog
