import React, { useState } from 'react'
import { Button, TextField, Stack } from '@mui/material'
import useAuth from '../../hooks/useAuth'


export const LoginForm = () => {

  const auth = useAuth()

  // STATES
  const [email, setEmail] = useState(null)
  const [password, setPassword] = useState(null)

  const submit = e => {
    e.preventDefault()
    return auth.signIn({ email, password })
  }

  return (
    <form onSubmit={submit} id='loginForm'>
      <Stack spacing={2}>
        <TextField
          required
          onChange={e => setEmail(e.target.value)}
          name='email'
          type='email'
          value={email || ''}
          label='Email'
          fullWidth
        />
        <TextField
          required
          onChange={e => setPassword(e.target.value)}
          name='password'
          type='password'
          value={password || ''}
          label='Mot de passe'
          fullWidth
        />
        <Button type='submit' variant='contained' color='primary' disabled={!email || !password}>
          Connexion
        </Button>
      </Stack>
    </form>
  )
}

export default LoginForm
