import React, { useState } from 'react'
import TestRequestDialog from '../tests/TestRequestDialog'
import WriteAvailabilityDialog from './WriteAvailabilityDialog'
import WeeklyTable from './WeeklyTable'
import { getWeek } from 'date-fns'
import { Box, Button, SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material'
import { ExpandLess, ExpandMore, Flight } from '@mui/icons-material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'


const WeeklyPanel = () => {
  
  const [week, setWeek] = useState(getWeek(new Date()))
  const [speedDialOpen, setSpeedialOpen] = useState(false)
  const [newFlightOpen, setNewFlightOpen] = useState(false)
  const [newAvailabilityOpen, setNewAvailabilityOpen] = useState(false)

  return (
    <Box sx={{ px: 1, py: 2, width: 'fit-content', mx: 'auto' }}>
      <Button
        variant='outlined'
        fullWidth
        onClick={() => setWeek(prev => prev - 1)}
        sx={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      ><ExpandLess /></Button>
      <WeeklyTable week={week} interactive size={2} />
      <Button
        variant='outlined'
        fullWidth
        onClick={() => setWeek(prev => prev + 1)}
        sx={{ borderTopLeftRadius: 0, borderTopRightRadius: 0 }}
      ><ExpandMore /></Button>
      <SpeedDial
        ariaLabel='speedDial'
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedialOpen(false)}
        onClick={() => setSpeedialOpen(true)}
        open={speedDialOpen}
        FabProps={{ color: 'secondary' }}
        sx={{
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
          position: 'fixed',
        }}
      >
        <SpeedDialAction
          icon={<Flight />}
          tooltipTitle='Plan a test'
          onClick={() => setNewFlightOpen(true)}
        />
        <SpeedDialAction
          icon={<FontAwesomeIcon icon={faUserFriends} style={{ fontSize: '1.1rem' }} />}
          tooltipTitle='Add availability'
          onClick={() => setNewAvailabilityOpen(true)}
        />
      </SpeedDial>
      {newFlightOpen && <TestRequestDialog open onClose={() => setNewFlightOpen(false)} />}
      {newAvailabilityOpen && <WriteAvailabilityDialog open onClose={() => setNewAvailabilityOpen(false)} />}
    </Box>
  )
}

export default WeeklyPanel
