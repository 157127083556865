import React from 'react'
import AllUsersPanel from './AllUsersPanel'
import Page from '../_library/Page'


const UserPage = () => {

  const tabs = [
    {
      key: 'all',
      label: 'All users',
      route: 'all',
      content: <AllUsersPanel />,
    },
  ]

  return <Page tabs={tabs} withTopBarAndMenu />
}

export default UserPage
