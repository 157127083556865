import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Card, CardContent, CardHeader, IconButton } from '@mui/material'
import { Airlines, Place, Timer, Badge, ArrowForward } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import { getTypeLabel } from '../../_helpers/dataHelper'
import { AIRCRAFTS } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'


const AircraftCard = ({ aircraftId }) => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()
  const navigate = useNavigate()
  
  const aircraft = useMemo(() => aircrafts?.find(a => a.id === aircraftId), [aircrafts, aircraftId])
  
  return (
    <Card>
      <CardHeader
        action={<IconButton aria-label='settings' onClick={() => navigate(`${AIRCRAFTS}/${aircraftId}/overview`)}><ArrowForward htmlColor='white' /></IconButton>}
        title={aircraft.serial}
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5)), url("${aircraft.coverUrl}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
        titleTypographyProps={{ sx: {
          color: 'white',
        } }}
      />
      <CardContent>
        <table>
          <tbody>
            <tr><td><Airlines /></td><td>{getTypeLabel(aircraft.type)}</td></tr>
            <tr><td><Badge /></td><td>{aircraft.registration}</td></tr>
            <tr><td><Place /></td><td>Francazal</td></tr>
            <tr><td><Timer /></td><td>Flight time: {aircraft.flightHours?.toFixed(2)}h</td></tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  )
}

AircraftCard.propTypes = {
  aircraftId: PropTypes.string.isRequired,
}

export default AircraftCard
