import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ClickAwayListener, CircularProgress, Tooltip, Typography } from '@mui/material'
import { Delete } from '@mui/icons-material'


function DeleteButton({ deleteAction, text, sx, ...props }) {

  const [activated, setActivated] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const action = () => {
    setSubmitted(true)
    // eslint-disable-next-line
    deleteAction().then(() => {
      setActivated(false)
      setSubmitted(false)
    })
  }

  return activated
    ? <ClickAwayListener onClickAway={() => setActivated(false)}>
      {submitted
        ? <CircularProgress color='inherit' size='1.5rem' />
        : <Button onClick={action} sx={{ minWidth: '40px', ...sx }} {...props}>Supprimer ?</Button>
      }
    </ClickAwayListener>
    : <Tooltip title='Supprimer'>
      <Button onClick={() => setActivated(true)} sx={{ minWidth: '40px', ...sx }} {...props}>
        {text
          ? <Typography component='span' color={props.disabled ? 'grey.500' : 'error.main'} >{text}</Typography>
          : <Delete />
        }
      </Button>
    </Tooltip>
}

DeleteButton.propTypes = {
  className: PropTypes.string,
  deleteAction: PropTypes.func.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
}

export default DeleteButton
