import React from 'react'
import { Box, Button, Card, CardHeader, Divider } from '@mui/material'
import useAuth from '../../hooks/useAuth'
import Avatar from './Avatar'
import { Logout } from '@mui/icons-material'
import { useSelector } from 'react-redux'


const GOOGLE_ACCOUNT_URL = 'https://myaccount.google.com/'
const AccountMenu = () => {

  const profile = useSelector(state => state.profile.data)
  const auth = useAuth()

  return (
    <Card sx={{ position: 'absolute', top: 50, right: 0, bgcolor: 'grey.600', p: 1 }}>
      <Box sx={{ bgcolor: 'grey.50', borderRadius: .5 }}>
        <CardHeader
          avatar={<Avatar user={profile} />}
          title={profile.firstname + ' ' + profile.lastname}
          subheader={profile.email}
        />
        {profile.identityProvider === 'google.com' && <Button
          variant='outlined'
          onClick={() => window.open(GOOGLE_ACCOUNT_URL, '_blank')}
          sx={{ color: 'action.secondary', borderColor: 'action.secondary', mx: 2, mb: 2, width: '-webkit-fill-available' }}
        >Gérer votre compte Google</Button>}
        <Divider sx={{ borderWidth: 1.5 }} />
        <Button fullWidth startIcon={<Logout />} onClick={auth.signOut} sx={{ color: 'action.secondary', borderColor: 'action.secondary' }}>Se déconnecter</Button>
      </Box>
    </Card>
  )
}

export default AccountMenu
