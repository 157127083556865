import React from 'react'
import { useSelector } from 'react-redux'
import ItemTable from '../technicalItems/ItemTable'
import { Stack } from '@mui/material'
import ComponentChangeTable from '../technicalItems/ComponentChangeTable'


export const TlbPanel = () => {
  
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)

  return <Stack spacing={2} p={2}>
    <ItemTable aircraftId={selectedAircraftId} />
    <ComponentChangeTable aircraftId={selectedAircraftId} />
  </Stack>
}

export default TlbPanel
