import React, { useState } from 'react'
import { Fab, Grid } from '@mui/material'
import AircraftBigCard from './AircraftBigCard'
import { Add } from '@mui/icons-material'
import WriteAircraftDialog from './WriteAircraftDialog'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'


export const AllAircraftsPanel = () => {

  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()
  
  const [selectedAircraft, setSelectedAircraft] = useState(null)

  return <Grid container spacing={1} sx={{ minHeight: '426px', m: '0 auto', maxWidth: '936px' }}>
    {aircrafts?.map(aircraft =>
      <Grid item md={4} key={aircraft.id} sx={{ zIndex: 1, ml: { xs: 1, md: 0 } }}>
        <AircraftBigCard aircraft={aircraft} />
      </Grid>,
    )}
    <Fab
      color='secondary'
      aria-label='add'
      onClick={() => setSelectedAircraft({})}
      sx={{
        position: 'fixed',
        right: theme => theme.spacing(3),
        bottom: theme => theme.spacing(3),
      }}
    >
      <Add />
    </Fab>
    {selectedAircraft && <WriteAircraftDialog open onClose={() => setSelectedAircraft(null)} aircraft={selectedAircraft} />}
  </Grid>
}

export default AllAircraftsPanel
