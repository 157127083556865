/* eslint-disable */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, CardContent, Divider, CardHeader } from '@mui/material'
import { Star } from '@mui/icons-material'
import { createEvent, getWorkingLocations, listCalendars, listEvents, signIn, signOut } from '../../_helpers/googleCalendarHelper'
import useGoogleApi from '../../hooks/useGoogleApi'


const TEST_ORDER_TEMPLATE_ID = '1dj7gxWV-xtyKuY8qxJwzdsOrCcMuJZkE-vy0Dx1oLDw'
export const AdminCard = ({ sx }) => {

  const { createFolder, getDocument, copyTemplate, hydrateTestOrder } = useGoogleApi()

  const [template, setTemplate] = useState(null)
  const [testOrder, setTestOrder] = useState(null)

  return (
    <Card sx={{ minWidth: 275, mb: 4, p: 1, position: 'relative', ...sx }}>
      <CardHeader avatar={<Star />} title='Admin actions' sx={{ pb: 0 }} />
      <CardContent>
        <Typography variant='subtitle1' mb={2}>Calendar</Typography>
        <button onClick={signIn}>sign-in</button>
        <button onClick={signOut}>sign-out</button>
        <button onClick={listCalendars}>listCalendars</button>
        <button onClick={createEvent}>createEvent</button>
        <button onClick={listEvents}>listEvents</button>
        <button onClick={getWorkingLocations}>getWorkingLocations</button>
        <Divider sx={{ mt: 2, mb: 1 }} />
        <Typography variant='subtitle1' mb={2}>Drive</Typography>
        <button onClick={() => createFolder('sample')}>Create folder</button>
        <button onClick={() => getDocument(TEST_ORDER_TEMPLATE_ID).then(setTemplate)}>Get document</button>
        <button onClick={() => copyTemplate(TEST_ORDER_TEMPLATE_ID, '18hucZj3wADl0earJoI5KlCt6mIW7osL_').then(setTestOrder)} disabled={!template}>Create TO</button>
        <button onClick={() => hydrateTestOrder()}>Hydrate TO</button>
      </CardContent>
    </Card>
  )
}

AdminCard.propTypes = {
  sx: PropTypes.object,
}

export default AdminCard
