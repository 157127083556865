import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputLabel, MenuItem, Paper, Select, Switch, TextField, Tooltip, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { AIRCRAFT_COLLECTION, HIL_ITEM_COLLECTION, ITEM_COLLECTION, TANK_COLLECTION, TEST_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { pick, pickBy } from 'lodash'
import { serverTimestamp, deleteField } from 'firebase/firestore'
import LiquidGraph from '../_library/LiquidGraph'
import DeleteButton from '../_library/DeleteButton'
import InfoBadge from '../_library/InfoBadge'
import { useFirestore } from '../../hooks/useFirestore'
import { isNil } from 'lodash/lang'
import { mergeCollections } from '../../_helpers/jsHelper'
import { DatePicker } from '@mui/x-date-pickers'
import { AttachFile, FilePresent } from '@mui/icons-material'
import VisuallyHiddenInput from '../_library/VisuallyHiddenInput'
import { useStorage } from '../../hooks/useStorage'


const EditItemDialog = ({ item, onClose }) => {
  
  const profile = useSelector(state => state.profile.data)
  const adminMode = useSelector(state => state.data.adminMode)
  const itemHooks = useFirestore(ITEM_COLLECTION)
  const testHooks = useFirestore(TEST_COLLECTION)
  const dbTests = testHooks.getDocs()
  const tankHooks = useFirestore(TANK_COLLECTION)
  const dbTanks = tankHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const hilHooks = useFirestore(HIL_ITEM_COLLECTION)
  const hilItems = hilHooks.getDocs()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)
  const selectedTestId = useSelector(state => state.data.selectedTestId)
  const storageHook = useStorage('actions')
  
  const [number, setNumber] = useState(item.number)
  const [requestTanks, setRequestTanks] = useState(null)
  const [defect, setDefect] = useState(item.defect)
  const [defectHil, setDefectHil] = useState(null)
  const [action, setAction] = useState(item.action)
  const [actionAttachment, setActionAttachment] = useState(item.actionAttachmentUrl)
  const [captainCheck, setCaptainCheck] = useState(item.captainCheck)
  const [fulFilledRequest, setFulFilledRequest] = useState(item.fulFilledRequest || false)
  const [openHil, setOpenHil] = useState(!!item.actionHilRef || false)
  const [hilTitle, setHilTitle] = useState(item.actionHil?.title || null)
  const [hilDescription, setHilDescription] = useState(item.actionHil?.description || null)
  const [test, setTest] = useState(null)
  const [defectAuthor, setDefectAuthor] = useState(null)
  const [defectDate, setDefectDate] = useState(item.defectDate || null)
  const [actionAuthor, setActionAuthor] = useState(null)
  const [actionDate, setActionDate] = useState(item.actionDate || null)
  const [captainCheckAuthor, setCaptainCheckAuthor] = useState(null)
  const [captainCheckDate, setCaptainCheckDate] = useState(item.captainCheckDate || null)
  
  const [submitted, setSubmitted] = useState(false)
  const defectChanged = useMemo(() => (!!defect && defect !== item.defect) || (!!defectHil && defectHil?.id !== item.defectHilRef?.id) || (requestTanks && requestTanks.map(t => t.request).join() !== item.tanks?.map(t => t.request).join()), [item, defect, requestTanks, defectHil])
  const actionChanged = useMemo(() => (!!fulFilledRequest !== !!item.fulFilledRequest)
    || (openHil !== !!item.actionHilRef)
    || (!!action && action !== item.action)
    || (actionAttachment instanceof File)
    || (!actionAttachment && !!item.actionAttachmentUrl)
  , [item, action, fulFilledRequest, openHil])
  const captainCheckChanged = useMemo(() => captainCheck !== item.captainCheck, [item, captainCheck])
  
  const selectedAircraft = useMemo(() => aircrafts.find(a => a.id === selectedAircraftId), [aircrafts, selectedAircraftId])
  
  useEffect(() => {
    testHooks.listen({
      orderBy: [['startDate', 'asc']],
    })
    return testHooks.unsubscribe
  }, [])
  
  useEffect(() => {
    selectedAircraftId && hilHooks.listen({
      where: [['aircraftRef', '==', aircraftHooks.getDocRef(selectedAircraftId)]],
      orderBy: [['number', 'asc']],
    })
    return () => hilHooks.unsubscribe()
  }, [selectedAircraftId])
  
  useEffect(() => {
    console.info('item id', item.id)
  }, [item.id])
  
  useEffect(() => {
    if (dbTanks && item.type === 'fuelRequest') setRequestTanks(mergeCollections(dbTanks, item.tanks || [], 'id'))
  }, [item.tanks, item.type, dbTanks])
  
  useEffect(() => {
    if (item.defectHilRef && hilItems) setDefectHil(hilItems.find(i => i.id === item.defectHilRef.id))
  }, [item?.defectHilRef, hilItems])
  
  useEffect(() => {
    if (item.testRef && dbTests) setTest(dbTests.find(t => t.id === item.testRef.id))
  }, [item?.testRef, dbTests])
  
  useEffect(() => {
    if (item.defectAuthorRef && dbUsers) setDefectAuthor(dbUsers.find(t => t.id === item.defectAuthorRef.id))
  }, [item?.defectAuthorRef, dbUsers])
  
  useEffect(() => {
    if (item.actionAuthorRef && dbUsers) setActionAuthor(dbUsers.find(t => t.id === item.actionAuthorRef.id))
  }, [item?.actionAuthorRef, dbUsers])
  
  useEffect(() => {
    if (item.captainCheckAuthorRef && dbUsers) setCaptainCheckAuthor(dbUsers.find(t => t.id === item.captainCheckAuthorRef.id))
  }, [item?.captainCheckAuthorRef, dbUsers])
  
  useEffect(() => {
    if (openHil && !hilTitle) setHilTitle(defect)
  }, [openHil])

  const handleSubmit = async e => {
    e.preventDefault()
    setSubmitted(true)
    const obj = pickBy({
      ...item,
      aircraftRef: test?.aircraftRef || aircraftHooks.getDocRef(selectedAircraftId),
      number,
      tanks: defectChanged && requestTanks?.some(t => !!t.request) ? requestTanks.map(t => pick(t, ['id', 'request'])) : null,
      defect,
      defectHilRef: defectHil && hilHooks.getDocRef(defectHil.id),
      defectAuthorRef: (adminMode && defectAuthor) ? userHooks.getDocRef(defectAuthor.id) : defectChanged ? userHooks.getDocRef(profile.id) : null,
      defectDate: defectDate || (defectChanged ? serverTimestamp() : null),
      action,
      actionAuthorRef: (adminMode && actionAuthor) ? userHooks.getDocRef(actionAuthor.id) : actionChanged ? userHooks.getDocRef(profile.id) : null,
      actionDate: actionDate || (actionChanged ? serverTimestamp() : null),
      fulFilledRequest,
      captainCheck,
      captainCheckAuthorRef: (adminMode && captainCheckAuthor) ? userHooks.getDocRef(captainCheckAuthor.id) : (captainCheckChanged ? userHooks.getDocRef(profile.id) : null),
      captainCheckDate: captainCheckDate || (captainCheckChanged ? serverTimestamp() : null),
    }, val => !isNil(val))
    if (captainCheckChanged) obj.testRef = captainCheck ? testHooks.getDocRef(selectedTestId) : null
    else if (test) obj.testRef = testHooks.getDocRef(test.id)
    if (actionAttachment instanceof File) obj.actionAttachmentUrl = await storageHook.uploadFileAction(actionAttachment, actionAttachment.name)
    else if (!actionAttachment) obj.actionAttachmentUrl = deleteField()
    const batch = itemHooks.getBatch()
    if (item.id)
      batch.update(itemHooks.getDocRef(item.id), { ...obj, _updatedAtTime: serverTimestamp() })
    else
      batch.set(itemHooks.getDocRef(), { ...obj, _createdAtTime: serverTimestamp(), _createdByRef: userHooks.getDocRef(profile.id), testStartDate: null }, { merge: true })
    if (fulFilledRequest)
      requestTanks.filter(tank => !!tank.request).forEach(tank => batch.update(tankHooks.getDocRef(tank.id), { quantity: tank.request }))
    if (openHil)
      batch.set(item.actionHilRef || hilHooks.getDocRef(), pickBy({
        _createdAtTime: !item.actionHilRef && serverTimestamp(),
        _updatedAtTime: item.actionHilRef && serverTimestamp(),
        _createdByRef: !item.actionHilRef && userHooks.getDocRef(profile.id),
        aircraftRef: !item.actionHilRef && test?.aircraftRef || aircraftHooks.getDocRef(selectedAircraftId),
        description: hilDescription,
        title: hilTitle,
        openingItemRef: !item.actionHilRef && itemHooks.getDocRef(item.id),
      }), { merge: true })
    if (defectHil && action)
      batch.update(hilHooks.getDocRef(defectHil.id), {
        closureDate: serverTimestamp(),
        closureItemRef: itemHooks.getDocRef(item.id),
      })
    return batch.commit()
      .then(onClose)
      .finally(() => setSubmitted(false))
  }
  
  if (!item) return <Loading />
  else return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-item' component='form' onSubmit={handleSubmit}>
      <DialogTitle>
        {item.label}
        <Typography variant='caption'>Aircraft: {item.aircraft?.serial || selectedAircraft?.serial}</Typography>
      </DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        {adminMode && <>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='test-select-label'>Test</InputLabel>
            <Select
              labelId='test-select-label'
              id='test-select'
              value={test?.id || ''}
              label='Test'
              onChange={e => setTest(dbTests.find(t => t.id === e.target.value))}
            >
              {dbTests?.map(t => <MenuItem key={t.id} value={t.id}>{t.label}</MenuItem>)}
            </Select>
          </FormControl>
          <TextField
            variant='outlined'
            margin='dense'
            id='number'
            label='Number'
            fullWidth
            value={number || ''}
            onChange={e => setNumber(e.target.value)}
          />
          <Divider sx={{ my: 2 }} />
        </>}
        {item.type === 'fuelRequest' && requestTanks && <LiquidGraph
          tanks={requestTanks}
          setTanks={id => value => setRequestTanks(prev => prev.map(t => t.id === id ? { ...t, request: value } : t))}
          field='request'
        />}
        {item.type !== 'fuelRequest' && hilItems && <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id='hil-select-label'>Create item from HIL</InputLabel>
          <Select
            labelId='hil-select-label'
            id='hil-select'
            value={defectHil?.id || ''}
            label='Create item from HIL'
            onChange={e => setDefectHil(hilItems.find(i => i.id === e.target.value))}
            disabled={actionChanged || captainCheckChanged || !!defect}
          >
            <MenuItem value={null}><i>none</i></MenuItem>
            {hilItems?.map(i => <MenuItem key={i.id} value={i.id}>{i.number} - {i.title}</MenuItem>)}
          </Select>
        </FormControl>}
        {adminMode && <FormControl fullWidth sx={{ mt: 1 }}>
          <InputLabel id='defect-author-select-label'>Author</InputLabel>
          <Select
            labelId='defect-author-select-label'
            id='defect-author-select'
            value={defectAuthor?.id || ''}
            label='Author'
            onChange={e => setDefectAuthor(dbUsers.find(u => u.id === e.target.value))}
          >
            {dbUsers?.map(u =>
              <MenuItem key={u.id} value={u.id}>{u.firstname} {u.lastname}</MenuItem>,
            )}
          </Select>
        </FormControl>}
        {adminMode && <DatePicker
          inputFormat='dd-MM-yyyy'
          margin='dense'
          id='defect-date'
          label='Date'
          value={defectDate}
          onChange={setDefectDate}
          sx={{ mt: 1, width: '100%' }}
        />}
        <TextField
          variant='outlined'
          margin='dense'
          id='defect'
          label={item.type === 'fuelRequest' ? 'Comment' : 'Defect'}
          fullWidth
          value={defect || ''}
          multiline
          rows={3}
          onChange={e => setDefect(e.target.value)}
          disabled={actionChanged || captainCheckChanged}
        />
        {item.id && <>
          <Divider sx={{ my: 2 }} />
          {item.type === 'fuelRequest'
            ? <>
              <Switch disabled={defectChanged || captainCheckChanged} checked={fulFilledRequest} onChange={e => setFulFilledRequest(e.target.checked)} />
              <InfoBadge title='Ticking this box will update aircraft fuel with requested values'>Fulfilled request</InfoBadge>
            </>
            : <>
              <Switch disabled={defectChanged || captainCheckChanged} checked={openHil} onChange={e => setOpenHil(e.target.checked)} />
              <InfoBadge title='Ticking this box will create new HIL item'>Create HIL item</InfoBadge>
            </>
          }
          {actionAttachment
            ? <Tooltip title='Remove attachment'>
              <IconButton onClick={() => setActionAttachment(null)} sx={{ position: 'absolute', right: theme => theme.spacing(2) }}>
                <FilePresent />
              </IconButton>
            </Tooltip>
            : <Tooltip title='Upload attachment'>
              <IconButton component='label' sx={{ position: 'absolute', right: theme => theme.spacing(2) }}>
                <AttachFile />
                <VisuallyHiddenInput type='file' onChange={e => setActionAttachment(e.target.files[0])} />
              </IconButton>
            </Tooltip>
          }
          {adminMode && <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='action-author-select-label'>Author</InputLabel>
            <Select
              labelId='action-author-select-label'
              id='action-author-select'
              value={actionAuthor?.id || ''}
              label='Author'
              onChange={e => setActionAuthor(dbUsers.find(u => u.id === e.target.value))}
            >
              {dbUsers?.map(u =>
                <MenuItem key={u.id} value={u.id}>{u.firstname} {u.lastname}</MenuItem>,
              )}
            </Select>
          </FormControl>}
          {adminMode && <DatePicker
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='action-date'
            label='Date'
            value={actionDate}
            onChange={setActionDate}
            sx={{ mt: 1, width: '100%' }}
          />}
          {openHil && <Paper elevation={0} sx={{ bgcolor: 'grey.200', p: 1 }}>
            <TextField
              variant='outlined'
              margin='dense'
              id='hilTitle'
              label='HIL Title'
              fullWidth
              value={hilTitle || ''}
              onChange={e => setHilTitle(e.target.value)}
            />
            <TextField
              variant='outlined'
              margin='dense'
              id='hilDescription'
              label='HIL Description'
              fullWidth
              value={hilDescription || ''}
              multiline
              rows={3}
              onChange={e => setHilDescription(e.target.value)}
            />
          </Paper>}
          <TextField
            variant='outlined'
            margin='dense'
            id='action'
            label='Action'
            fullWidth
            value={action || ''}
            multiline
            rows={3}
            onChange={e => setAction(e.target.value)}
            disabled={defectChanged || captainCheckChanged}
          />
          <Divider sx={{ my: 2 }} />
          <Switch disabled={defectChanged || actionChanged} checked={captainCheck || false} onChange={e => setCaptainCheck(e.target.checked)} /> Captain check
          {adminMode && <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='captain-check-author-select-label'>Author</InputLabel>
            <Select
              labelId='captain-check-author-select-label'
              id='captain-check-author-select'
              value={captainCheckAuthor?.id || ''}
              label='Author'
              onChange={e => setCaptainCheckAuthor(dbUsers.find(u => u.id === e.target.value))}
            >
              {dbUsers?.map(u =>
                <MenuItem key={u.id} value={u.id}>{u.firstname} {u.lastname}</MenuItem>,
              )}
            </Select>
          </FormControl>}
          {adminMode && <DatePicker
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='captain-check-date'
            label='Date'
            value={captainCheckDate}
            onChange={setCaptainCheckDate}
            sx={{ mt: 1, width: '100%' }}
          />}
        </>}
      </DialogContent>
      <DialogActions>
        {!submitted && <Button type='submit'>Submit</Button>}
        {item.id && <DeleteButton deleteAction={() => itemHooks.deleteDoc(item.id).then(onClose)} text='Supprimer' />}
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditItemDialog.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditItemDialog
