import React from 'react'
import DailyPanel from './DailyPanel'
import SyncPanel from './SyncPanel'
import WeeklyPanel from './WeeklyPanel'
import Page from '../_library/Page'


const CalendarPage = () => {

  const tabs = [
    {
      key: 'weekly',
      label: 'Weekly',
      route: 'weekly',
      content: <WeeklyPanel />,
    },
    {
      key: 'daily',
      label: 'Daily',
      route: 'daily',
      content: <DailyPanel />,
    },
    {
      key: 'sync',
      label: 'Sync',
      route: 'sync',
      content: <SyncPanel />,
    },
  ]

  return <Page withTopBarAndMenu tabs={tabs} />
}

export default CalendarPage
