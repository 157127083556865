import React from 'react'
import { Box, Button, Card, Stack, Typography } from '@mui/material'
import Logo from '../../images/logoText.png'
import Page from '../_library/Page'
import { HOME } from '../../_constants/routes'
import { useNavigate, useSearchParams } from 'react-router-dom'
import LoginForm from './EmailLoginForm'
import useAuth from '../../hooks/useAuth'
import bgImg from '../../images/nav_nachos.png'


export const LoginPage = () => {

  const auth = useAuth()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const handleClick = () => auth.googleSignIn()
    .then(() => navigate(HOME))
    .catch(e => console.error(e))

  return (
    <Page
      sx={{ background: theme => `top left / contain no-repeat url(${bgImg}) ${theme.palette.secondary.main}` }}
      containerSx={{ display: 'flex', alignContent: 'center', height: '100%' }}
    >
      <Card sx={{ width: '300px', borderRadius: .5, p: 2, mx: 'auto', alignSelf: 'center' }}>
        <Stack spacing={2} justifyContent='center'>
          <Box component='img' src={Logo} alt='Logo' sx={{ width: '109px' }} style={{ margin: 'auto' }} />
          <Typography variant='h1' textAlign='center'>Flight Test App</Typography>
          <Typography variant='body1' textAlign='center'>Veuillez vous connecter pour accéder à l&apos;application.</Typography>
          {searchParams.get('method') === 'email'
            ? <LoginForm />
            : <Button variant='contained' onClick={handleClick}>Se connecter</Button>
          }
        </Stack>
      </Card>
      <Box component='footer' sx={{ position: 'absolute', width: '100%', bottom: t => t.spacing(2) }}>
        <Typography variant='caption' color='white' align='center' display='block'>
          Version {window.version}
        </Typography>
      </Box>
    </Page>
  )
}

export default LoginPage
