import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, ButtonGroup, Card, CardHeader, IconButton } from '@mui/material'
import Avatar from '../_library/Avatar'
import { format } from 'date-fns'
import ConfirmButton from '../_library/ConfirmButton'
import { TASK_COLLECTION, TEST_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { serverTimestamp } from 'firebase/firestore'
import { AddToDrive, Check, Clear, Delete, Edit, InsertDriveFile, Mail, OpenInNew } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import { useSelector } from 'react-redux'
import TaskEditDialog from './TaskEditDialog'
import CircularProgress from '@mui/material/CircularProgress'


const Task = ({ task, taskController: { ready, busy, createTestOrder, createCerRequest, cerEmailLink, getDocumentUrl, createTestReport } }) => {

  const taskHooks = useFirestore(TASK_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const profile = useSelector(state => state.profile.data)
  const adminMode = useSelector(state => state.data.adminMode)
  const testHooks = useFirestore(TEST_COLLECTION)
  const dbTests = testHooks.getDocs()
  const selectedTestId = useSelector(state => state.data.selectedTestId)
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [openEdit, setOpenEdit] = useState(false)

  const test = useMemo(() => dbTests.find(t => t.id === selectedTestId), [dbTests, selectedTestId])

  return (
    <Card sx={{ boxShadow: 'none' }}>
      <CardHeader
        avatar={<Avatar user={task.user} />}
        title={task.title}
        subheader={task.completedTime && task.user?.firstname + ' le ' + format(task.completedTime, 'dd-MM-yyyy')}
        action={adminMode
          ? <>
            <IconButton onClick={() => setOpenEdit(true)}><Edit /></IconButton>
            <IconButton onClick={() => taskHooks.deleteDoc(task.id)}><Delete /></IconButton>
          </>
          : <ButtonGroup variant='outlined'>
            {test.driveFolderId && task.slug === 'test-order' && !test.driveTestOrderId &&
              <Button disabled={!ready || busy || readOnlyMode} onClick={createTestOrder}>{busy ? <CircularProgress size={20} /> : <AddToDrive />}</Button>}
            {test.driveFolderId && task.slug === 'test-order' && test.driveTestOrderId &&
              <Button component='a' href={getDocumentUrl(test.driveTestOrderId)} target='_blank' rel='noopener noreferrer'><InsertDriveFile /></Button>}
            {test.driveFolderId && task.slug === 'cer' && !test.driveCerRequestId &&
              <Button disabled={!ready || busy || readOnlyMode} onClick={createCerRequest}>{busy ? <CircularProgress size={20} /> : <AddToDrive />}</Button>}
            {test.driveFolderId && task.slug === 'cer' && test.driveCerRequestId &&
              <Button component='a' href={getDocumentUrl(test.driveCerRequestId)} target='_blank' rel='noopener noreferrer'><InsertDriveFile /></Button>}
            {test.driveFolderId && task.slug === 'cer' && <Button component='a' href={cerEmailLink} target='_blank' rel='noopener noreferrer'><Mail /></Button>}
            {test.driveFolderId && task.slug === 'ppr' && <Button component='a' href='https://cy.myhandlingsoftware.com' target='_blank' rel='noopener noreferrer'><OpenInNew /></Button>}
            {test.driveFolderId && task.slug === 'test-report' && !test.driveTestReportId &&
              <Button disabled={!ready || busy || !test.offBlocksUtc || !test.onBlocksUtc || readOnlyMode} onClick={createTestReport}>{busy ? <CircularProgress size={20} /> : <AddToDrive />}</Button>}
            {test.driveFolderId && task.slug === 'test-report' && test.driveTestReportId &&
              <Button component='a' href={getDocumentUrl(test.driveTestReportId)} target='_blank' rel='noopener noreferrer'><InsertDriveFile /></Button>}
            <ConfirmButton
              title={task.dialog}
              variant={profile.roles?.includes(task.majorRole) ? 'contained' : 'outlined'}
              disabled={!!task.completedTime || readOnlyMode}
              onClick={() => taskHooks.updateDoc(task.id, {
                completedTime: serverTimestamp(),
                userRef: userHooks.getDocRef(profile.id),
              })}
              sx={{ ml: 1 }}
            >
              {task.completedTime ? <Check /> : <Clear />}
            </ConfirmButton>
          </ButtonGroup>
        }
      />
      {openEdit && <TaskEditDialog onClose={() => setOpenEdit(false)} task={task} />}
    </Card>
  )
}

Task.propTypes = {
  task: PropTypes.object,
  taskController: PropTypes.object,
}

export default Task
