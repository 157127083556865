import profileSlice from './slices/profileSlice'
import dataReducer from './reducers/dataReducer'
import notificationReducer from './reducers/notificationReducer'
import firestoreSlice from './slices/firestoreSlice'

export default ({
  profile: profileSlice,
  data: dataReducer,
  firestore: firestoreSlice,
  notifications: notificationReducer,
})
