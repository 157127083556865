import React from 'react'
import { IconButton, styled } from '@mui/material'

const ExpandMore = styled(props => {
  const { expand, ...other } = props // eslint-disable-line
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default ExpandMore
