import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { AppBar, IconButton, Box, Button, Toolbar, Container, ClickAwayListener, Tab, Tabs, Typography } from '@mui/material'
import Avatar from '../_library/Avatar'
import { ManageAccounts, Menu as MenuIcon } from '@mui/icons-material'
import { ROLE_ADMIN } from '../../_constants/globals'
import { useDispatch, useSelector } from 'react-redux'
import { setAdminModeAction } from '../../store/actions/dataActions'
import DocsButton from './DocsButton'
import Logo from '../../images/logoText_w.png'
import AccountMenu from '../_library/AccountMenu'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME } from '../../_constants/routes'


const TopBar = ({ tabs, setMenuStatus }) => {

  const profile = useSelector(state => state.profile.data)
  const adminMode = useSelector(state => state.data.adminMode)
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()

  const [openMenu, setOpenMenu] = useState(false)

  const selectedTab = useMemo(() => pathname.split('/').slice(2).join('/'), [pathname])

  return (
    <AppBar color='secondary' sx={{ borderRadius: 0 }}>
      <Container maxWidth='xxl'>
        <Toolbar disableGutters sx={{ color: 'white' }}>
          <IconButton color='inherit' onClick={() => setMenuStatus(true)}><MenuIcon /></IconButton>
          <Box component='img' src={Logo} alt='Logo' onClick={() => navigate(HOME)} sx={{ height: '20px' }} />
          {selectedTab !== '' && <Tabs value={selectedTab} onChange={(_, val) => navigate(val)} textColor='inherit'>
            {tabs?.map(tab => (
              <Tab key={'tab' + tab.key} id={'tab-' + tab.key} label={tab.label} value={tab.key} />
            ))}
          </Tabs>}
          <Box sx={{ flexGrow: 1 }} />
          {readOnlyMode && <Typography variant='caption' mr={1}>Read only</Typography>}
          {profile.roles?.includes(ROLE_ADMIN) && <IconButton
            value='adminMode'
            selected={adminMode || false}
            onClick={() => {
              dispatch(setAdminModeAction(!adminMode))
            }}
            sx={{ color: adminMode ? 'error.main' : 'inherit' }}
          >
            <ManageAccounts />
          </IconButton>}
          <DocsButton />
          <Button onClick={() => setOpenMenu(true)}>
            <Avatar user={profile} />
          </Button>
          {openMenu && <ClickAwayListener onClickAway={() => setOpenMenu(false)}><div><AccountMenu /></div></ClickAwayListener>}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

TopBar.propTypes = {
  tabs: PropTypes.array,
  setMenuStatus: PropTypes.func.isRequired,
}

export default TopBar
