import React, { useEffect } from 'react'
import { Card, CardContent, Typography, Divider, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import { AVAILABILITY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { addWeeks, endOfWeek, startOfDay, startOfWeek } from 'date-fns'
import CTAButton from '../_library/CTAButton'
import { getWorkingLocations, signIn } from '../../_helpers/googleCalendarHelper'
import { pick } from 'lodash'
import AvailabilityItem from './AvailabilityItem'
import { useFirestore } from '../../hooks/useFirestore'


const SyncPanel = () => {
  
  const profile = useSelector(state => state.profile.data)
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const availabilities = availabilityHooks.getDocs()

  useEffect(() => {
    if (profile)
      availabilityHooks.listen({
        where: [
          ['dayDate', '>=', startOfWeek(new Date())],
          ['dayDate', '<=', endOfWeek(addWeeks(new Date(), 1))],
          ['userRef', '==', userHooks.getDocRef(profile.id)],
        ],
        orderBy: [['dayDate', 'asc']],
      })
    return () => availabilityHooks.unsubscribe()
  }, [profile.id])
  
  const handleSync = async () => {
    availabilityHooks.batchDeleteDocAction(availabilities.map(a => a.id))
    await signIn()
    console.log('signed in')
    const timeMin = startOfWeek(new Date()).toISOString()
    console.log('timeMin', timeMin)
    const timeMax = endOfWeek(addWeeks(new Date(), 1)).toISOString()
    console.log('timeMax', timeMax)
    const res = await getWorkingLocations({ timeMin, timeMax })
    console.log('items', res.result.items.map(i => pick(i, ['start', 'end', 'status'])))
    res.result.items.forEach(({ start, end, status }) => {
      if (status === 'confirmed') {
        const data = {
          _tags: ['created by Google API'],
          dayDate: startOfDay(new Date(start.dateTime || start.date)),
          startTime: start.dateTime ? new Date(start.dateTime) : new Date(start.date + 'T08:00'),
          endTime: end.dateTime ? new Date(end.dateTime) : new Date(end.date+ 'T18:00'),
          userRef: userHooks.getDocRef(profile.id),
        }
        availabilityHooks.addDoc(data)
      }
    })
  }
  
  if (!availabilities) return <Loading />
  return (
    <Box sx={{ my: 2, mx: 'auto', maxWidth: '900px' }}>
      <CTAButton onClick={handleSync} fullWidth sx={{ mt: 2 }}>Google Calendar Sync</CTAButton>
      <Divider light />
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Disponibilités de la semaine</Typography>
      <Card variant='outlined' sx={{ minWidth: '275px', mb: 2, bgcolor: 'background.card' }}>
        <CardContent sx={{ px: 0 }}>
          {availabilities.length === 0
            ? <Typography variant='body1' style={{ textAlign: 'center', fontStyle: 'italic' }}>Aucune dispo</Typography>
            : availabilities.map(availability => <AvailabilityItem item={availability} key={availability.id} />)
          }
        </CardContent>
      </Card>
    </Box>
  )
}

export default SyncPanel
