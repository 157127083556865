import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, useTheme, useMediaQuery, IconButton, DialogContent, DialogActions } from '@mui/material'
import WriteAirportForm from './WriteAirportForm'
import { Close } from '@mui/icons-material'
import CTAButton from '../_library/CTAButton'
import { useSelector } from 'react-redux'


export const WriteAirportDialog = ({ open, onClose, id, airport: paramAirport }) => {

  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const formRef = useRef()
  const airport = paramAirport || useSelector(state => state.firestore.airports?.find(b => b.id === id))

  const [submitted, setSubmitted] = useState(false)

  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      sx={{
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 700,
        },
        '& .MuiInput-underline::before': {
          borderBottomColor: 'rgba(0,0,0,.1)',
        },
      }}
    >
      <DialogTitle>
        {!id && 'New airport'}
        &nbsp;
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <WriteAirportForm formRef={formRef} setSubmitted={setSubmitted} onComplete={onClose} airport={airport} />
      </DialogContent>
      <DialogActions>
        <CTAButton type='submit' fullWidth onClick={() => formRef.current.requestSubmit()} disabled={submitted}>
          {id ? 'Mettre à jour' : 'Créer'}
        </CTAButton>
      </DialogActions>
    </Dialog>
  )
}

WriteAirportDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  id: PropTypes.string,
  airport: PropTypes.object,
}

export default WriteAirportDialog
