import React from 'react'
import PropTypes from 'prop-types'
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Typography, useTheme, ListItemButton, Box, styled } from '@mui/material'
import { Dashboard, Flight, FlightTakeoff, Group, CalendarToday, Settings } from '@mui/icons-material'
import Logo from '../../images/logo.png'
import * as ROUTES from '../../_constants/routes'
import NavBackground from '../../images/nav_nachos.png'
import { useLocation, useNavigate } from 'react-router-dom'


const listItemStyles = {
  py: 2,
  '&:hover,&:focus': {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
}

const StyledListItemText = styled(ListItemText)(() => ({
  fontSize: 'inherit',
  marginTop: 0,
  marginBottom: 0,
  display: 'inline-block',
  verticalAlign: 'top',
}))

const StyledListItemIcon = styled(ListItemIcon)(({ theme }) => ({
  color: 'inherit',
  marginRight: theme.spacing(2),
  minWidth: 'auto',
}))

const NavigationDrawer = ({ menuOpen, setMenuOpen }) => {

  const theme = useTheme()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  return (
    <Drawer
      variant='temporary'
      open={menuOpen}
      onClose={() => setMenuOpen(false)}
      PaperProps={{
        sx: {
          width: 256,
          backgroundAttachment: 'fixed',
          backgroundColor: 'secondary.main',
          backgroundImage: `url(${NavBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'bottom left',
          overflowX: 'hidden',
          maxHeight: '100vh',
        },
      }}
    >
      <List disablePadding sx={{ backgroundColor: '#191935aa', height: '100%', color: 'white' }}>
        <ListItem
          onClick={() => setMenuOpen(false)}
          sx={{
            py: 2,
            fontWeight: 500,
            color: 'white',
            '&:hover': {
              backgroundColor: 'initial',
            },
          }}
        >
          <Box component='img' src={Logo} alt='AURA' sx={{ height: '28px' }} />
          <Box component='span' sx={{ ml: 2 }}>AURA - FLIGHT TEST</Box>
        </ListItem>
        <ListItemButton
          onClick={() => navigate(ROUTES.HOME)}
          sx={{
            ...listItemStyles,
            color: pathname === ROUTES.HOME && 'primary.light',
            display: 'inline-block',
            width: '80%',
          }}
        >
          <StyledListItemIcon><Dashboard /></StyledListItemIcon>
          <StyledListItemText>Dashboard</StyledListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate(ROUTES.SETTINGS)}
          sx={{
            ...listItemStyles,
            color: pathname.includes(ROUTES.SETTINGS) && 'primary.light',
            display: 'inline-block',
            width: '20%',
          }}
        >
          <StyledListItemIcon><Settings /></StyledListItemIcon>
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate(ROUTES.AIRCRAFTS)}
          sx={{
            ...listItemStyles,
            color: pathname.includes(ROUTES.AIRCRAFTS) && 'primary.light',
          }}
        >
          <StyledListItemIcon><Flight /></StyledListItemIcon>
          <StyledListItemText>Aircrafts</StyledListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate(ROUTES.USERS)}
          sx={{
            ...listItemStyles,
            color: pathname.includes(ROUTES.USERS) && 'primary.light',
          }}
        >
          <StyledListItemIcon><Group /></StyledListItemIcon>
          <StyledListItemText>Users</StyledListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate(ROUTES.TESTS)}
          sx={{
            ...listItemStyles,
            color: pathname.includes(ROUTES.TESTS) && 'primary.light',
          }}
        >
          <StyledListItemIcon><FlightTakeoff /></StyledListItemIcon>
          <StyledListItemText>Vols</StyledListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={() => navigate(ROUTES.CALENDAR)}
          sx={{
            ...listItemStyles,
            color: pathname.includes(ROUTES.CALENDAR) && 'primary.light',
          }}
        >
          <StyledListItemIcon><CalendarToday /></StyledListItemIcon>
          <StyledListItemText>Calendar</StyledListItemText>
        </ListItemButton>
      </List>
      <Typography variant='body2' color='rgba(255, 255, 255, 0.4)' fontSize='.7rem' textAlign='center' sx={{
        position: 'absolute',
        bottom: theme.spacing(1),
        left: 0,
        width: '80%',
      }}>
        Version {window.version}
      </Typography>
    </Drawer>
  )
}

NavigationDrawer.propTypes = {
  menuOpen: PropTypes.bool.isRequired,
  setMenuOpen: PropTypes.func.isRequired,
}

export default NavigationDrawer
