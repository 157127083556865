import React, { Fragment, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { AIRCRAFT_COLLECTION, AVAILABILITY_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import { eachDayOfInterval, endOfWeek, format, getWeek, isSameDay, isToday, setWeek, startOfWeek } from 'date-fns'
import Loading from '../_library/Loading'
import { chunk } from 'lodash'
import { Box, Card, CardContent, CardHeader, Chip, Typography } from '@mui/material'
import { useFirestore } from '../../hooks/useFirestore'
import { TESTS } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'


const WeeklyTable = ({ week= getWeek(new Date()), interactive, size = 2 }) => {

  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  const availabilities = availabilityHooks.getDocs('weeklyAvailabilities')
  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs('weeklyTests')
  const navigate = useNavigate()

  const weekLimits = useMemo(() => ({
    start: startOfWeek(setWeek(new Date(), week), { weekStartsOn: 1 }),
    end: endOfWeek(setWeek(new Date(), week + size - 1), { weekStartsOn: 1 }),
  }), [week])

  useEffect(() => {
    availabilityHooks.listen({
      where: [
        ['dayDate', '>=', weekLimits.start],
        ['dayDate', '<=', weekLimits.end],
      ],
      orderBy: [['dayDate', 'asc']],
      storeAs: 'weeklyAvailabilities',
    })
    testHooks.listen({
      where: [
        ['startDate', '>=', weekLimits.start],
        ['startDate', '<=', weekLimits.end],
      ],
      orderBy: [['startDate', 'asc']],
      storeAs: 'weeklyTests',
    })
    return () => {
      availabilityHooks.unsubscribe()
      testHooks.unsubscribe()
    }
  }, [weekLimits])

  const weekDays = useMemo(() => eachDayOfInterval(weekLimits), [weekLimits])

  if (!aircrafts) return <Loading />

  return (
    <Box component='table' sx={{
      mx: 'auto',
      borderCollapse: 'collapse',
      '& td': {
        borderLeft: '1px solid black',
        borderRight: '1px solid black',
      },
      '& tr:nth-of-type(3n + 1)': {
        fontWeight: 'bold',
        '& td': {
          p: 1,
        },
      },
    }}>
      <tbody>
        {chunk(weekDays, 7).map((chunked, index) => <Fragment key={index}>
          <Box component='tr' sx={{ border: '1px solid black' }}>
            <td>{interactive && format(chunked[0], 'MMMM')} W{getWeek(chunked[0])}</td>
            {chunked.slice(0, 5).map(day => (
              <Box component='td' key={day.toString()} sx={{ bgcolor: isToday(day) && 'primary.light' }}>
                <div>{format(day, 'EEE do')}</div>
              </Box>
            ))}
          </Box>
          <tr>
            <td style={{ border: 'none' }} />
            {chunked.slice(0, 5).map(day => (
              <td key={day.toString()}>
                {availabilities.filter(a => isSameDay(a.dayDate, day)).map(availability => (
                  <Chip
                    key={availability.id}
                    size='small'
                    label={availability.user?.trigram}
                    onDelete={interactive ? () => availabilityHooks.deleteDoc(availability.id) : undefined}
                  />
                ))}
              </td>
            ))}
          </tr>
          <tr>
            <td style={{ border: 'none' }} />
            {chunked.slice(0, 5).map(day => (
              <Box component='td' key={day.toString()} sx={{ borderBottom: '1px solid black' }}>
                <Box sx={{ minHeight: 50 }}>
                  {tests.filter(t => isSameDay(t.startDate, day)).map(test => (
                    <Card key={test.id} onClick={() => navigate(`${TESTS}/${test.id}`)} sx={{ m: 1, cursor: 'pointer', '&:hover': { boxShadow: '0 0 0 2px black' } }}>
                      <CardHeader
                        sx={{
                          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5)), url("${test.aircraft?.coverUrl}")`,
                          backgroundPosition: 'center',
                          backgroundSize: 'cover',
                        }}
                      />
                      <CardContent>
                        <Typography color='secondary' textTransform='uppercase'>{format(test.startDate, 'HH:mm')}</Typography>
                        <Typography>{test.title}</Typography>
                        <Typography variant='caption'>{test.label}</Typography>
                        <Box sx={{ mt: 1, display: 'flex', placeContent: 'space-around', fontWeight: 'bold' }}>{test.users?.map(u => <Chip key={test.id+u.id} size='small' label={u.trigram} />)}</Box>
                      </CardContent>
                    </Card>
                  ))}
                </Box>
              </Box>
            ))}
          </tr>
        </Fragment>)}
      </tbody>
    </Box>
  )
}

WeeklyTable.propTypes = {
  week: PropTypes.number,
  interactive: PropTypes.bool,
  size: PropTypes.number,
}

export default WeeklyTable
