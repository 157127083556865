import * as React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, CircularProgress } from '@mui/material'


const CircularProgressWithLabel = ({ completed, total, ...props }) => (
  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
    <CircularProgress variant='determinate' value={completed / total * 100} {...props} />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography variant='caption' component='div' color='text.secondary'>
        {completed}/{total}
      </Typography>
    </Box>
  </Box>
)

CircularProgressWithLabel.propTypes = {
  completed: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
}

export default CircularProgressWithLabel
