import React, { useState } from 'react'
import { SpeedDial, SpeedDialAction, SpeedDialIcon, Typography } from '@mui/material'
import DaySelector from './DaySelector'
import { Flight } from '@mui/icons-material'
import DailyTable from './DailyTable'
import TestRequestDialog from '../tests/TestRequestDialog'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserFriends } from '@fortawesome/free-solid-svg-icons'
import { parseParams } from '../../_helpers/urlHelper'
import { parse, format } from 'date-fns'
import { useLocation } from 'react-router-dom'
import { fr } from 'date-fns/locale'
import WriteAvailabilityDialog from './WriteAvailabilityDialog'


const DailyPanel = () => {

  const location = useLocation()

  const [day, setDay] = useState(parseParams(location.search).date ? parse(parseParams(location.search).date, 'dd-MM-yyyy', new Date()) : new Date())
  const [speedDialOpen, setSpeedialOpen] = useState(false)
  const [newFlightOpen, setNewFlightOpen] = useState(false)
  const [newAvailabilityOpen, setNewAvailabilityOpen] = useState(false)

  return (
    <>
      <DaySelector day={day} setDay={setDay} sx={{ mx: 'auto', my: 1 }} />
      <Typography variant='caption' ml={1}>{format(day, 'EEEE', { locale: fr })}</Typography>
      <DailyTable day={day} />
      <SpeedDial
        ariaLabel='speedDial'
        icon={<SpeedDialIcon />}
        onClose={() => setSpeedialOpen(false)}
        onClick={() => setSpeedialOpen(true)}
        open={speedDialOpen}
        FabProps={{ color: 'secondary' }}
        sx={{
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
          position: 'fixed',
        }}
      >
        <SpeedDialAction
          icon={<Flight />}
          tooltipTitle='Plan a test'
          onClick={() => setNewFlightOpen(true)}
        />
        <SpeedDialAction
          icon={<FontAwesomeIcon icon={faUserFriends} style={{ fontSize: '1.1rem' }} />}
          tooltipTitle='Add availability'
          onClick={() => setNewAvailabilityOpen(true)}
        />
      </SpeedDial>
      {newFlightOpen && <TestRequestDialog open onClose={() => setNewFlightOpen(false)} />}
      {day && newAvailabilityOpen && <WriteAvailabilityDialog open dayInit={day} onClose={() => setNewAvailabilityOpen(false)} />}
    </>
  )
}

export default DailyPanel
