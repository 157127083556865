import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { TextField, Box } from '@mui/material'
import { AIRPORT_COLLECTION } from '../../_constants/globals'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'
import { isNil } from 'lodash/lang'


const WriteAirportForm = ({ formRef, setSubmitted, onComplete, airport }) => {

  const airportHooks = useFirestore(AIRPORT_COLLECTION)

  const [name, setName] = useState(airport?.name)
  const [ICAO, setICAO] = useState(airport?.ICAO)
  const [city, setCity] = useState(airport?.city)
  const [country, setCountry] = useState(airport?.country)
  const [latitude, setLatitude] = useState(airport?.latitude)
  const [longitude, setLongitude] = useState(airport?.longitude)
  const [altitude, setAltitude] = useState(airport?.altitude)
  const [errors, setErrors] = useState({})

  const handleSubmit = async e => {
    e.preventDefault()
    if (!name)
      setErrors(prev => ({ ...prev, airport: 'The field has to be to completed' }))
    if (name) {
      setSubmitted(true)
      const obj = pickBy({
        name,
        ICAO,
        city,
        country,
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        altitude: parseFloat(altitude),
        occurrences: airport?.occurrences || 0,
      }, val => !isNil(val))
      try {
        airport?.id
          ? await airportHooks.updateDoc(airport.id, obj)
          : await airportHooks.addDoc(obj)
        onComplete()
      }
      finally {setSubmitted(false)}
    }
  }

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <Box sx={{ px: 1 }}>
        <TextField
          id='name'
          variant='outlined'
          margin='dense'
          label='Name'
          fullWidth
          value={name || ''}
          onChange={e => setName(e.target.value)}
          sx={{ mt: 2 }}
          error={!!errors.name}
          helperText={errors.name}
        />
        <TextField
          id='icao'
          variant='outlined'
          margin='dense'
          label='ICAO code'
          placeholder='LFXX'
          fullWidth
          value={ICAO || ''}
          onChange={e => setICAO(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          id='city'
          variant='outlined'
          margin='dense'
          label='City'
          fullWidth
          value={city || ''}
          onChange={e => setCity(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          id='country'
          variant='outlined'
          margin='dense'
          label='Country'
          fullWidth
          value={country || ''}
          onChange={e => setCountry(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          id='latitude'
          variant='outlined'
          margin='dense'
          label='Latitude'
          fullWidth
          value={latitude || ''}
          onChange={e => setLatitude(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          id='longitude'
          variant='outlined'
          margin='dense'
          label='Longitude'
          fullWidth
          value={longitude || ''}
          onChange={e => setLongitude(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          id='altitude'
          variant='outlined'
          margin='dense'
          label='Altitude'
          fullWidth
          value={altitude || ''}
          type='number'
          onChange={e => setAltitude(e.target.value)}
          sx={{ mt: 2 }}
        />
      </Box>
    </form>
  )
}

WriteAirportForm.propTypes = {
  formRef: PropTypes.object,
  setSubmitted: PropTypes.func,
  onComplete: PropTypes.func,
  airport: PropTypes.object,
}

export default WriteAirportForm
