/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import { AIRCRAFT_COLLECTION, HIL_ITEM_COLLECTION } from '../../_constants/globals'
import { Archive } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import EditHIL from './EditHIL'
import { format } from 'date-fns'


const HILTable = ({ title }) => {

  const theme = useTheme()
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'))
  const hilItemHooks = useFirestore(HIL_ITEM_COLLECTION)
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)
  const aircrafts = aircraftHooks.getDocs()
  const items = hilItemHooks.getDocs()

  const selectedAircraft = useMemo(() => aircrafts?.find(a => a.id === selectedAircraftId), [aircrafts, selectedAircraftId])

  const [selectedItemIndex, setSelectedItemIndex] = useState(null)
  const [showClosed, setShowClosed] = useState(false)

  useEffect(() => {
    selectedAircraft && hilItemHooks.listen({
      where: [['aircraftRef', '==', aircraftHooks.getDocRef(selectedAircraft.id)]],
      orderBy: [['number', 'asc']],
    })
    return () => hilItemHooks.unsubscribe()
  }, [selectedAircraft?.id])

  const filteredItems = useMemo(() => items?.filter(item => showClosed ? true : !item.closureDate), [items, showClosed])
  const selectedItem = useMemo(
    () => filteredItems && selectedItemIndex !== null && (filteredItems[selectedItemIndex] || { aircraftRef: aircraftHooks.getDocRef(selectedAircraft.id) }),
    [filteredItems, selectedItemIndex],
  )

  const data = useMemo(() =>
    filteredItems?.map(({ number, openingItem, title, description, closureDate, closureItem }) =>
      [number, openingItem?.label, title, description, closureDate, closureItem?.label],
    ), [filteredItems])

  if (!data) return <Loading />
  else return (
    <>
      <MUIDataTable
        title={title}
        data={data}
        columns={[
          { name: 'Num' },
          { name: 'Opening Item' },
          { name: 'Title' },
          {
            name: 'Description',
            options: {
              display: 'false',
            },
          },
          {
            name: 'Closure date',
            options: {
              display: !isUpXl,
              customBodyRender: val => val && format(val, 'dd/MM/yyyy'),
            },
          },
          {
            name: 'Closing Item',
            options: {
              display: !isUpXl,
            },
          },
        ]}
        options={{
          selectableRows: 'none',
          responsive: 'standard',
          onRowClick: (row, { dataIndex }) => setSelectedItemIndex(dataIndex),
          download: false,
          filter: false,
          search: false,
          print: false,
          selectableRowsHeader: false,
          viewColumns: false,
          pagination: false,
          sort: false,
          textLabels: { body: { noMatch: 'No item' } },
          setRowProps: () => ({ sx: { cursor: 'pointer' } }),
          customToolbar: () => (<>
            <Tooltip title='Show / Hide closed items'>
              <IconButton color={showClosed ? 'primary' : 'grey.500'} onClick={() => setShowClosed(prev => !prev)}>
                <Archive />
              </IconButton>
            </Tooltip>
          </>),
        }}
      />
      {selectedItem && <EditHIL item={selectedItem} onClose={() => setSelectedItemIndex(null)} />}
    </>
  )
}

HILTable.propTypes = {
  title: PropTypes.string,
}

export default HILTable
