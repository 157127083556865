import React from 'react'
import PropTypes from 'prop-types'
import { Grid, Typography } from '@mui/material'
import { formatDistanceToNowStrict, addHours, startOfToday } from 'date-fns'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'


const AvailabilityItem = ({ item }) => (
  <Grid item xs={12} sx={{ mb: 2, bgcolor: 'white', p: 2 }}>
    <Typography color='textSecondary' lineHeight='20px' display='inline-block' width={200} sx={{ color: 'rgba(0,0,0,.54)' }}>
      {item.day && formatDistanceToNowStrict(item.day.toDate(), { unit: 'day', addSuffix: true })}
    </Typography>
    <DatePicker
      inputFormat='dd-MM-yyyy'
      margin='dense'
      disabled
      value={item.dayDate ? item.dayDate : new Date()}
      slotProps={{
        textField: { sx: { mr: 1, width: '150px' } },
      }}
    />
    <TimePicker
      variant='inline'
      ampm={false}
      margin='dense'
      value={item.startTime ? item.startTime : new Date()}
      minutesStep={15}
      minTime={addHours(startOfToday(), 8)}
      maxTime={addHours(startOfToday(), 19)}
      disabled
      slotProps={{
        textField: { sx: { mr: 1, width: '110px' } },
      }}
    />
    <TimePicker
      variant='inline'
      ampm={false}
      margin='dense'
      value={item.endTime ? item.endTime : new Date()}
      minutesStep={15}
      minTime={addHours(startOfToday(), 9)}
      maxTime={addHours(startOfToday(), 20)}
      disabled
      slotProps={{
        textField: { sx: { mr: 1, width: '110px' } },
      }}
    />
  </Grid>
)

AvailabilityItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default AvailabilityItem
