import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, Typography, FormLabel, FormGroup, FormControlLabel, Checkbox, Switch,
} from '@mui/material'
import DeleteButton from '../_library/DeleteButton'
import InlineEditor from '../_library/InlineEditor'
import Avatar from '../_library/Avatar'
import { ROLE_CAPTAIN, ROLE_ENGINEER, ROLE_MECHANICS, ROLE_PILOT, ROLE_VISITOR, USER_COLLECTION }from '../../_constants/globals'
import Loading from '../_library/Loading'
import { getRoleIcon } from '../../_helpers/dataHelper'
import { useFirestore } from '../../hooks/useFirestore'


const labelStyle = {
  color: 'rgba(0, 0, 0, .54)',
  display: 'inline-block',
  lineHeight: '20px',
  width: 200,
}
const ROLES = [ROLE_CAPTAIN, ROLE_PILOT, ROLE_ENGINEER, ROLE_MECHANICS, ROLE_VISITOR]
const EditUserDialog = ({ user, onClose }) => {

  const userHooks = useFirestore(USER_COLLECTION)

  useEffect(() => {console.info('user id', user.id)}, [user?.id])

  if (!user) return <Loading />
  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-title'>
      <DialogTitle id='form-dialog-title' sx={{ display: 'flex' }}>
        <Avatar user={user} />&nbsp;{user.firstname} {user.lastname}
      </DialogTitle>
      <DialogContent>
        <Typography variant='body2' color='textSecondary' gutterBottom>Identité</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Grid item xs={12}>
              <InlineEditor title='Nom' value={user.lastname} handleSubmit={val => userHooks.updateDoc(user.id, { lastname: val })} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Prénom' value={user.firstname} handleSubmit={val => userHooks.updateDoc(user.id, { firstname: val })} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor disabled title='Email' value={user.email} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor disabled title='Téléphone' value={user.phone} />
            </Grid>
            <Grid item xs={12}>
              <InlineEditor title='Trigram' value={user.trigram} handleSubmit={val => userHooks.updateDoc(user.id, { trigram: val })} />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={user.archived || false}
                    onChange={e => userHooks.updateDoc(user.id, { archived: e.target.checked })}
                  />
                }
                label='Archived'
              />
            </Grid>
          </CardContent>
        </Card>

        <Typography variant='body2' color='textSecondary' gutterBottom>Gestion</Typography>
        <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
          <CardContent>
            <Grid item xs={12}>
              <FormControl variant='standard' component='fieldset'>
                <FormLabel sx={{ ...labelStyle, position: 'absolute' }}>Roles</FormLabel>
                <FormGroup sx={{ ml: '200px' }}>
                  {ROLES.map(role =>
                    <FormControlLabel
                      control={<Checkbox
                        checked={user.roles?.includes(role) || false}
                        onChange={event => userHooks.updateDoc(user.id, { roles: event.target.checked ? [...(user.roles || []), role] : user.roles.filter(r => r !== role) })}
                        name={role}
                        color='primary'
                        icon={getRoleIcon(role, false)}
                        checkedIcon={getRoleIcon(role, true)}
                      />}
                      label={role}
                      key={role}
                    />,
                  )}
                </FormGroup>
              </FormControl>
            </Grid>
          </CardContent>
        </Card>
      </DialogContent>
      <DialogActions>
        <DeleteButton deleteAction={() => userHooks.deleteDoc(user.id).then(onClose)} text='Supprimer' />
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditUserDialog.propTypes = {
  user: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditUserDialog
