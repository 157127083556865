import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button, Card, CardActions, CardContent } from '@mui/material'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'


const MapCard = ({ airport }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  })
  
  const coordinates = useMemo(() => ({ lat: airport.latitude, lng: airport.longitude }), [airport.latitude, airport.longitude])
  
  return isLoaded ? (
    <Card>
      <CardContent sx={{ p: 0 }}>
        <GoogleMap
          mapContainerStyle={{ width: '100%', aspectRatio: 1 }}
          options={{
            streetViewControl: false,
          }}
          onLoad={map => {
            const bounds = new window.google.maps.LatLngBounds()
            bounds.extend({ lat: 43.604652, lng: 1.444209 })
            bounds.extend(coordinates)
            map.fitBounds(bounds)
          }}
        >
          { /* Child components, such as markers, info windows, etc. */ }
          <Marker position={coordinates} />
        </GoogleMap>
      </CardContent>
      <CardActions>
        <Button color='primary' fullWidth disabled>{airport.name}</Button>
      </CardActions>
    </Card>
  ) : <></>
}

MapCard.propTypes = {
  airport: PropTypes.object.isRequired,
}

export default MapCard
