import { Box, Paper, Stack, Typography } from '@mui/material'
import Avatar from './Avatar'
import React from 'react'
import PropTypes from 'prop-types'


const ChatBalloon = ({ title, subtitle, user, content, orientation = 'left', sx, ...props }) => (
  <Stack direction={orientation === 'left' ? 'row' : 'row-reverse'} spacing={1} mb={1}  sx={{ boxShadow: 'none', ...sx }} {...props}>
    <Avatar user={user} />
    <Paper sx={{ position: 'relative', bgcolor: 'grey.200', p: .5 }}>
      <Box sx={{ position: 'absolute', [orientation]: '-6px', top: '3px', width: '12px', height: '12px', bgcolor: 'grey.200', rotate: '45deg', zIndex: 2 }} />
      <Typography color='primary' fontSize='.9rem'>{title}</Typography>
      {content}
      <Typography variant='caption' color='grey.500' align='right' fontSize='.7rem'>{subtitle}</Typography>
    </Paper>
  </Stack>
)

ChatBalloon.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  user: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  orientation: PropTypes.oneOf(['left', 'right']),
  sx: PropTypes.object,
}

export default ChatBalloon
