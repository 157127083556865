import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, TextField, Typography } from '@mui/material'
import DeleteButton from '../_library/DeleteButton'
import { HIL_ITEM_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'


const EditHIL = ({ item, onClose }) => {

  const hilHooks = useFirestore(HIL_ITEM_COLLECTION)

  const [number, setNumber] = useState(item.number)
  const [title, setTitle] = useState(item.title)
  const [description, setDescription] = useState(item.description)
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    const obj = pickBy({
      ...item,
      number,
      title,
      description,
    })
    const handler = data => item.id ? hilHooks.updateDoc(item.id, data) : hilHooks.addDoc(data)
    return handler(obj)
      .then(onClose)
      .finally(() => setSubmitted(false))
  }

  if (!item) return <Loading />
  else return (
    <Dialog open onClose={onClose} component='form' onSubmit={handleSubmit}>
      <DialogContent>
        <Typography>Id: {item.id}</Typography>
        <Typography>Aircraft: {item.aircraft?.serial}</Typography>
        <Typography>Opening item: {item.openItem?.label}</Typography>
        <Typography>Closing item: {item.closureItem?.label}</Typography>
        <TextField
          variant='outlined'
          margin='dense'
          id='number'
          label='Number'
          fullWidth
          type='number'
          value={number || ''}
          onChange={e => setNumber(Number(e.target.value))}
          disabled={!!item.closureDate}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='title'
          label='Title'
          fullWidth
          value={title || ''}
          onChange={e => setTitle(e.target.value)}
          disabled={!!item.closureDate}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='description'
          label='Description'
          fullWidth
          multiline
          rows={4}
          value={description || ''}
          onChange={e => setDescription(e.target.value)}
          disabled={!!item.closureDate}
        />
      </DialogContent>
      <DialogActions>
        {!item.closureDate && <Button type='submit' disabled={submitted}>Submit</Button>}
        {item.id && !item.closureDate && <DeleteButton deleteAction={() => hilHooks.deleteDoc(item.id).then(onClose)} text='Delete' />}
        <Button onClick={onClose} color='primary'>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

EditHIL.propTypes = {
  item: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditHIL
