/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { format } from 'date-fns'
import Loading from '../_library/Loading'
import MUIDataTable from 'mui-datatables'
import { pick, pickBy } from 'lodash'
import { AIRCRAFT_COLLECTION, TASK_COLLECTION, TEST_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import { TESTS } from '../../_constants/routes'
import { useFirestore } from '../../hooks/useFirestore'
import { Chip, IconButton, Tooltip } from '@mui/material'
import PropTypes from 'prop-types'
import { Add } from '@mui/icons-material'
import TestRequestDialog from './TestRequestDialog'
import CircularProgressWithLabel from '../_library/CircularProgressWithLabel'
import { useSelector } from 'react-redux'


const STORAGE_KEY = 'allTestsTableState'
const TestTable = ({ aircraftId }) => {

  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const taskHooks = useFirestore(TASK_COLLECTION)
  const dbTasks = taskHooks.getDocs()
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const navigate = useNavigate()
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [openNewTest, setOpenNewTest] = useState(false)

  useEffect(() => {
    testHooks.listen(pickBy({
      where: aircraftId && [['aircraftRef', '==', aircraftHooks.getDocRef(aircraftId)]],
      orderBy: [
        ['startDate', 'asc'],
        ['label', 'asc'],
      ],
    }))
    taskHooks.listen(pickBy({
      where: aircraftId && [['aircraftRef', '==', aircraftHooks.getDocRef(aircraftId)]],
      orderBy: [
        ['order', 'asc'],
      ],
    }))
    return () => testHooks.unsubscribe()
  }, [aircraftId])

  const data = useMemo(() => tests?.map(val => [
    val,
    val.startDate,
    val.aircraft?.serial,
    val.users,
    val.testType,
    val.label,
    val.title,
    dbTasks?.filter(t => t.testRef.id === val.id),
    val.departure,
    val.arrival,
    val.offBlocksUtc,
    val.takeOffUtc,
    val.landingUtc,
    val.onBlocksUtc,
    val.comments || '',
    val.purpose,
  ]), [tests, dbTasks])

  const localState = window.localStorage.getItem(STORAGE_KEY) &&
    JSON.parse(window.localStorage.getItem(STORAGE_KEY))

  if (!tests) return <Loading />
  return (<>
    <MUIDataTable
      data={data}
      columns={[
        { name: 'object', options: { filter: false, sort: false, display: 'excluded', print: false, searchable: false, download: false } },
        {
          name: 'date',
          label: 'Date',
          options: {
            display: localState?.columns[1].display || 'true',
            filter: true,
            filterList: localState?.filterList[1] || [],
            sortThirdClickReset: true,
            sortDescFirst: true,
            customBodyRender: val => val && format(val, 'dd-MM-yyyy'),
          },
        },
        {
          name: 'aircraft',
          label: 'Aircraft',
          options: {
            display: aircraftId ? 'false' : 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[2] || [],
            sortThirdClickReset: true,
          },
        },
        {
          name: 'users',
          label: 'Crew',
          options: {
            display: localState?.columns[3].display || 'true',
            filter: true,
            filterList: localState?.filterList[3] || [],
            sortThirdClickReset: true,
            customBodyRender: val => val?.map(u => <Chip key={u.email} label={u.trigram} />),
            sort: false,
            filterType: 'multiselect',
            filterOptions: {
              names: dbUsers.map(u => u.trigram).filter(t => !!t),
              logic: (users, filters) => !users.some(u => filters.includes(u.trigram)),
            },
          },
        },
        {
          name: 'testType',
          label: 'Test type',
          options: {
            display: localState?.columns[4].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[4] || [],
            sortThirdClickReset: true,
          },
        },
        {
          name: 'label',
          label: 'Label',
          options: {
            display: localState?.columns[5].display || 'true',
            filter: false,
            sortThirdClickReset: true,
          },
        },
        {
          name: 'title',
          label: 'Title',
          options: {
            display: localState?.columns[6].display || 'true',
            filter: true,
            filterType: 'multiselect',
            filterList: localState?.filterList[6] || [],
            sortThirdClickReset: true,
          },
        },
        {
          name: 'tasks',
          label: 'Tasks',
          options: {
            display: localState?.columns[7].display || 'true',
            sortThirdClickReset: true,
            customBodyRender: tasks => tasks && <CircularProgressWithLabel completed={tasks.reduce((acc, val) => val.completedTime ? acc + 1 : acc, 0)} total={tasks.length} />,
          },
        },
        {
          name: 'departure',
          label: 'Departure',
          options: {
            display: localState?.columns[8].display || 'false',
            filter: true,
            filterList: localState?.filterList[8] || [],
            sortCompare: order => (a, b) => a.data.ICAO.localeCompare(b.data.ICAO) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: val => typeof val === 'object' ? val.ICAO : val,
          },
        },
        {
          name: 'arrival',
          label: 'Arrival',
          options: {
            display: localState?.columns[9].display || 'false',
            filter: true,
            filterList: localState?.filterList[9] || [],
            sortCompare: order => (a, b) => a.data.ICAO.localeCompare(b.data.ICAO) * (order === 'asc' ? 1 : -1),
            sortThirdClickReset: true,
            customBodyRender: val => typeof val === 'object' ? val.ICAO : val,
          },
        },
        {
          name: 'offBlocksUtc',
          label: 'Off blocks',
          options: {
            display: localState?.columns[10].display || 'false',
            filter: false,
            sortThirdClickReset: true,
            sortDescFirst: true,
          },
        },
        {
          name: 'takeoffUtc',
          label: 'Takeoff',
          options: {
            display: localState?.columns[11].display || 'false',
            filter: false,
            sortThirdClickReset: true,
            sortDescFirst: true,
          },
        },
        {
          name: 'landingUtc',
          label: 'Landing',
          options: {
            display: localState?.columns[12].display || 'false',
            filter: false,
            sortThirdClickReset: true,
            sortDescFirst: true,
          },
        },
        {
          name: 'onBlocksUtc',
          label: 'On blocks',
          options: {
            display: localState?.columns[13].display || 'false',
            filter: false,
            sortThirdClickReset: true,
            sortDescFirst: true,
          },
        },
        {
          name: 'comments',
          label: 'Comments',
          options: {
            display: localState?.columns[14].display || 'false',
            filter: false,
            sortThirdClickReset: true,
          },
        },
        {
          name: 'purpose',
          label: 'Purpose',
          options: {
            display: 'false',
            sortThirdClickReset: true,
          },
        },
      ]}
      options={{
        selectableRows: 'none',
        downloadOptions: {
          filename: 'tests.csv',
          separator: ';',
          filterOptions: {
            useDisplayedColumnsOnly: false,
            useDisplayedRowsOnly: true,
          },
        },
        setRowProps: () => ({
          sx: {
            cursor: 'pointer',
            '&:hover': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
        }),
        onRowClick: (row, { dataIndex }) => navigate(TESTS + '/' + tests[dataIndex].id),
        onTableChange: (action, tableState) => {
          if (action === 'propsUpdate') return
          window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
        },
        sortOrder: localState?.sortOrder || {},
        rowsPerPage: localState?.rowsPerPage || 10,
        jumpToPage: true,
        rowsPerPageOptions: [10, 50, 100],
        pagination: !aircraftId,
        customToolbar: () => (<>
          <Tooltip title='Nouveau vol'>
            <span>
              <IconButton color='primary' onClick={() => setOpenNewTest(true)} disabled={readOnlyMode}>
                <Add />
              </IconButton>
            </span>
          </Tooltip>
        </>),
      }}
    />
    {openNewTest && <TestRequestDialog open onClose={() => setOpenNewTest(false)} aircraftInitId={aircraftId} />}
  </>)
}

TestTable.propTypes = {
  aircraftId: PropTypes.string,
}

export default TestTable
