import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import DeleteButton from '../_library/DeleteButton'
import { AIRCRAFT_COLLECTION, COMPONENT_CHANGE_COLLECTION, ITEM_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'
import { useSelector } from 'react-redux'


const EditChangeDialog = ({ change, onClose, items }) => {

  const componentChangeHooks = useFirestore(COMPONENT_CHANGE_COLLECTION)
  const itemHooks = useFirestore(ITEM_COLLECTION)
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)

  const [item, setItem] = useState(null)
  const [position, setPosition] = useState(change.position)
  const [designation, setDesignation] = useState(change.designation)
  const [partNoOff, setPartNoOff] = useState(change.partNoOff)
  const [serialNoOff, setSerialNoOff] = useState(change.serialNoOff)
  const [quantityOff, setQuantityOff] = useState(change.quantityOff)
  const [partNoOn, setPartNoOn] = useState(change.partNoOn)
  const [serialNoOn, setSerialNoOn] = useState(change.serialNoOn)
  const [quantityOn, setQuantityOn] = useState(change.quantityOn)
  const [batch, setBatch] = useState(change.batch)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    if (!items || item) return // eslint-disable-line no-useless-return
    else if (change?.itemRef) setItem(items.find(i => i.id === change.itemRef.id))
    else setItem(items[0])
  }, [items, item])

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    const obj = pickBy({
      ...change,
      itemRef: itemHooks.getDocRef(item.id),
      aircraftRef: aircraftHooks.getDocRef(selectedAircraftId),
      position,
      designation,
      partNoOff,
      serialNoOff,
      quantityOff,
      partNoOn,
      serialNoOn,
      quantityOn,
      batch,
    })
    const handler = data => change.id ? componentChangeHooks.updateDoc(change.id, data) : componentChangeHooks.addDoc(data)
    return handler(obj)
      .then(onClose)
      .finally(() => setSubmitted(false))
  }

  if (!change || !item) return <Loading />
  else return (
    <Dialog open onClose={onClose} component='form' onSubmit={handleSubmit}>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id='item-select-label'>Item</InputLabel>
          <Select
            labelId='item-select-label'
            id='item-select'
            value={item.id || ''}
            label='Item'
            onChange={e => setItem(items.find(i => i.id === e.target.value))}
          >
            {items.map(i =>
              <MenuItem key={i.id} value={i.id}>{i.label} - {i.defect}</MenuItem>,
            )}
          </Select>
        </FormControl>
        <TextField
          variant='outlined'
          margin='dense'
          id='position'
          label='Position'
          fullWidth
          value={position || ''}
          onChange={e => setPosition(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='designation'
          label='Designation'
          fullWidth
          value={designation || ''}
          onChange={e => setDesignation(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='partNoOff'
          label='Part No Off'
          fullWidth
          value={partNoOff || ''}
          onChange={e => setPartNoOff(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='serialNoOff'
          label='Serial No Off'
          fullWidth
          value={serialNoOff || ''}
          onChange={e => setSerialNoOff(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='quantityOff'
          label='Quantity Off'
          fullWidth
          value={quantityOff || 0}
          type='number'
          onChange={e => setQuantityOff(Number(e.target.value))}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='partNoOn'
          label='Part No On'
          fullWidth
          value={partNoOn || ''}
          onChange={e => setPartNoOn(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='serialNoOn'
          label='Serial No On'
          fullWidth
          value={serialNoOn || ''}
          onChange={e => setSerialNoOn(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='quantityOn'
          label='Quantity On'
          fullWidth
          value={quantityOn || 0}
          type='number'
          onChange={e => setQuantityOn(Number(e.target.value))}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='batch'
          label='Batch No'
          fullWidth
          value={batch || ''}
          onChange={e => setBatch(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={submitted}>Submit</Button>
        {change.id && <DeleteButton deleteAction={() => componentChangeHooks.deleteDoc(change.id).then(onClose)} text='Supprimer' />}
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditChangeDialog.propTypes = {
  change: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
}

export default EditChangeDialog
