import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogActions, DialogContent, DialogTitle, Dialog, Select, MenuItem } from '@mui/material'
import { useSelector } from 'react-redux'
import { addHours, format, startOfDay } from 'date-fns'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { AVAILABILITY_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const WriteAvailabilityDialog = ({ open, dayInit, onClose, availability }) => {

  const profile = useSelector(state => state.profile.data)
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs()
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)

  const [user, setUser] = useState(null)
  const [day, setDay] = useState(dayInit || new Date())
  const [startTime, setStartTime] = useState(new Date(format(dayInit || new Date(), 'yyyy-MM-dd') + 'T09:00'))
  const [endTime, setEndTime] = useState(new Date(format(dayInit || new Date(), 'yyyy-MM-dd') + 'T18:00'))
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    users && setUser(profile)
  }, [users])

  useEffect(() => {
    setStartTime(prev => new Date(day.getFullYear(), day.getMonth(), day.getDate(), prev.getHours(), prev.getMinutes()))
    setEndTime(prev => new Date(day.getFullYear(), day.getMonth(), day.getDate(), prev.getHours(), prev.getMinutes()))
  }, [day])

  useEffect(() => {
    if (availability) {
      setUser(users.find(i => i.id === availability.userRef.id))
      setDay(availability.dayDate)
      setStartTime(availability.startTime)
      setEndTime(availability.endTime)
    }
  }, [availability])

  const handleSubmit = () => {
    setSubmitted(true)
    const handler = data => availability ? availabilityHooks.updateDoc(availability.id, data) : availabilityHooks.addDoc(data)
    handler({
      userRef: userHooks.getDocRef(user.id),
      dayDate: startOfDay(day),
      startTime,
      endTime,
    }).then(onClose).finally(() => setSubmitted(false))
  }

  return <Dialog open={open} maxWidth='xs'>
    <DialogTitle>{availability ? 'Edit dispo' : 'Nouvelle dispo'}</DialogTitle>
    <DialogContent>
      <Select
        variant='standard'
        id='user-select'
        value={user?.id || ''}
        onChange={e => setUser(users.find(u => u.id === e.target.value))}
        fullWidth
        sx={{ my: 1 }}
      >
        {users?.map(u => <MenuItem key={u.id} value={u.id}>{u.firstname}</MenuItem>)}
      </Select>
      <DatePicker
        id='day'
        variant='inline'
        inputFormat='dd-MM-yyyy'
        value={day}
        fullWidth
        onChange={setDay}
        sx={{ mb: 1 }}
      />
      <TimePicker
        id='start'
        variant='inline'
        ampm={false}
        margin='dense'
        value={startTime}
        onChange={setStartTime}
        minutesStep={15}
        minTime={addHours(startOfDay(day), 8)}
        maxTime={addHours(startOfDay(day), 19)}
        sx={{ mb: 1 }}
      />
      <TimePicker
        id='end'
        variant='inline'
        ampm={false}
        margin='dense'
        value={endTime}
        onChange={setEndTime}
        minutesStep={15}
        minTime={addHours(startOfDay(day), 9)}
        maxTime={addHours(startOfDay(day), 20)}
        sx={{ mb: 1 }}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>Annuler</Button>
      {availability && <Button color='error' onClick={() => availabilityHooks.deleteDoc(availability.id)}>Supprimer</Button>}
      <Button disabled={submitted} onClick={handleSubmit}>Valider</Button>
    </DialogActions>
  </Dialog>
}

WriteAvailabilityDialog.propTypes = {
  open: PropTypes.bool,
  dayInit: PropTypes.object,
  onClose: PropTypes.func.isRequired,
  availability: PropTypes.object,
}

export default WriteAvailabilityDialog
