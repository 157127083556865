import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material'
import { REGEX_MAIL } from '../../_constants/misc'
import { USER_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'


const InviteMemberDialog = ({ onClose }) => {

  const userHooks = useFirestore(USER_COLLECTION)

  const [firstname, setFirstname] = useState(null)
  const [lastname, setLastname] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [errors, setErrors] = useState({})

  const handleSubmit = e => {
    e.preventDefault()
    if (!firstname) setErrors(prev => ({ ...prev, firstname: 'Un prénom doit être renseigné' }))
    else if (email && !email.match(REGEX_MAIL)) setErrors(prev => ({ ...prev, email: 'Le format de l\'email n\'est pas bon' }))
    else
      userHooks.addDoc({
        email: email?.toLowerCase().trim() || null,
        firstname: firstname?.trim() || null,
        lastname: lastname?.trim() || null,
        phone: phone?.trim() || null,
      }).then(onClose)
  }

  return (
    <Dialog open onClose={onClose} component='form' onSubmit={handleSubmit} sx={{
      '& .MuiInput-underline::before': {
        borderBottomColor: 'rgba(0,0,0,.1)',
      },
    }}>
      <DialogTitle id='form-dialog-title'>Inviter un nouveau membre</DialogTitle>
      <DialogContent>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='dense'
              id='firstname'
              label='Firstname'
              fullWidth
              value={firstname || ''}
              onChange={e => setFirstname(e.target.value)}
              error={Boolean(errors.firstname)}
              helperText={errors.firstname} />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='standard'
              margin='dense'
              id='lastname'
              label='Lastname'
              fullWidth
              value={lastname || ''}
              onChange={e => setLastname(e.target.value)}
              error={Boolean(errors.lastname)}
              helperText={errors.lastname} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='standard'
              margin='dense'
              id='email'
              label='Email'
              fullWidth
              value={email || ''}
              onChange={e => setEmail(e.target.value)}
              error={Boolean(errors.email)}
              helperText={errors.email} />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='standard'
              margin='dense'
              id='phone'
              label='Phone'
              fullWidth
              value={phone || ''}
              onChange={e => setPhone(e.target.value)}
              error={Boolean(errors.phone)}
              helperText={errors.phone} />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color='primary'>Annuler</Button>
        <Button type='submit' color='primary'>Valider</Button>
      </DialogActions>
    </Dialog>
  )
}

InviteMemberDialog.propTypes = {
  onClose: PropTypes.func,
}

export default InviteMemberDialog
