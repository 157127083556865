import React, { useState } from 'react'
import { Box, Fab } from '@mui/material'
import { Add } from '@mui/icons-material'
import TestRequestDialog from './TestRequestDialog'
import TestTable from './TestTable'


const AllTestsPanel = () => {

  const [open, setOpen] = useState(false)

  return (
    <Box sx={{ pb: '100px' }}>
      <TestTable />
      <Fab
        color='secondary'
        aria-label='add'
        onClick={() => setOpen(true)}
        sx={{
          position: 'fixed',
          right: theme => theme.spacing(3),
          bottom: theme => theme.spacing(3),
        }}
      >
        <Add />
      </Fab>
      {open && <TestRequestDialog open onClose={() => setOpen(false)} />}
    </Box>
  )
}

export default AllTestsPanel
