import React, { useState } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import { HomeRepairService } from '@mui/icons-material'


const weightUrl = 'http://10.0.1.49/'
const rexUrl = 'https://globe.adsbexchange.com/'

const DocsButton = () => {

  const [anchor, setAnchor] = useState(null)

  return (
    <>
      <IconButton onClick={e => setAnchor(e.currentTarget)} color='inherit'><HomeRepairService /></IconButton>
      <Menu
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
        PaperProps={{
          style: {
            width: 200,
          },
        }}
      >
        <MenuItem onClick={() => window.open(weightUrl, '_blank')}>
          Weight and balance
        </MenuItem>
        <MenuItem onClick={() => window.open(rexUrl, '_blank')}>
          ADSB Tracker
        </MenuItem>
      </Menu>
    </>
  )
}

export default DocsButton
