import { SELECT_USER, SELECT_AIRCRAFT, SELECT_TEST, TOGGLE_ADMIN_MODE, TOGGLE_READ_ONLY_MODE } from '../types'


export const selectUser = user => (dispatch, getState) =>
  dispatch({
    type: SELECT_USER,
    payload: user || getState().auth.profile,
  })

export const selectAircraftIdAction = id => async dispatch =>
  dispatch({
    type: SELECT_AIRCRAFT,
    payload: id,
  })

export const selectTestIdAction = id => dispatch =>
  dispatch({
    type: SELECT_TEST,
    payload: id,
  })

export const setAdminModeAction = val => dispatch =>
  dispatch({
    type: TOGGLE_ADMIN_MODE,
    payload: val,
  })

export const setReadOnlyModeAction = val => dispatch =>
  dispatch({
    type: TOGGLE_READ_ONLY_MODE,
    payload: val,
  })
