import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, DialogActions, DialogContent, FormControl, FormHelperText, Grid, InputLabel, MenuItem,
  Select, TextField, Typography, Autocomplete, Switch } from '@mui/material'
import { useSelector } from 'react-redux'
import { addHours, format, startOfDay } from 'date-fns'
import { DatePicker, TimePicker } from '@mui/x-date-pickers'
import { pickBy } from 'lodash'
import { AIRCRAFT_COLLECTION, AIRPORT_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import InfoBadge from '../_library/InfoBadge'
import { isNil } from 'lodash/lang'
import { EASA_FLIGHT_PURPOSES } from '../../_constants/misc'


export const TestRequestForm = ({ onClose, startDateInit, aircraftInitId, test }) => {

  const testHooks = useFirestore(TEST_COLLECTION, { storeAs: 'allTestAircraft' })
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const airportHooks = useFirestore(AIRPORT_COLLECTION)
  const tests = testHooks.getDocs()
  const aircrafts = aircraftHooks.getDocs()
  const airports = airportHooks.getDocs()
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)

  const [aircraftId, setAircraftId] = useState(test?.aircraftRef?.id || aircraftInitId || selectedAircraftId)
  const [startDate, setStartDate] = useState(test?.startDate || startDateInit || addHours(startOfDay(Date.now()), 9))
  const [testType, setTestType] = useState(test?.testType)
  const [testId, setTestId] = useState(test?.testId || 1)
  const [title, setTitle] = useState(test?.title)
  const [purpose, setPurpose] = useState(test?.purpose || 1)
  const [classification, setClassification] = useState(test?.classification || 1)
  const [mass, setMass] = useState(test?.mass)
  const [gravity, setGravity] = useState(test?.gravity)
  const [departure, setDeparture] = useState(null)
  const [arrival, setArrival] = useState(null)
  const [cerRequest, setCerRequest] = useState(test?.cerRequest || false)
  const [comments, setComments] = useState(test?.comments)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)

  const scroll = useRef()

  useEffect(() => {
    testHooks.listen({
      where: [['aircraftRef', '==', aircraftHooks.getDocRef(aircraftId)]],
      orderBy: [['testId', 'desc']],
    })
    return testHooks.unsubscribe
  }, [aircraftId])

  useEffect(function onAirportsLoaded() {
    if (airports) {
      setDeparture(airports.find(a => test?.departureRef ? a.id === test.departureRef.id : a.ICAO === 'LFBF'))
      setArrival(airports.find(a => test?.arrivalRef ? a.id === test.arrivalRef.id : a.ICAO === 'LFBF'))
    }
  }, [test?.departureRef, test?.arrivalRef, airports])

  useEffect(function onTypeChange() {
    setErrors(prev => ({ ...prev, testType: null }))
    if (!test && tests && aircraftId && testType) {
      const latestTest = tests.filter(t => t.testType === testType)[0]
      if (latestTest) {
        console.info(`latestTest id: ${latestTest?.id}, number: ${latestTest?.testId}`)
        setTestId(Number(latestTest.testId) + 1)
      }
    }
  }, [tests, aircraftId, testType])

  useEffect(function onDepartureChange() {setErrors(prev => ({ ...prev, departure: null }))}, [departure])
  useEffect(function onArrivalChange() { setErrors(prev => ({ ...prev, arrival: null }))}, [arrival])
  useEffect(function onStartDateChange() { setErrors(prev => ({ ...prev, startDate: null })) }, [startDate])
  useEffect(function onTitleChange() { setErrors(prev => ({ ...prev, title: null })) }, [title])

  const handleSubmit = () => {
    if (!aircraftId)
      setErrors(prev => ({ ...prev, aircraftId: 'il faut sélectionner un avion' }))
    if (!testType)
      setErrors(prev => ({ ...prev, testType: 'il faut sélectionner un type de test' }))
    if (!departure)
      setErrors(prev => ({ ...prev, departure: 'Le terrain de départ n\'est pas défini' }))
    if (!arrival)
      setErrors(prev => ({ ...prev, arrival: 'Le terrain d\'arrivée n\'est pas défini' }))
    if (!title)
      setErrors(prev => ({ ...prev, title: 'Give your test a title' }))
    if (aircraftId && testType && departure && arrival) {
      setSubmitted(true)
      const obj = pickBy({
        aircraftRef: aircraftHooks.getDocRef(aircraftId),
        startDate,
        testType,
        testId: parseInt(testId),
        title,
        mass,
        gravity,
        departureRef: airportHooks.getDocRef(departure.id),
        arrivalRef: airportHooks.getDocRef(arrival.id),
        cerRequest,
        comments,
        purpose,
        classification,
      }, val => !isNil(val))
      const handler = data => test?.id ? testHooks.updateDoc(test.id, data) : testHooks.addDoc(data)
      return handler(obj)
        .then(onClose)
        .finally(() => setSubmitted(false))
    }
    else
      scroll.current
        ? scroll.current.scrollIntoView({ block: 'center' })
        : window.scrollTo(0, 0)
  }

  return <>
    <DialogContent>
      <Grid container id='aircraftId' item xs={12} spacing={3}>
        <Grid item md={6}>
          {!!aircrafts?.length && <FormControl
            variant='standard'
            sx={{ width: '100%' }}
            error={Boolean(errors.aircraftId)}
            ref={el => {
              if (errors.aircraftId && el) scroll.current = el
            }}
          >
            <InputLabel id='aircraftId-select-label'>Aircraft</InputLabel>
            <Select
              variant='standard'
              labelId='aircraftId-select-label'
              id='aircraftId-select'
              value={aircraftId || ''}
              onChange={e => setAircraftId(e.target.value)}
            >
              {aircrafts.map(a => <MenuItem key={a.id} value={a.id}>{a.serial}</MenuItem>)}
            </Select>
            <FormHelperText>{errors.aircraft}</FormHelperText>
          </FormControl>}
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl
            variant='standard'
            sx={{ width: '100%' }}
            error={Boolean(errors.testType)}
            ref={el => {
              if (errors.testType && el) scroll.current = el
            }}
          >
            <InputLabel id='test-type-select-label'>Type of test</InputLabel>
            <Select
              variant='standard'
              labelId='test-type-select-label'
              id='test-type-select'
              value={testType || ''}
              onChange={e => setTestType(e.target.value)}
            >
              <MenuItem value='ground'>Ground</MenuItem>
              <MenuItem value='run'>Run</MenuItem>
              <MenuItem value='taxi'>Taxi</MenuItem>
              <MenuItem value='flight'>Test Flight</MenuItem>
              <MenuItem value='checkFlight'>Reception</MenuItem>
            </Select>
            <FormHelperText>{errors.testType}</FormHelperText>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='testTitle' item xs={12} spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            id='testId'
            label='Test Number'
            placeholder='0001'
            type='number'
            fullWidth
            value={testId || ''}
            onChange={e => setTestId(e.target.value)}
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.testId)}
            helperText={errors.testId}
            ref={el => {
              if (errors.testId && el) scroll.current = el
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            id='title'
            label='Title'
            fullWidth
            value={title || ''}
            onChange={e => setTitle(e.target.value)}
            error={Boolean(errors.title)}
            helperText={errors.title}
            ref={el => {
              if (errors.title && el) scroll.current = el
            }}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <FormControl variant='standard' fullWidth>
            <InputLabel id='purpose-select-label'>EASA Flight conditions purpose</InputLabel>
            <Select
              variant='standard'
              labelId='purpose-select-label'
              id='purpose-select'
              value={purpose || ''}
              onChange={e => setPurpose(e.target.value)}
            >
              {EASA_FLIGHT_PURPOSES.map(p => <MenuItem key={p.code} value={p.code}>{p.code} {p.title}</MenuItem>)}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4}>
          <FormControl variant='standard' fullWidth>
            <InputLabel id='classification-select-label'>Classification</InputLabel>
            <Select
              variant='standard'
              labelId='classification-select-label'
              id='classification-select'
              value={classification || ''}
              onChange={e => setClassification(e.target.value)}
            >
              <MenuItem value={1}>Class 1</MenuItem>
              <MenuItem value={2}>Class 2</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid container id='date' item xs={12} spacing={3} mt={1}>
        <Grid item md={6}>
          <DatePicker
            disableToolbar
            variant='inline'
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='startDate-date'
            label='Date of test'
            value={startDate}
            onChange={setStartDate}
            style={{ marginTop: 30 }}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TimePicker
            ampm={false}
            margin='dense'
            id='startDate-time'
            label='Heure de départ'
            value={startDate}
            minutesStep={5}
            onChange={date =>
              date instanceof Date && !isNaN(date.valueOf()) &&
              setStartDate(prev => new Date(format(prev, 'yyyy-MM-dd') + `T${('00' + date.getHours()).slice(-2)}:${('00' + date.getMinutes()).slice(-2)}:00`))
            }
            ref={el => {
              if (errors.startDate && el) scroll.current = el
            }}
            slotProps={{ textField: { error: !!errors.startDate, helperText: errors.startDate } }}
          />
        </Grid>
      </Grid>
      <Grid container id='config' item xs={12} spacing={3} mt={1}>
        <Typography variant='subtitle1' width='100%' mt={4}>Configuration</Typography>
        <Grid item md={6} xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            id='mass'
            label='Mass (kg)'
            placeholder='995'
            type='number'
            fullWidth
            value={mass || ''}
            onChange={e => setMass(e.target.value)}
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.mass)}
            helperText={errors.mass}
            ref={el => {
              if (errors.mass && el) scroll.current = el
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            id='gravity'
            label='Center of gravity (%)'
            placeholder='26'
            type='number'
            fullWidth
            value={gravity || ''}
            onChange={e => setGravity(e.target.value)}
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.gravity)}
            helperText={errors.gravity}
            ref={el => {
              if (errors.gravity && el) scroll.current = el
            }}
          />
        </Grid>
      </Grid>
      <Grid container id='airports' item xs={12} spacing={3} mt={1}>
        <Typography variant='subtitle1' width='100%' mt={4}>Terrains</Typography>
        <Grid item md={6} xs={12}>
          {!!departure && <Autocomplete
            id='departure-select'
            options={airports}
            getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={params =>
              <TextField
                {...params}
                variant='standard'
                label='Departure'
                fullWidth
                error={Boolean(errors.departure)}
                helperText={errors.departure}
                ref={el => {
                  if (errors.departure && el) scroll.current = el
                }}
              />
            }
            value={departure || ''}
            onChange={(event, newValue) => setDeparture(newValue)}
          />}
        </Grid>
        <Grid item md={6} xs={12}>
          {!!arrival && <Autocomplete
            autoComplete
            autoSelect
            id='arrival-select'
            options={airports}
            getOptionLabel={option => option.ICAO ? option.ICAO + ' - ' + option.name : ''}
            isOptionEqualToValue={(option, value) => option.id === value?.id}
            renderInput={params =>
              <TextField
                {...params}
                variant='standard'
                label='Arrival'
                fullWidth
                error={Boolean(errors.arrival)}
                helperText={errors.arrival}
                ref={el => {
                  if (errors.arrival && el) scroll.current = el
                }}
              />
            }
            value={arrival || ''}
            onChange={(event, newValue) => setArrival(newValue)}
          />}
        </Grid>
      </Grid>
      <Grid container id='miscelaneous' spacing={3} mt={1}>
        <Typography variant='subtitle1' width='100%' mt={4}>Miscelaneous</Typography>
        <Switch checked={cerRequest} onChange={e => setCerRequest(e.target.checked)} />
        <InfoBadge component='p' title='Tick this box if flight requires CER services'><Typography sx={{ verticalAlign: '-webkit-baseline-middle', display: 'inline-block' }}>Request CER</Typography></InfoBadge>
        <Grid item xs={12}>
          <TextField
            variant='standard'
            margin='dense'
            label='Description'
            fullWidth
            value={comments || ''}
            multiline
            onChange={e => setComments(e.target.value)} />
        </Grid>
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose} color='primary'>Annuler</Button>
      <Button onClick={handleSubmit} color='primary' disabled={submitted}>Valider</Button>
    </DialogActions>
  </>
}

TestRequestForm.propTypes = {
  onClose: PropTypes.func,
  startDateInit: PropTypes.object,
  aircraftInitId: PropTypes.string,
  test: PropTypes.object,
}

export default TestRequestForm
