/**
 * Cross Project constants
 *
 * This file purpose is to centralise all constants used across project
 * It is part of the project root folder and scripts may copy it in some subfolders if needed
 */
module.exports = {
  AIRCRAFT_COLLECTION: 'aircrafts',
  AIRPORT_COLLECTION: 'airports',
  AVAILABILITY_COLLECTION: 'availabilities',
  COMPONENT_CHANGE_COLLECTION: 'componentChanges',
  EMAIL_COLLECTION: 'emails',
  HIL_ITEM_COLLECTION: 'hilItems',
  HISTORY_COLLECTION: 'history',
  ITEM_COLLECTION: 'items',
  NOTIFICATION_COLLECTION: 'notifications',
  OPERATION_COLLECTION: 'operations',
  TANK_COLLECTION: 'tanks',
  TASK_COLLECTION: 'tasks',
  TEST_COLLECTION: 'tests',
  USER_COLLECTION: 'users',

  ROLE_ADMIN: 'admin',
  ROLE_CAPTAIN: 'captain',
  ROLE_PILOT: 'pilot',
  ROLE_ENGINEER: 'engineer',
  ROLE_MECHANICS: 'mechanics',
  ROLE_VISITOR: 'visitor',

  AIRCRAFT_TYPE_IS: 'IS',
  AIRCRAFT_TYPE_IR: 'IR',
  AIRCRAFT_TYPE_IRE: 'IRE',
  AIRCRAFT_TYPE_ISE: 'ISE',
}
