import React  from 'react'
import { AppBar, Card, CardContent, CardHeader, Grid, IconButton, Paper } from '@mui/material'
import Page from '../_library/Page'
import WeeklyTable from '../calendar/WeeklyTable'
import { format } from 'date-fns'
import AircraftBigCard from '../aircrafts/AircraftBigCard'
import { CALENDAR } from '../../_constants/routes'
import { ArrowForward } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import AdminCard from './AdminCard'


export const DashboardPage = () => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()?.filter(a => !a.archived)
  const navigate = useNavigate()
  const adminMode = useSelector(state => state.data.adminMode)
  
  return <Page withTopBarAndMenu>
    <AppBar
      component='div'
      position='relative'
      elevation={0}
      color='secondary'
      sx={{ height: '40vh', borderRadius: 0 }}
    />
    <Paper sx={{ mt: '-35vh', mx: 'auto', maxWidth: '936px', backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Grid container justifyContent='center' spacing={1} px={2}>
        <Grid item md={12} sx={{ zIndex: 1, ml: { xs: 1, md: 'inherit' } }}>
          <Card sx={{ mb: 1 }}>
            <CardHeader
              title={format(new Date(), 'MMMM')}
              action={<IconButton aria-label='settings' onClick={() => navigate(CALENDAR)}><ArrowForward /></IconButton>}
            />
            <CardContent>
              <WeeklyTable />
            </CardContent>
          </Card>
        </Grid>
        {adminMode && <AdminCard />}
        {aircrafts?.map(aircraft =>
          <Grid item xs={12} md={4} key={aircraft.id}>
            <AircraftBigCard aircraft={aircraft} />
          </Grid>,
        )}
      </Grid>
    </Paper>
  </Page>
}

export default DashboardPage
