import React from 'react'
import { Card, CardContent, Typography, Divider, Grid, Box } from '@mui/material'
import { useSelector } from 'react-redux'
import InlineEditor from '../_library/InlineEditor'
import { useFirestore } from '../../hooks/useFirestore'
import { USER_COLLECTION } from '../../_constants/globals'


const ProfilePanel = () => {
  
  const profile = useSelector(state => state.profile.data)
  const userHooks = useFirestore(USER_COLLECTION)
  
  return (
    <Box sx={{ my: 3, mx: 'auto', maxWidth: 900 }}>
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Identité</Typography>
      <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
        <CardContent>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Nom' disabled value={profile.lastname} />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Prénom' disabled value={profile.firstname} />
          </Grid>
        </CardContent>
      </Card>
      
      <Typography variant='body2' color='textSecondary' gutterBottom mb={1}>Coordonnées</Typography>
      <Card variant='outlined' sx={{ minWidth: '275px', mb: 3 }}>
        <CardContent>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Email' disabled value={profile.email} />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Téléphone' value={profile.phone} handleSubmit={val => userHooks.updateDoc(profile.id, { phone: val })} />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Trigram' value={profile.trigram} handleSubmit={val => userHooks.updateDoc(profile.id, { trigram: val })} />
          </Grid>
          <Grid item xs={12} sx={{ mb: 1 }}>
            <InlineEditor title='Pin' value={profile.pin} type='password' handleSubmit={val => userHooks.updateDoc(profile.id, { pin: val })} />
          </Grid>
        </CardContent>
      </Card>
      
      <Divider light />
    </Box>
  )
}

export default ProfilePanel
