import React, { useEffect, useMemo, useState } from 'react'
import { AppBar, Avatar, Button, Card, CardHeader, Chip, Grid, IconButton, Paper, Typography } from '@mui/material'
import Page from '../_library/Page'
import { endOfDay, format, startOfDay } from 'date-fns'
import { CALENDAR, TESTS } from '../../_constants/routes'
import { ArrowForward } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION, AVAILABILITY_COLLECTION, ITEM_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import { useNavigate } from 'react-router-dom'
import EditActionDialog from '../technicalItems/EditActionDialog'
import AircraftViewerCard from '../aircrafts/AircraftViewerCard'


export const ViewerDashboardPage = () => {

  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const aircrafts = aircraftHooks.getDocs()?.filter(a => !a.archived)
  const navigate = useNavigate()
  const itemHooks = useFirestore(ITEM_COLLECTION)
  const dbItems = itemHooks.getDocs()
  const availabilityHooks = useFirestore(AVAILABILITY_COLLECTION)
  const availabilities = availabilityHooks.getDocs()
  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs()

  const [selectedItem, setSelectedItem] = useState(null)

  useEffect(() => {
    itemHooks.listen({
      orderBy: [['label', 'asc']],
    })
    availabilityHooks.listen({
      where: [
        ['dayDate', '>=', startOfDay(new Date())],
        ['dayDate', '<=', endOfDay(new Date())],
      ],
      orderBy: [['dayDate', 'asc']],
    })
    testHooks.listen({
      where: [
        ['startDate', '>=', startOfDay(new Date())],
        ['startDate', '<=', endOfDay(new Date())],
      ],
      orderBy: [['startDate', 'asc']],
    })
    return () => {
      itemHooks.unsubscribe()
      availabilityHooks.unsubscribe()
      testHooks.unsubscribe()
    }
  }, [])

  const items = useMemo(() => dbItems.filter(i => !i.testRef), [dbItems])

  return <Page withTopBarAndMenu>
    <AppBar
      component='div'
      position='relative'
      elevation={0}
      color='secondary'
      sx={{ height: '50vh', borderRadius: 0 }}
    />
    <Paper sx={{ mt: 'calc(-50vh + 8px)', mx: 'auto', backgroundColor: 'transparent', boxShadow: 'none' }}>
      <Grid container justifyContent='center' spacing={1} px={2}>
        <Grid item md={12} sx={{ zIndex: 1, ml: { xs: 1, md: 'inherit' } }}>
          <Card sx={{ mb: 1 }}>
            <CardHeader
              avatar={<Avatar
                variant='rounded'
                sx={{ bgcolor: 'primary.light', width: 'initial', px: 1 }}
              >{format(new Date(), 'MMMM, dd')}</Avatar>}
              title={<>
                <Typography display='inline-block' mr={2} fontWeight={600}>Today</Typography>
                {availabilities?.map(a => <Chip key={a.id} size='small' label={a.user?.trigram} sx={{ mr: 1 }} />)}
                {tests?.map(t => <Button key={t.id} variant='outlined' onClick={() => navigate(`${TESTS}/${t.id}`)} sx={{ mr: 1 }}>
                  {format(t.startDate, 'HH:mm') + ' ' + t.label}
                </Button>)}
              </>}
              action={<IconButton aria-label='settings' onClick={() => navigate(CALENDAR)}><ArrowForward /></IconButton>}
            />
          </Card>
        </Grid>
        {aircrafts?.map(aircraft => <AircraftViewerCard
          key={aircraft.id}
          aircraft={aircraft}
          items={items.filter(i => i.aircraftRef.id === aircraft.id)}
        /> )}
      </Grid>
    </Paper>
    {selectedItem && <EditActionDialog item={selectedItem} onClose={() => setSelectedItem(null)} />}
  </Page>
}

export default ViewerDashboardPage
