/* eslint-disable react/display-name */
import React, { useEffect, useMemo } from 'react'
import MUIDataTable from 'mui-datatables'
import { format } from 'date-fns'
import { useFirestore } from '../../hooks/useFirestore'


const HistoryTable = () => {

  const historyHooks = useFirestore('history')
  const logs = historyHooks.getDocs()

  useEffect(() => {
    historyHooks.listen({
      omit: ['zBeforeData', 'zzAfterData'],
    })
    return () => historyHooks.unsubscribe()
  }, [])

  const data = useMemo(() => logs?.map(log => [log._createdAtTime, log.action, log.documentRef?.path, log.userRef?.path]), [logs])

  return (
    <MUIDataTable
      data={data}
      title='Component changes'
      columns={[
        {
          name: '_createdAtTime',
          options: {
            filter: false,
            customBodyRender: date => date && format(date, 'dd-MM-yyyy HH:mm:ss'),
          },
        },
        { name: 'Action' },
        { name: 'Document' },
        { name: 'User' },
      ]}
      options={{
        responsive: 'standard',
        textLabels: { body: { noMatch: 'No item' } },
      }}
    />
  )
}

export default HistoryTable
