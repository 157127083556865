import React, { useEffect, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import * as ROUTES from './_constants/routes.js'
import LoginPage from './components/auth/LoginPage'
import Alerts from './components/Alerts'
import DashboardPage from './components/dashboard/DashboardPage'
import NotFoundPage from './components/NotFoundPage'
import Loading from './components/_library/Loading'
import { AIRCRAFT_COLLECTION, AIRPORT_COLLECTION, USER_COLLECTION } from './_constants/globals'
import RestrictedArea from './components/_library/RestrictedArea'
import AircraftPage from './components/aircrafts/AircraftPage'
import TestPage from './components/tests/TestPage'
import UserPage from './components/users/userPage'
import CalendarPage from './components/calendar/CalendarPage'
import SettingsPage from './components/settings/SettingsPage'
import useAuth from './hooks/useAuth'
import { useSelector } from 'react-redux'
import { useFirestore } from './hooks/useFirestore'
import { listenerRefs } from './firebase'
import { mapValues } from 'lodash'
import ViewerDashboardPage from './components/dashboard/ViewerDashboardPage'


const App = () => {
  
  const profile = useSelector(state => state.profile.data)
  const auth = useAuth()
  const aircraftHook = useFirestore(AIRCRAFT_COLLECTION)
  const airportHook = useFirestore(AIRPORT_COLLECTION)
  const userHook = useFirestore(USER_COLLECTION)
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  useEffect(() => {
    auth.listenProfile()
    return () => {
      mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    }
  }, [])

  useEffect(() => {
    if (profile.id) {
      aircraftHook.listen({ orderBy: [['serial', 'asc']] })
      airportHook.listen({ orderBy: [['occurrences', 'desc']] })
      userHook.listen({ orderBy: [['lastname', 'asc']] })
    }
  }, [profile?.id])

  useEffect(() => {
    console.log('Environnement: ' + process.env.NODE_ENV + ' ' + process.env.REACT_APP_FIREBASE_PROJECT_ID)
  }, [])

  return (
    <BrowserRouter>
      <Suspense fallback={<Loading />} >
        <Routes>
          <Route path={ROUTES.HOME} element={<RestrictedArea loggedIn>{readOnlyMode ? <ViewerDashboardPage /> : <DashboardPage />}</RestrictedArea>} />
          <Route path={ROUTES.HOME} element={<RestrictedArea loggedIn><DashboardPage /></RestrictedArea>} />x
          <Route path={ROUTES.LOGIN} element={<RestrictedArea loggedOut><LoginPage /></RestrictedArea>} />
          <Route path={ROUTES.AIRCRAFTS + '/*'} element={<RestrictedArea loggedIn><AircraftPage /></RestrictedArea>} />
          <Route path={ROUTES.CALENDAR + '/*'} element={<RestrictedArea loggedIn><CalendarPage /></RestrictedArea>} />
          <Route path={ROUTES.SETTINGS + '/*'} element={<RestrictedArea loggedIn><SettingsPage /></RestrictedArea>} />
          <Route path={ROUTES.TESTS + '/*'} element={<RestrictedArea loggedIn><TestPage /></RestrictedArea>} />
          <Route path={ROUTES.USERS + '/*'} element={<RestrictedArea loggedIn><UserPage /></RestrictedArea>} />
          <Route path='*' element={<NotFoundPage />} />
        </Routes>
        <Alerts />
      </Suspense>
    </BrowserRouter>
  )
}

export default App
