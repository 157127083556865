import { Card, CardContent, CardHeader, Tooltip } from '@mui/material'
import Avatar from '../_library/Avatar'
import React from 'react'
import PropTypes from 'prop-types'


const ItemCell = ({ title, subheader, user, content, tooltip, sx, ...props }) => (
  <Card sx={{ boxShadow: 'none', ...sx }} {...props}>
    <CardHeader
      avatar={<Avatar user={user} />}
      title={title}
      subheader={subheader}
      sx={{ p: 0 }}
    />
    <CardContent sx={{ p: 0, pt: 1, '&:last-child': { pb: 0 } }}>
      {tooltip
        ? <Tooltip title={tooltip}>{content}</Tooltip>
        : content
      }
    </CardContent>
  </Card>
)

ItemCell.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  user: PropTypes.object,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  tooltip: PropTypes.string,
  sx: PropTypes.object,
}

export default ItemCell
