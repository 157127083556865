import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, useLocation } from 'react-router-dom'
import { HOME, LOGIN } from '../../_constants/routes'
import Loading from './Loading'
import { useSelector } from 'react-redux'

const RestrictedArea = ({ children, needRoles, loggedOut, loggedIn }) => {
  const profile = useSelector(state => state.profile)
  const location = useLocation()
  
  if (!profile.isLoaded) return <Loading />
  
  if (needRoles) return profile.data.roles?.length > 0 ? children : (<Navigate to={LOGIN} state={{ from: location }} replace />)
  
  if (loggedIn) return !profile.data.isEmpty ? children : (<Navigate to={LOGIN} state={{ from: location }} replace />)
  
  if (loggedOut) return profile.data.isEmpty ? children : (<Navigate to={HOME} state={{ from: location }} replace />)
  
  return children
}

RestrictedArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  needRoles: PropTypes.bool,
  loggedOut: PropTypes.bool,
  loggedIn: PropTypes.bool,
}

export default RestrictedArea
