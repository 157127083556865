import React, { useState, useMemo } from 'react'
import Cropper from 'react-easy-crop'
import getCroppedImg from '../../_helpers/imageHelper'
import PropTypes from 'prop-types'
import { Box, Button } from '@mui/material'
import { CropRotate } from '@mui/icons-material'


const ImageCropper = ({ onComplete, imageUrl, cropShape = 'rect', aspectRatio = 2 }) => {
  const [rawImage, setRawImage] = useState(null)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)

  const imgUrl = useMemo(() => rawImage && URL.createObjectURL(rawImage), [rawImage])

  const onCropComplete = (croppedArea, areaPixels) => getCroppedImg(imgUrl, areaPixels, rotation).then(onComplete)

  return (
    <Box sx={{
      bgcolor: 'grey.500',
      width: '100%',
      aspectRatio: `${aspectRatio}`,
      position: 'relative',
      backgroundImage: `url(${imageUrl})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
    }}>
      {imgUrl && <Cropper
        image={imgUrl}
        crop={crop}
        rotation={rotation}
        zoom={zoom}
        aspect={aspectRatio}
        onCropChange={setCrop}
        onRotationChange={setRotation}
        onCropComplete={onCropComplete}
        onZoomChange={setZoom}
        showGrid={false}
        objectFit='cover'
        cropShape={cropShape}
      />}
      {imgUrl && <Button
        onClick={() => setRotation(prev => (prev - 90) % 360)}
        sx={{ color: 'grey.100' }}
      ><CropRotate /></Button>}
      <Button
        variant='contained'
        component='label'
        sx={{ position: 'absolute', bottom: theme => theme.spacing(1), right: theme => theme.spacing(2) }}
      >
        {imageUrl
          ? 'Modifier'
          : 'Ajouter une photo de couverture'
        }
        <input hidden accept='image/*' type='file' onChange={e => setRawImage(e.target.files[0])} />
      </Button>
    </Box>
  )
}

ImageCropper.propTypes = {
  onComplete: PropTypes.func,
  imageUrl: PropTypes.string,
  cropShape: PropTypes.string,
  aspectRatio: PropTypes.number,
}

export default ImageCropper
