import { useGoogleLogin } from 'react-google-login'
import { useEffect, useState } from 'react'
import { gapi } from 'gapi-script'
import { sleep } from '../_helpers/jsHelper'


const SCOPES = 'https://www.googleapis.com/auth/drive'
const FLIGHT_TEST_DRIVE_ID = '0ALVtnqeNTFH2Uk9PVA'

const useGoogleApi = () => {

  const { loaded } = useGoogleLogin({ clientId: process.env.REACT_APP_OAUTH_CLIENT_ID })

  const [accessToken, setAccessToken] = useState(null)

  useEffect(() => {
    async function start() {
      await gapi.client.init({
        clientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
        apiKey: '1:583899971384:web:d787dd0231165f36d04bcd', // process.env.REACT_APP_FIREBASE_API_KEY,
        scope: SCOPES,
      })
      console.log('gapi init')
      await sleep(500)
      const token = gapi.auth.getToken()
      console.log('token', token)
      !!token && setAccessToken(token.access_token)
    }
    start()
  }, [])

  const getDocument = id =>
    fetch(`https://docs.googleapis.com/v1/documents/${id}`, {
      method: 'GET',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
    })
      .then(res => res.json())

  const createFolder = (name, parent) =>
    fetch('https://www.googleapis.com/drive/v3/files?supportsAllDrives=true', {
      method: 'POST',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      body: JSON.stringify({
        name,
        mimeType: 'application/vnd.google-apps.folder',
        driveId: FLIGHT_TEST_DRIVE_ID,
        parents: [parent],
      }),
    })
      .then(res => res.json())

  const copyTemplate = (templateId, folder, docName) =>
    fetch(`https://www.googleapis.com/drive/v3/files/${templateId}/copy?supportsAllDrives=true`, {
      method: 'POST',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      body: JSON.stringify({
        name: docName,
        mimeType: 'application/vnd.google-apps.document',
        driveId: FLIGHT_TEST_DRIVE_ID,
        parents: [folder],
      }),
    })
      .then(res => res.json())

  const hydrateTestOrder = (document, requests) =>
    fetch(`https://docs.googleapis.com/v1/documents/${document.id}:batchUpdate`, {
      method: 'POST',
      headers: new Headers({ Authorization: 'Bearer ' + accessToken }),
      body: JSON.stringify({ requests }),
    })
      .then(() => document)

  const getDocumentUrl = id => `https://docs.google.com/document/d/${id}`

  return { ready: loaded && !!accessToken, createFolder, getDocument, copyTemplate, hydrateTestOrder, getDocumentUrl }
}

export default useGoogleApi
