import { deleteUser, getAdditionalUserInfo, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup,
  signOut as apiSignOut } from 'firebase/auth'
import { doc, onSnapshot, serverTimestamp, setDoc } from 'firebase/firestore'
import { USER_COLLECTION } from '../_constants/globals'
import { auth, db, listenerRefs } from '../firebase'
import { useDispatch } from 'react-redux'
import { actions } from '../store/slices/profileSlice'
import { mapValues } from 'lodash'
import { resetLocalStorage } from '../_helpers/localStorageHelper'
import { setReadOnlyModeAction } from '../store/actions/dataActions'


const useAuth = () => {
  const dispatch = useDispatch()

  const listenProfile = () =>
    onAuthStateChanged(auth, authUser => {
      if (authUser) {
        const collectionListener = onSnapshot(doc(db, USER_COLLECTION, authUser.uid), async dbUser => {
          const profile = {
            id: dbUser.id,
            ...dbUser.data(),
            identityProvider: authUser.auth.currentUser.providerData[0].providerId,
            isAnonymous: auth.currentUser.isAnonymous,
            emailVerified: auth.currentUser.emailVerified,
            isLoaded: true,
            token: await auth.currentUser.getIdTokenResult(),
            isEmpty: false,
          }
          console.info('profile', profile)
          return Promise.all([
            dispatch(setReadOnlyModeAction((!profile.roles || profile.roles.length === 0))),
            dispatch(actions.success(profile)),
          ])
        })
        listenerRefs.auth = { unsubscribe: collectionListener }
      }
      else dispatch(actions.success({ isEmpty: true }))
    })

  const signIn = ({ email, password }) => signInWithEmailAndPassword(auth, email, password)

  /**
   * Signs in with Google account
   */
  const googleSignIn = () => new Promise((resolve, reject) => {
    auth.useDeviceLanguage()
    const provider = new GoogleAuthProvider()
    provider.setCustomParameters({ login_hint: 'user@example.com' })
    signInWithPopup(auth, provider)
      .then(result => ({ ...getAdditionalUserInfo(result), uid: result.user.uid }))
      .then(user => user.isNewUser
        ? ['aura-aero.com', 'mikegoulianaviation.com'].includes(user.profile.hd)
          ? setDoc(doc(db, 'users', user.uid), {
            firstname: user.profile.given_name,
            lastname: user.profile.family_name,
            email: user.profile.email,
            photoUrl: user.profile.picture,
            roles: [],
            _createdAtTime: serverTimestamp(),
            _updatedAtTime: serverTimestamp(),
          }).then(resolve)
          : deleteUser(auth.currentUser).then(() => reject(new Error('External user')))
        : resolve(),
      )
  })

  const signOut = () => {
    mapValues(listenerRefs, ({ unsubscribe }) => unsubscribe())
    resetLocalStorage()
    return apiSignOut(auth)
  }

  return { listenProfile, signIn, googleSignIn, signOut }
}

export default useAuth
