import React, { useState } from 'react'
import TopBar from '../menu/TopBar'
import PropTypes from 'prop-types'
import NavigationDrawer from '../menu/NavigationDrawer'
import { Box } from '@mui/material'
import { compact } from 'lodash'
import { Navigate, Route, Routes } from 'react-router-dom'


const Page = ({ tabs, children, withTopBarAndMenu, containerSx, sx }) => {

  const [menuOpen, setMenuOpen] = useState(false)

  return (
    <Box sx={{ height: '100vh', backgroundColor: 'background.default', ...sx }}>
      {withTopBarAndMenu && <nav><NavigationDrawer menuOpen={menuOpen} setMenuOpen={setMenuOpen} /></nav>}
      {withTopBarAndMenu && <TopBar tabs={compact(tabs)} setMenuStatus={setMenuOpen} />}
      <Box sx={{ mt: withTopBarAndMenu && '48px', ...containerSx }}>
        {tabs
          ? <Routes>
            {compact(tabs).map(tab =>
              <Route
                key={'route' + tab.key}
                path={tab.route}
                element={tab.content}
              />,
            )}
            <Route path='*' element={<Navigate replace to={tabs[0].route} />} />
          </Routes>
          : children
        }
      </Box>
    </Box>
  )
}

Page.propTypes = {
  tabs: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.element]),
  withTopBarAndMenu: PropTypes.bool,
  containerSx: PropTypes.object,
  sx: PropTypes.object,
}

export default Page
