/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState } from 'react'
import { Box, IconButton, Tooltip } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import { format } from 'date-fns'
import { Add } from '@mui/icons-material'
import PropTypes from 'prop-types'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION, COMPONENT_CHANGE_COLLECTION, ITEM_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import EditChangeDialog from './EditChangeDialog'
import { useSelector } from 'react-redux'


const ComponentChangeTable = ({ testId, aircraftId, disableAdd }) => {

  const testHooks = useFirestore(TEST_COLLECTION)
  const itemHooks = useFirestore(ITEM_COLLECTION, { storeAs: 'ItemTable' })
  const items = itemHooks.getDocs()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const changeHooks = useFirestore(COMPONENT_CHANGE_COLLECTION)
  const changes = changeHooks.getDocs()
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [selectedChangeIndex, setSelectedChangeIndex] = useState(null)

  const selectedChange = useMemo(
    () => {
      if (!changes || selectedChangeIndex === null) return null
      else if (changes[selectedChangeIndex]) return changes[selectedChangeIndex]
      else if (testId) return ({ testRef: testHooks.getDocRef(testId) })
      else if (aircraftId) return ({ aircraftRef: aircraftHooks.getDocRef(aircraftId) })
    }, [changes, selectedChangeIndex])

  useEffect(() => {
    let where
    if (testId) where = [['testRef', '==', testHooks.getDocRef(testId)]]
    else if (aircraftId) where = [['aircraftRef', '==', aircraftHooks.getDocRef(aircraftId)]]
    changeHooks.listen(pickBy({
      where,
      orderBy: [
        ['item.label', 'asc'],
        ['_createdAtTime', 'asc'],
      ],
    }))
    return changeHooks.unsubscribe
  }, [testId, aircraftId])

  const data = useMemo(() =>
    changes?.map(({ item, position, designation, partNoOff, serialNoOff, quantityOff, partNoOn, serialNoOn, batch, quantityOn, _createdAtTime }) =>
      [item?.label, position, designation, partNoOff, serialNoOff, quantityOff, partNoOn, serialNoOn, batch, quantityOn, _createdAtTime],
    ), [changes])

  return (
    <Box sx={{ mx: 'auto', maxWidth: '1200px' }}>
      <MUIDataTable
        data={data}
        title='Component changes'
        columns={[
          { name: 'Defect item' },
          { name: 'Position' },
          { name: 'Designation' },
          { name: 'Part No OFF' },
          { name: 'Serial No OFF' },
          { name: 'Quantity OFF' },
          { name: 'Part No ON' },
          { name: 'Serial No ON' },
          { name: 'Batch No' },
          { name: 'Quantity ON' },
          {
            name: '_createdAtTime',
            options: {
              display: 'false',
              filter: false,
              customBodyRender: date => date && format(date, 'dd-MM-yyyy'),
            },
          },
        ]}
        options={{
          selectableRows: 'none',
          responsive: 'standard',
          onRowClick: (row, { dataIndex }) => setSelectedChangeIndex(dataIndex),
          download: false,
          filter: false,
          search: false,
          print: false,
          selectableRowsHeader: false,
          viewColumns: false,
          pagination: false,
          sort: false,
          textLabels: { body: { noMatch: 'No item' } },
          setRowProps: () => ({ sx: { cursor: 'pointer' } }),
          customToolbar: () => (
            <Tooltip title='Add a component change'>
              <span>
                <IconButton color='primary' onClick={() => setSelectedChangeIndex('item')} disabled={disableAdd || readOnlyMode }>
                  <Add />
                </IconButton>
              </span>
            </Tooltip>
          ),
        }}
      />
      {selectedChange && items && <EditChangeDialog change={selectedChange} onClose={() => setSelectedChangeIndex(null)} items={items.filter(i => i.type ==='item')} />}
    </Box>
  )
}

ComponentChangeTable.propTypes = {
  testId: PropTypes.string,
  aircraftId: PropTypes.string,
  disableAdd: PropTypes.bool,
}

export default ComponentChangeTable
