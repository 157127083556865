import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardContent, CardHeader, Grid, IconButton, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { TASK_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import { Cancel, CheckCircle, Edit } from '@mui/icons-material'
import { getDurationFromUtc } from '../../_helpers/dataHelper'


const FlightParamsCard = () => {

  const selectedTestId = useSelector(state => state.data.selectedTestId)
  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs()
  const taskHooks = useFirestore(TASK_COLLECTION)
  const tasks = taskHooks.getDocs()
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const test = useMemo(() => tests?.find(t => t.id === selectedTestId), [tests, selectedTestId])

  const [editMode, setEditMode] = useState(!test?.offBlocksUtc && !test?.takeOffUtc && !test?.landingUtc && !test?.onBlocksUtc && !readOnlyMode)
  const [offBlocksUtc, setOffBlocksUtc] = useState('')
  const [takeOffUtc, setTakeOffUtc] = useState('')
  const [landingUtc, setLandingUtc] = useState('')
  const [onBlocksUtc, setOnBlocksUtc] = useState('')
  const [cerHours, setCerHours] = useState('')
  const [landings, setLandings] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => {
    setOffBlocksUtc(test?.offBlocksUtc || '')
    setTakeOffUtc(test?.takeOffUtc || '')
    setLandingUtc(test?.landingUtc || '')
    setOnBlocksUtc(test?.onBlocksUtc || '')
    setCerHours(test?.cerHours || '')
    setLandings(test?.landings || '')
    setSubmitted(false)
  }, [test])

  const blockTime = useMemo(() => {
    if (offBlocksUtc && onBlocksUtc) {
      return getDurationFromUtc(offBlocksUtc, onBlocksUtc)
    }
  }, [offBlocksUtc, onBlocksUtc])

  const flightTime = useMemo(() => {
    if (takeOffUtc && landingUtc) {
      return getDurationFromUtc(takeOffUtc, landingUtc)
    }
  }, [takeOffUtc, landingUtc])

  const handleSubmit = () => testHooks.updateDoc(test.id, {
    offBlocksUtc,
    takeOffUtc,
    landingUtc,
    onBlocksUtc,
    landings,
    cerHours: Number(cerHours),
  })
    .then(() => setEditMode(false))
    .finally(() => setSubmitted(false))

  return tasks && (
    <Card>
      <CardHeader
        title='Parameters'
        action={editMode
          ? <>
            <IconButton onClick={() => setEditMode(false)} disabled={submitted} color='error'><Cancel /></IconButton>
            <IconButton onClick={handleSubmit} disabled={submitted} color='success'><CheckCircle /></IconButton>
          </>
          : <IconButton disabled={readOnlyMode} onClick={() => setEditMode(true)}><Edit /></IconButton>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='offBlocksUtc'
                label='Off Blocks'
                placeholder='1301'
                fullWidth
                value={offBlocksUtc || ''}
                onChange={e => setOffBlocksUtc(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Off Blocks</Typography>
                <Typography fontWeight='bold'>{offBlocksUtc}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='onBlocksUtc'
                label='On Blocks'
                placeholder='1304'
                fullWidth
                value={onBlocksUtc || ''}
                onChange={e => setOnBlocksUtc(e.target.value)}
              />
              : <>
                <Typography variant='caption'>On Blocks</Typography>
                <Typography fontWeight='bold'>{onBlocksUtc}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            <Typography>Block time</Typography>
            <Typography>{blockTime?.toFixed(2)}h</Typography>
          </Grid>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='takeOffUtc'
                label='Take off'
                placeholder='1302'
                fullWidth
                value={takeOffUtc || ''}
                onChange={e => setTakeOffUtc(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Take off</Typography>
                <Typography fontWeight='bold'>{takeOffUtc}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='landingUtc'
                label='Landing'
                placeholder='1303'
                fullWidth
                value={landingUtc || ''}
                onChange={e => setLandingUtc(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Landing</Typography>
                <Typography fontWeight='bold'>{landingUtc}</Typography>
              </>
            }
          </Grid>
          <Grid item xs={4}>
            <Typography>Flight time</Typography>
            <Typography>{flightTime?.toFixed(2)}h</Typography>
          </Grid>
          {tasks.some(t => t.slug === 'cer') && <>
            <Grid item xs={8}>
              {editMode
                ? <TextField
                  variant='standard'
                  margin='dense'
                  id='cerHours'
                  label='CER Time'
                  placeholder='1.02'
                  fullWidth
                  value={cerHours || ''}
                  onChange={e => setCerHours(e.target.value)}
                />
                : <>
                  <Typography variant='caption'>CER Time (hours)</Typography>
                  <Typography fontWeight='bold'>{cerHours}</Typography>
                </>
              }
            </Grid>
            <Grid item xs={4}>
              <Typography>CER time</Typography>
              <Typography>{Number(cerHours)?.toFixed(2)}h</Typography>
            </Grid>
          </>}
          <Grid item xs={12}>
            {editMode
              ? <TextField
                variant='standard'
                margin='dense'
                id='landings'
                label='Landings'
                placeholder='1'
                fullWidth
                type='number'
                value={landings || ''}
                onChange={e => setLandings(e.target.value)}
              />
              : <>
                <Typography variant='caption'>Landings</Typography>
                <Typography fontWeight='bold'>{landings}</Typography>
              </>
            }
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default FlightParamsCard
