import React, { useEffect, useState } from 'react'
import { TextField, Box, Typography, ToggleButtonGroup, ToggleButton, FormControl, InputLabel, Select, MenuItem, Button, IconButton, FormHelperText } from '@mui/material'
import { AIRCRAFT_COLLECTION, AIRCRAFT_TYPE_IR, AIRCRAFT_TYPE_IRE, AIRCRAFT_TYPE_IS, AIRCRAFT_TYPE_ISE, TANK_COLLECTION } from '../../_constants/globals'
import { omit, pickBy } from 'lodash'
import PropTypes from 'prop-types'
import ImageCropper from '../_library/ImageCropper'
import { getTypeLabel } from '../../_helpers/dataHelper'
import { Delete } from '@mui/icons-material'
import { INTEGRAL_ELEC_TANKS_STANDARD, INTEGRAL_TANKS_STANDARD } from '../../_constants/misc'
import { useFirestore } from '../../hooks/useFirestore'
import { useStorage } from '../../hooks/useStorage'


const AIRCRAFT_TYPES = [AIRCRAFT_TYPE_IS, AIRCRAFT_TYPE_IR, AIRCRAFT_TYPE_IRE, AIRCRAFT_TYPE_ISE]
const WriteAircraftForm = ({ formRef, setSubmitted, onComplete, aircraft }) => {

  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const tankHooks = useFirestore(TANK_COLLECTION)
  const dbTanks = tankHooks.getDocs()
  const storageHooks = useStorage(AIRCRAFT_COLLECTION)

  const [cover, setCover] = useState(null)
  const [type, setType] = useState(aircraft?.type)
  const [serial, setSerial] = useState(aircraft?.serial)
  const [registration, setRegistration] = useState(aircraft?.registration)
  const [description, setDescription] = useState(aircraft?.description?.replaceAll('<br>', '\n'))
  const [tanks, setTanks] = useState([])
  const [errors, setErrors] = useState({})

  useEffect(() => {
    tankHooks.listen({
      where: [['aircraftRef', '==', aircraftHooks.getDocRef(aircraft?.id)]],
      orderBy: [['order', 'asc']],
    })
    return () => tankHooks.unsubscribe()
  }, [aircraft?.id])
  
  useEffect(() => {
    if (dbTanks?.length) setTanks(dbTanks)
    else if (type === 'IS' || type === 'IR') setTanks(INTEGRAL_TANKS_STANDARD)
    else setTanks(INTEGRAL_ELEC_TANKS_STANDARD)
  }, [type, dbTanks])
  
  const handleSubmit = async e => {
    e.preventDefault()
    if (!type)
      setErrors(prev => ({ ...prev, type: 'il faut sélectionner un type' }))
    if (type) {
      setSubmitted(true)
      try {
        const coverUrl = cover && await storageHooks.uploadFileAction(cover)
        const { id } = await aircraftHooks.setDoc(aircraft?.id, pickBy({
          coverUrl,
          type,
          serial,
          registration,
          description: description?.replaceAll('\n', '<br>'),
          bookable: aircraft?.bookable || true,
        }))
        await aircraftHooks.batchSetAction(TANK_COLLECTION, tanks.map(t => ({ ...t, aircraftRef: aircraftHooks.getDocRef(id) })))
        onComplete(id)
      }
      finally {setSubmitted(false)}
    }
  }
  
  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <ImageCropper onComplete={setCover} imageUrl={aircraft?.coverUrl} />
      <Box sx={{ px: 1 }}>
        <ToggleButtonGroup
          value={type}
          exclusive
          onChange={(_, value) => {
            setErrors(prev => omit(prev, 'type'))
            setType(value)
          }}
          ref={el => {
            if (errors.type && el) scroll.current = el
          }}
          sx={{ mt: 1 }}
        >
          {AIRCRAFT_TYPES.map(t =>
            <ToggleButton key={t} value={t} sx={{
              color: 'primary.main',
              borderColor: errors.type ? 'error.main' : 'primary.main',
              '&:hover': {
                color: 'white',
                backgroundColor: 'primary.main',
              },
              '&.Mui-selected': {
                color: 'white',
                backgroundColor: 'primary.main',
                '&:hover': {
                  color: 'white',
                  backgroundColor: 'primary.main',
                  cursor: 'default',
                },
              },
            }}>{getTypeLabel(t)}</ToggleButton>,
          )}
        </ToggleButtonGroup>
        {errors.type && <Box sx={{ color: 'error.main', fontSize: '0.75rem', lineHeight: 1.66 }}>{errors.type}</Box>}
        <TextField
          variant='outlined'
          margin='dense'
          label='Numéro de série'
          placeholder='MSNOO5'
          fullWidth
          value={serial || ''}
          onChange={e => setSerial(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          variant='outlined'
          margin='dense'
          label='Immatriculation'
          placeholder='F-WIAS'
          fullWidth
          value={registration || ''}
          onChange={e => setRegistration(e.target.value)}
          sx={{ mt: 2 }}
        />
        <TextField
          variant='outlined'
          margin='dense'
          label='Description'
          fullWidth
          value={description || ''}
          multiline
          onChange={e => setDescription(e.target.value)}
          rows={3}
          sx={{ mt: 2 }}
        />
      </Box>
      {type && <Box id='tanks' sx={{ px: 1 }}>
        <Typography>Liquid tanks</Typography>
        {tanks?.map((tank, index) => <Box key={index} my={1}>
          <FormControl error={!!errors.type} sx={{ mr: 2, width: 120 }}>
            <InputLabel id='select-tank-type'>Type</InputLabel>
            <Select
              labelId='select-tank-type'
              id='select-tank'
              variant='standard'
              size='small'
              value={tank.type}
              onChange={event => setTanks(prev => prev.map((t, i) => i === index ? { ...t, type: event.target.value } : t))}
            >
              <MenuItem value='fuel'>Fuel</MenuItem>
              <MenuItem value='lubricant'>Lubricant</MenuItem>
              <MenuItem value='water'>Water</MenuItem>
            </Select>
            {errors.type && <FormHelperText>{errors.type}</FormHelperText>}
          </FormControl>
          <TextField
            margin='dense'
            label='Title'
            value={tank.title || ''}
            onChange={event => setTanks(prev => prev.map((t, i) => i === index ? { ...t, title: event.target.value } : t))}
            sx={{ display: 'inline-block', verticalAlign: 'baseline' }}
          />
          <TextField
            margin='dense'
            label='Capacity'
            type='number'
            value={tank.capacity || 0}
            onChange={event => setTanks(prev => prev.map((t, i) => i === index ? { ...t, capacity: Number(event.target.value) } : t))}
            sx={{ display: 'inline-block', verticalAlign: 'baseline', width: 80 }}
          />
          <IconButton
            value='delete'
            title='Remove tank'
            onClick={() => setTanks(prev => prev.toSpliced(index, 1))}
          >
            <Delete />
          </IconButton>
        </Box>)}
        <Button
          onClick={() => setTanks(prev => [...prev, { id: tanks.length, type: 'fuel', capacity: 100, quantity: 0, unit: 'L' }])}
        >Add a tank</Button>
      </Box>}
    </form>
  )
}

WriteAircraftForm.propTypes = {
  formRef: PropTypes.object,
  setSubmitted: PropTypes.func,
  onComplete: PropTypes.func,
  aircraft: PropTypes.object,
}

export default WriteAircraftForm
