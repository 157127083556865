import React from 'react'
import ProfilePanel from './ProfilePanel'
import AirportOverviewPanel from '../airports/AirportOverviewPanel'
import HistoryTable from './HistoryTable'
import Page from '../_library/Page'


const SettingsPage = () => {

  const tabs = [
    {
      key: 'profile',
      label: 'Profile',
      route: 'profile',
      content: <ProfilePanel />,
    },
    {
      key: 'airports',
      label: 'Airports',
      route: 'airports',
      content: <AirportOverviewPanel />,
    },
    {
      key: 'history',
      label: 'History',
      route: 'history',
      content: <HistoryTable />,
    },
  ]

  return <Page tabs={tabs} withTopBarAndMenu />
}

export default SettingsPage
