import React, { useEffect, useMemo, useState } from 'react'
import { Card, CardContent, Typography, Grid, Box, FormControl, Switch, CardMedia, TextField, CardActions } from '@mui/material'
import { useSelector } from 'react-redux'
import InlineEditor from '../_library/InlineEditor'
import { AIRCRAFT_COLLECTION, TANK_COLLECTION, TEST_COLLECTION } from '../../_constants/globals'
import LiquidGraph from '../_library/LiquidGraph'
import { useFirestore } from '../../hooks/useFirestore'
import { getTypeLabel } from '../../_helpers/dataHelper'
import DeleteButton from '../_library/DeleteButton'
import { AIRCRAFTS } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import HILTable from './HILTable'
import { format } from 'date-fns'


const AircraftOverviewPanel = () => {
  
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const tankHooks = useFirestore(TANK_COLLECTION)
  const testHooks = useFirestore(TEST_COLLECTION)
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)
  const navigate = useNavigate()
  const aircrafts = aircraftHooks.getDocs()
  const tests = testHooks.getDocs()
  const dbTanks = tankHooks.getDocs()
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const aircraft = useMemo(() => aircrafts?.find(a => a.id === selectedAircraftId), [aircraftHooks, selectedAircraftId])
  
  useEffect(() => {
    if (aircraft) {
      tankHooks.listen({
        where: [['aircraftRef', '==', aircraftHooks.getDocRef(aircraft.id)]],
        orderBy: [['order', 'asc']],
      })
      testHooks.listen({
        where: [['aircraftRef', '==', aircraftHooks.getDocRef(aircraft.id)]],
        orderBy: [
          ['startDate', 'asc'],
          ['label', 'asc'],
        ],
      })
      return () => {
        tankHooks.unsubscribe()
        testHooks.unsubscribe()
      }
    }
  }, [aircraft?.id])
  
  const [submitted, setSubmitted] = useState(false)
  
  const handleSwitch = event => {
    setSubmitted(true)
    aircraftHooks.updateDoc(aircraft.id, {
      [event.target.name]: event.target.checked,
    }).then(() => setSubmitted(false))
  }
  
  const updateAircraftAction = data => aircraftHooks.updateDoc(aircraft.id, data)
  
  return <Box sx={{ maxWidth: '900px', p: 2, mx: 'auto' }}>
    {aircraft
      ? <>
        <Card id='general' variant='outlined' sx={{ minWidth: '275px', mb: 2 }}>
          <CardMedia component='img' height='194' image={aircraft.coverUrl} alt={aircraft.serial} />
          <CardContent>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor title='Aircraft type' value={getTypeLabel(aircraft.type)} disabled />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor title='Serial number' value={aircraft.serial} handleSubmit={serial => updateAircraftAction({ serial })} disabled={readOnlyMode} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor title='Immatriculation' value={aircraft.registration} handleSubmit={registration => updateAircraftAction({ registration })} disabled={readOnlyMode} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor
                title='Commentaire'
                value={aircraft.comments}
                handleSubmit={comments => updateAircraftAction({ comments })}
                multiline
                rows={4}
                disabled={readOnlyMode}
              />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography color='rgba(0,0,0,.54)' display='inline-block' lineHeight='20px' width='200px'>
                Bookable
              </Typography>
              <FormControl variant='outlined'>
                <Switch
                  checked={aircraft.bookable || false}
                  onChange={handleSwitch}
                  name='bookable'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={submitted || readOnlyMode}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <Typography color='rgba(0,0,0,.54)' display='inline-block' lineHeight='20px' width='200px'>
                Archived
              </Typography>
              <FormControl variant='outlined'>
                <Switch
                  checked={aircraft.archived || false}
                  onChange={handleSwitch}
                  name='archived'
                  inputProps={{ 'aria-label': 'secondary checkbox' }}
                  disabled={submitted || readOnlyMode}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor title='Drive folder ID' value={aircraft.driveFolderId} handleSubmit={driveFolderId => updateAircraftAction({ driveFolderId })} disabled={readOnlyMode} />
            </Grid>
            <Grid item xs={12} sx={{ mb: 1 }}>
              <InlineEditor title='Aicraft particularities link' value={aircraft.particularitiesUrl} handleSubmit={particularitiesUrl => updateAircraftAction({ particularitiesUrl })} disabled={readOnlyMode} />
            </Grid>
          </CardContent>
        </Card>
        
        {dbTanks && <>
          <Typography variant='body2' color='textSecondary' mb={1}>Consommables</Typography>
          <Card id='liquids' variant='outlined' sx={{ minWidth: '275px', mb: 2 }}>
            <CardContent>
              <LiquidGraph tanks={dbTanks} field='quantity' connected disabled={readOnlyMode} />
            </CardContent>
          </Card>
        </>}
        
        <Typography variant='body2' color='textSecondary' mb={1}>HIL</Typography>
        <Grid item md={12}>
          <HILTable sx={{ mb: 2 }} />
        </Grid>
        
        <Typography variant='body2' color='textSecondary' mb={1} mt={2}>Stats</Typography>
        <Card id='stats' variant='outlined' sx={{ minWidth: '275px', mb: 2 }}>
          <CardContent>
            <Typography>Created at {aircraft._createdAtTime && format(aircraft._createdAtTime, 'dd/MM/yyyy')}</Typography>
            <Typography>Ground tests: {tests?.filter(t => t.testType === 'ground').map(t => format(t.startDate, 'dd/MM/yyyy')).join(', ')}</Typography>
            <Typography>Engine runs: {tests?.filter(t => t.testType === 'run').map(t => format(t.startDate, 'dd/MM/yyyy')).join(', ')}</Typography>
            <Typography>Taxis: {tests?.filter(t => t.testType === 'taxi').map(t => format(t.startDate, 'dd/MM/yyyy')).join(', ')}</Typography>
            <Typography>Flights: {tests?.filter(t => t.testType === 'flight').map(t => format(t.startDate, 'dd/MM/yyyy')).join(', ')}</Typography>
          </CardContent>
        </Card>
        
        <Typography variant='body2' color='textSecondary' mb={1} mt={2}>Archiving</Typography>
        <Card id='archive' variant='outlined' sx={{ minWidth: '275px', mb: 2 }}>
          <CardContent>
            <TextField label='comments' multiline rows={4} fullWidth disabled />
          </CardContent>
          <CardActions>
            <DeleteButton fullWidth disabled={readOnlyMode} deleteAction={() => aircraftHooks.deleteDoc(aircraft.id).then(() => navigate(AIRCRAFTS))} text='Delete aircraft' />
          </CardActions>
        </Card>
      </>
      : <div style={{ width: 'fit-content', margin: 'auto' }}>Pas d&apos;avion sélectionné</div>}
  </Box>
}

export default AircraftOverviewPanel
