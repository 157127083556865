import React, { useEffect, useMemo } from 'react'
import AircraftOverviewPanel from './AircraftOverviewPanel'
import AllAircraftsPanel from './AllAircraftsPanel'
import { useDispatch } from 'react-redux'
import { useMatch } from 'react-router-dom'
import { AIRCRAFTS } from '../../_constants/routes'
import { selectAircraftIdAction } from '../../store/actions/dataActions'
import { getTypeLabel } from '../../_helpers/dataHelper'
import TestTable from '../tests/TestTable'
import TlbPanel from './TlbPanel'
import Page from '../_library/Page'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import OperationPanel from '../operations/OperationPanel'


const AircraftPage = () => {
  
  const match = useMatch(AIRCRAFTS + '/:id/:tab')
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const dbAircrafts = aircraftHooks.getDocs()
  const dispatch = useDispatch()

  const aircraft = useMemo(() => match?.params?.id && dbAircrafts.find(a => a.id === match?.params.id), [[match?.params?.id]])
  useEffect(() => {match?.params?.id && dispatch(selectAircraftIdAction(match.params.id))}, [match?.params?.id])
  
  const tabs = [
    {
      key: 'all',
      label: 'All aircrafts',
      route: 'all',
      content: <AllAircraftsPanel />,
    },
    aircraft && {
      key: aircraft.id + '/overview',
      title: `${getTypeLabel(aircraft?.type)} - ${aircraft?.serial}` || '',
      label: 'Overview',
      route: '/:id/overview',
      content: <AircraftOverviewPanel />,
    },
    aircraft && {
      key: aircraft.id + '/flights',
      title: `${getTypeLabel(aircraft?.type)} - ${aircraft?.serial}` || '',
      label: 'Flight logbook',
      route: '/:id/flights',
      content: <TestTable aircraftId={aircraft?.id} />,
    },
    aircraft && {
      key: aircraft.id + '/tlb',
      title: `${getTypeLabel(aircraft?.type)} - ${aircraft?.serial}` || '',
      label: 'Technical logbook',
      route: '/:id/tlb',
      content: <TlbPanel />,
    },
    aircraft && {
      key: aircraft.id + '/operations',
      label: 'Scheduled operations',
      route: '/:id/operations',
      content: <OperationPanel />,
    },
  ]

  if (!match?.params?.id || aircraft) return <Page tabs={tabs} withTopBarAndMenu />
  else return <Loading />
}

export default AircraftPage
