import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, Link, MenuItem,
  Select, ToggleButton, ToggleButtonGroup } from '@mui/material'
import { ROLE_CAPTAIN, ROLE_ENGINEER, ROLE_MECHANICS, ROLE_PILOT, ROLE_VISITOR, TEST_COLLECTION,
  USER_COLLECTION } from '../../_constants/globals'
import { pick, pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'
import { isNil } from 'lodash/lang'
import { Delete } from '@mui/icons-material'
import { capitalize, getRoleIcon } from '../../_helpers/dataHelper'
import { useSelector } from 'react-redux'
import { mergeCollections } from '../../_helpers/jsHelper'


const ROLES = [ROLE_CAPTAIN, ROLE_PILOT, ROLE_ENGINEER, ROLE_MECHANICS, ROLE_VISITOR]
const EditCrewDialog = ({ onClose }) => {

  const testHooks = useFirestore(TEST_COLLECTION)
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const tests = testHooks.getDocs()
  const selectedTestId = useSelector(state => state.data.selectedTestId)
  const adminMode = useSelector(state => state.data.adminMode)

  const test = useMemo(() => tests?.find(t => t.id === selectedTestId), [tests, selectedTestId]) || {}

  const [users, setUsers] = useState(null)
  const [submitted, setSubmitted] = useState(false)

  useEffect(() => { test && setUsers(mergeCollections(dbUsers.filter(u => test.userRefs?.some(ref => ref.id === u.id)), test.users, 'id')) }, [test, dbUsers])

  const availableUsers = useMemo(() => dbUsers?.filter(u => !users?.some(t => t.id === u.id)), [dbUsers, users])
  const orderedUsers = useMemo(() => availableUsers.filter(u => adminMode || (!!u.roles && u.roles.length && !u.archived)).sort(u => {
    if (u.roles?.includes(ROLE_CAPTAIN)) return -10
    else if (u.roles?.includes(ROLE_PILOT)) return 1
    else if (u.roles?.includes(ROLE_ENGINEER)) return 2
    else if (u.roles?.includes(ROLE_MECHANICS)) return 3
    else if (u.roles?.includes(ROLE_VISITOR)) return 4
    else return 5
  }), [availableUsers])

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    const obj = pickBy({
      userRefs: users.map(u => userHooks.getDocRef(u.id)),
      users: users.map(u => pick(u, ['email', 'firstname', 'lastname', 'photoUrl', 'id', 'role', 'roles', 'trigram'])),
    }, val => !isNil(val))
    const handler = data => test?.id ? testHooks.updateDoc(test.id, data) : testHooks.addDoc(data)
    return handler(obj)
      .then(onClose)
      .finally(() => setSubmitted(false))
  }

  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-item' component='form' onSubmit={handleSubmit}>
      <DialogTitle>Edit users</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Grid container id='users' item xs={12} spacing={3} mt={1}>
          <Grid item md={12} xs={12}>
            {availableUsers && users?.map((user, index) => <Box key={index} mb={2}>
              <FormControl sx={{ mr: 2 }}>
                <InputLabel id='select-user-label'>User</InputLabel>
                <Select
                  labelId='select-user-label'
                  id='select-user'
                  variant='standard'
                  value={user.id}
                  onChange={e => setUsers(prev => {prev[index] = dbUsers.find(t => t.id === e.target.value); return [...prev]} )}
                  size='small'
                  disabled={submitted}
                >
                  <MenuItem key={user.id} value={user.id}>{user.firstname}</MenuItem>
                  {orderedUsers.map(t => <MenuItem key={t.id} value={t.id}>{t.firstname}</MenuItem>)}
                </Select>
              </FormControl>
              <ToggleButtonGroup
                value={user.role}
                exclusive
                onChange={(_, role) => setUsers(prev => prev.map(t => t.id === user.id ? { ...t, role } : t))}
              >
                {ROLES.map(role =>
                  <ToggleButton key={role} value={role} title={capitalize(role)} color='primary' disabled={!user.roles?.includes(role) && role !== ROLE_VISITOR}>
                    {getRoleIcon(role)}
                  </ToggleButton>,
                )}
                <ToggleButton value='delete' title='Remove user' onClick={() => setUsers(prev => prev.filter(t => t.id !== user.id))}>
                  <Delete />
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>)}
            {orderedUsers?.length > 0 && <Link
              onClick={() => setUsers(prev => [...prev, pick(orderedUsers[0], ['id', 'firstname', 'lastname', 'email', 'photoUrl', 'roles', 'trigram'])])}
              sx={{ cursor: 'pointer', mt: 1 }}
            >Add a crew</Link>}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!submitted && <Button type='submit'>Submit</Button>}
        <Button onClick={onClose} color='primary'>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

EditCrewDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  testId: PropTypes.string,
}

export default EditCrewDialog
