import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
import {
  ROLE_CAPTAIN, ROLE_ENGINEER, ROLE_MECHANICS, ROLE_PILOT, ROLE_VISITOR, TASK_COLLECTION, USER_COLLECTION,
} from '../../_constants/globals'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'
import { isNil } from 'lodash/lang'
import { DatePicker } from '@mui/x-date-pickers'


const TaskEditDialog = ({ task, onClose }) => {
  
  const userHooks = useFirestore(USER_COLLECTION)
  const dbUsers = userHooks.getDocs()
  const taskHooks = useFirestore(TASK_COLLECTION)
  
  const [user, setUser] = useState(null)
  const [completedTime, setCompletedTime] = useState(task.completedTime || new Date())
  const [submitted, setSubmitted] = useState(false)
  
  const orderedUsers = useMemo(() => dbUsers.filter(u => !!u.roles && u.roles.length && !u.archived).sort(u => {
    if (u.roles?.includes(ROLE_CAPTAIN)) return -10
    else if (u.roles?.includes(ROLE_PILOT)) return 1
    else if (u.roles?.includes(ROLE_ENGINEER)) return 2
    else if (u.roles?.includes(ROLE_MECHANICS)) return 3
    else if (u.roles?.includes(ROLE_VISITOR)) return 4
    else return 5
  }), [dbUsers])
  
  useEffect(() => {
    if (dbUsers) setUser(dbUsers.find(u => u.id === task.userRef?.id))
  }, [dbUsers])
  
  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    return taskHooks.updateDoc(task.id, pickBy({
      userRef: userHooks.getDocRef(user.id),
      completedTime,
    }, val => !isNil(val)))
      .then(onClose)
      .finally(() => setSubmitted(false))
  }
  
  return (
    <Dialog open onClose={onClose} aria-labelledby='form-dialog-item' component='form' onSubmit={handleSubmit}>
      <DialogTitle>Edit task</DialogTitle>
      <DialogContent sx={{ minWidth: 400 }}>
        <Grid container id='users' item xs={12} spacing={3} mt={1}>
          <Grid item md={12} xs={12}>
            {orderedUsers && <FormControl sx={{ mr: 2 }}>
              <InputLabel id='select-user-label'>User</InputLabel>
              <Select
                labelId='select-user-label'
                id='select-user'
                variant='standard'
                value={user?.id || ''}
                onChange={e => setUser(dbUsers.find(u => u.id === e.target.value))}
                size='small'
                disabled={submitted}
                sx={{ minWidth: 100 }}
              >
                {orderedUsers.map(t => <MenuItem key={t.id} value={t.id}>{t.firstname}</MenuItem>)}
              </Select>
            </FormControl>}
            <DatePicker
              disableToolbar
              variant='inline'
              inputFormat='dd-MM-yyyy'
              margin='dense'
              id='startDate-date'
              label='Date of test'
              value={completedTime}
              onChange={setCompletedTime}
              style={{ marginTop: 30 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={submitted || !user}>Submit</Button>
        <Button onClick={onClose} color='primary'>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

TaskEditDialog.propTypes = {
  task: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default TaskEditDialog
