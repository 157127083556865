import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box, ClickAwayListener, Input, Slider } from '@mui/material'
import { LocalGasStation, OilBarrel, Water } from '@mui/icons-material'
import { useFirestore } from '../../hooks/useFirestore'
import { TANK_COLLECTION } from '../../_constants/globals'


const TankRow = ({ tank, field, onChange, disabled, connected }) => {
  const tankHooks = useFirestore(TANK_COLLECTION)

  const [value, setValue] = useState(tank[field])
  const [submitted, setSubmitted] = useState(false)

  const getTypeIcon = type => {
    switch (type) {
      case 'fuel': return <LocalGasStation />
      case 'lubricant': return <OilBarrel />
      case 'water': return <Water />
    }
  }

  const handleSubmit = async () => {
    if (value === tank[field]) return
    setSubmitted(true)
    if (connected) tankHooks.setDoc(tank.id, { [field]: value })
    else if (onChange instanceof Function) {
      await onChange(value)
    }
    setSubmitted(false)
  }

  return (
    <tr>
      <td>{getTypeIcon(tank.type)}</td>
      <td>{tank.title}</td>
      <td>
        <Slider
          value={value || 0}
          onChange={(_, value) => setValue(Number(value))}
          onChangeCommitted={handleSubmit}
          aria-labelledby='input-slider'
          step={1}
          max={tank.capacity}
          disabled={disabled || submitted}
          sx={{ minWidth: 50 }}
        />
      </td>
      <td>
        <ClickAwayListener onClickAway={handleSubmit}>
          <Input
            value={value || 0}
            size='small'
            onChange={e => setValue(Number(e.target.value))}
            disabled={disabled || submitted}
            inputProps={{
              step: 1,
              min: 0,
              max: tank.capacity,
              type: 'number',
              'aria-labelledby': 'input-slider',
              sx: { width: 50, textAlign: 'right' },
            }}
          />
        </ClickAwayListener>
      </td>
      <td>{tank.unit}</td>
    </tr>
  )
}

TankRow.propTypes = {
  tank: PropTypes.object.isRequired,
  index: PropTypes.number,
  field: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  connected: PropTypes.bool,
}

export const LiquidGraph = ({ tanks, setTanks, field, disabled, connected }) => (
  <Box component='table' sx={{ width: '-webkit-fill-available' }}>
    <tbody>
      {tanks?.map((tank, index) => <TankRow
        key={tank.id}
        tank={tank}
        index={index}
        field={field}
        disabled={disabled}
        connected={connected}
        onChange={setTanks instanceof Function
          ? setTanks(tank.id || index)
          : null
        }
      />)}
    </tbody>
  </Box>
)

LiquidGraph.propTypes = {
  tanks: PropTypes.array.isRequired,
  setTanks: PropTypes.any,
  field: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  connected: PropTypes.bool,
}

export default LiquidGraph
