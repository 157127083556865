import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Avatar as MuiAvatar, CircularProgress, Tooltip } from '@mui/material'
import { useSelector } from 'react-redux'
import { getGravatar } from '../../_helpers/dataHelper'


const Avatar = ({ user, sx, variant, tooltip, ...props }) => {
  
  const profile = useSelector(state => state.profile.data)
  
  if (!profile && !user) return <CircularProgress />
  const { email, photoUrl, firstname, lastname } = user || {}
  
  const { src, content } = useMemo(() => {
    if (variant === 'anonymous') return { src: null, content: null }
    else if (photoUrl) return { src: photoUrl, content: null }
    else if (email) return { src: getGravatar(email), content: null }
    else if (firstname || lastname) return { src: null, content: firstname?.charAt(0).toUpperCase() || '' + lastname?.charAt(0).toUpperCase() || '' }
    else return { src: null, content: '?' }
  }, [variant, photoUrl, email, firstname, lastname])
  
  return (
    <Tooltip title={tooltip || firstname} enterDelay={500}>
      <MuiAvatar
        alt={email}
        src={src}
        {...props}
        sx={{
          width: '35px',
          height: '35px',
          ...sx,
        }}
      >{content}</MuiAvatar>
    </Tooltip>
  )
}

Avatar.propTypes = {
  user: PropTypes.object,
  sx: PropTypes.object,
  variant: PropTypes.string,
  tooltip: PropTypes.string,
}

export default Avatar
