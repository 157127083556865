import React, { useEffect, useMemo, useState } from 'react'
import { Autocomplete, Avatar, Button, Card, CardActions, CardContent, CardHeader, Fab, Grid, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { Add, Edit } from '@mui/icons-material'
import WriteAirportDialog from './WriteAirportDialog'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import MarkerIcon from '../../images/airportMarker.svg'
import DeleteButton from '../_library/DeleteButton'
import { AIRPORT_COLLECTION } from '../../_constants/globals'
import { FRANCAZAL_LAT_LNG } from '../../_constants/misc'
import { useFirestore } from '../../hooks/useFirestore'


export const AirportOverviewPanel = () => {
  
  const airports = useSelector(state => state.firestore.airports)
  const airportHooks = useFirestore(AIRPORT_COLLECTION)

  const [map, setMap] = useState(null)
  const [openAirportDialog, setOpenAirportDialog] = useState(false)
  const [selectedAirportId, selectAirportId] = useState(null)
  
  const { isLoaded: mapLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  })
  
  const selectedAirport = useMemo(() => airports?.find(a => a.id === selectedAirportId), [airports, selectedAirportId])
  const coordinates = useMemo(() => airports?.map(a => ({ lat: a.latitude, lng: a.longitude })), [airports])
  
  useEffect(() => {
    if (selectedAirport && selectedAirport.ICAO !== 'LFBF') {
      const bounds = new window.google.maps.LatLngBounds()
      bounds.extend(FRANCAZAL_LAT_LNG)
      bounds.extend({ lat: selectedAirport.latitude, lng: selectedAirport.longitude })
      map.fitBounds(bounds)
    }
  }, [selectedAirport])
  
  return <Grid container spacing={1} sx={{ minHeight: '426px', m: '0 auto', maxWidth: '936px' }}>
    <Grid item md={6} sx={{ zIndex: 1, ml: { xs: 1, md: 0 } }}>
      {airports && <Autocomplete
        options={airports}
        getOptionLabel={option => option.name || ''}
        isOptionEqualToValue={(option, value) => option.id === value?.id}
        renderInput={params => <TextField variant='standard' {...params} label='Search' />}
        value={selectedAirport || null}
        onChange={(_, newValue) => selectAirportId(newValue?.id)}
      />}
      {selectedAirport && <Card sx={{ my: 2 }}>
        <CardHeader
          avatar={<Avatar alt='airport' src={MarkerIcon} />}
          title={selectedAirport.name}
          subheader={selectedAirport.ICAO}
          sx={{ bgcolor: 'grey.200' }}
        />
        <CardContent>
          <Typography variant='h4'>Location</Typography>
          <Typography>City : {selectedAirport.city}</Typography>
          <Typography>Country : {selectedAirport.country}</Typography>
          <Typography>Latitude : {selectedAirport.latitude}</Typography>
          <Typography>Longitude : {selectedAirport.longitude}</Typography>
          <Typography>Altitude : {selectedAirport.altitude}</Typography>
        </CardContent>
        <CardActions sx={{ float: 'right' }}>
          <Button onClick={() => setOpenAirportDialog(true)}>
            <Edit />
          </Button>
          <DeleteButton
            deleteAction={() => airportHooks.deleteDoc(selectedAirportId).then(() => selectAirportId(null))}
            sx={{ color: 'error.main' }}
          />
        </CardActions>
      </Card>}
    </Grid>
    <Grid item md={6} sx={{ zIndex: 1, ml: { xs: 1, md: 0 } }}>
      {mapLoaded && coordinates && <GoogleMap
        mapContainerStyle={{ width: '100%', aspectRatio: 1 }}
        options={{
          streetViewControl: false,
          zoomControl: true,
          mapTypeControl: true,
        }}
        onLoad={map => {
          setMap(map)
          const bounds = new window.google.maps.LatLngBounds()
          coordinates.map(coord => bounds.extend(coord))
          map.fitBounds(bounds)
        }}
        onClick={e => setOpenAirportDialog({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })}
      >
        {selectedAirport
          ? <>
            <Marker
              position={FRANCAZAL_LAT_LNG}
              icon={{
                path: 'M12 8c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm8.94 3c-.46-4.17-3.77-7.48-7.94-7.94V1h-2v2.06C6.83 3.52 3.52 6.83 3.06 11H1v2h2.06c.46 4.17 3.77 7.48 7.94 7.94V23h2v-2.06c4.17-.46 7.48-3.77 7.94-7.94H23v-2h-2.06zM12 19c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z',
                anchor: { x: 15, y: 15 },
                fillOpacity: 1,
              }}
              onClick={() => selectAirportId(null)}
            />
            {selectedAirport.ICAO !== 'LFBF' && <Marker
              position={{ lat: selectedAirport.latitude, lng: selectedAirport.longitude }}
              icon={{ url: MarkerIcon, scaledSize: { width: 30, height: 30 }, anchor: { x: 15, y: 15 } }}
              onClick={() => selectAirportId(null)}
            />}
          </>
          : airports?.map(airport => <Marker
            key={airport.id}
            position={{ lat: airport.latitude, lng: airport.longitude }}
            icon={{ url: MarkerIcon, scaledSize: { width: 30, height: 30 }, anchor: { x: 15, y: 15 } }}
            onClick={() => selectAirportId(airport.id)}
          />)
        }
      </GoogleMap>}
    </Grid>
    <Fab
      color='secondary'
      aria-label='add'
      onClick={() => {
        selectAirportId(null)
        setOpenAirportDialog(true)
      }}
      sx={{
        position: 'fixed',
        right: theme => theme.spacing(3),
        bottom: theme => theme.spacing(3),
      }}
    >
      <Add />
    </Fab>
    {openAirportDialog && <WriteAirportDialog
      open
      onClose={() => setOpenAirportDialog(false)}
      id={selectedAirport?.id}
      airport={typeof openAirportDialog === 'object' ? openAirportDialog : undefined}
    />}
  </Grid>
}

export default AirportOverviewPanel
