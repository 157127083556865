import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Card, CardContent, CardHeader, Divider, Grid, IconButton, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import Avatar from '../_library/Avatar'
import { ROLE_CAPTAIN, ROLE_ENGINEER, ROLE_MECHANICS, ROLE_PILOT, ROLE_VISITOR, TEST_COLLECTION } from '../../_constants/globals'
import { useFirestore } from '../../hooks/useFirestore'
import { Edit } from '@mui/icons-material'
import EditCrewDialog from './EditCrewDialog'


const UserCard = ({ sx }) => {

  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs()
  const selectedTestId = useSelector(state => state.data.selectedTestId)
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [openEdit, setOpenEdit] = useState(false)

  const { users } = useMemo(() => tests?.find(t => t.id === selectedTestId), [tests, selectedTestId]) || {}
  
  const crew = useMemo(() => users?.filter(u => [ROLE_CAPTAIN, ROLE_PILOT, ROLE_ENGINEER].includes(u.role)), [users])
  const support = useMemo(() => users?.filter(u => [ROLE_MECHANICS].includes(u.role)), [users])
  const visitors = useMemo(() => users?.filter(u => [ROLE_VISITOR].includes(u.role)), [users])
  const orderedUsers = useMemo(() => users && [
    ...users?.filter(u => u.role === ROLE_CAPTAIN),
    ...users?.filter(u => u.role === ROLE_PILOT),
    ...users?.filter(u => u.role === ROLE_ENGINEER),
    ...users?.filter(u => u.role === ROLE_MECHANICS),
    ...users?.filter(u => u.role === ROLE_VISITOR),
  ], [users])

  return (
    <Card sx={sx}>
      <CardHeader
        title='Team'
        action={<IconButton disabled={readOnlyMode} onClick={() => setOpenEdit(true)}><Edit /></IconButton>}
      />
      <CardContent>
        <Grid container>
          <Grid item xs={4}>
            <Typography component='div' fontWeight='bold' textAlign='center'>{crew?.length || 0}</Typography>
            <Typography component='div' variant='caption' textAlign='center'>CREW</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component='div' fontWeight='bold' textAlign='center'>{support?.length || 0}</Typography>
            <Typography component='div' variant='caption' textAlign='center'>SUPPORT</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography component='div' fontWeight='bold' textAlign='center'>{visitors?.length || 0}</Typography>
            <Typography component='div' variant='caption' textAlign='center'>GUESTS</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ my: 2 }} />
        {orderedUsers?.map(user =>
          <Button fullWidth key={user.email} sx={{ textAlign: 'left', display: 'block', textTransform: 'none', color: 'initial' }}>
            <Badge color='info' variant='dot' sx={{
              float: 'left',
              mr: 1,
              mt: .5,
              '& .MuiBadge-badge': { right: 8, top: 30, height: 16, width: 16, borderRadius: 8, border: 'solid white 2px' },
            }}>
              <Avatar user={user} />
            </Badge>
            <Typography>{user.firstname}</Typography>
            <Typography variant='caption' sx={{ color: 'grey.500' }}>{user.role}</Typography>
          </Button>,
        )}
      </CardContent>
      {openEdit && <EditCrewDialog onClose={() => setOpenEdit(false)} />}
    </Card>
  )
}

UserCard.propTypes = {
  sx: PropTypes.object,
}

export default UserCard
