/* eslint-disable react/display-name */
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { Box, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import EditUserDialog from './EditUserDialog'
import { pick } from 'lodash'
import Avatar from '../_library/Avatar'
import { Archive } from '@mui/icons-material'


const STORAGE_KEY = 'memberTableState'
const UserTable = () => {

  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const users = useSelector(state => state.firestore.users)

  const [selectedUserIndex, selectUserIndex] = useState(null)
  const [displayArchived, setDisplayArchived] = useState(false)

  const filteredData = useMemo(() => users?.filter(u => displayArchived || !u.archived ), [displayArchived, users])

  const selectedUser = useMemo(() => filteredData && selectedUserIndex !== null && filteredData[selectedUserIndex], [filteredData, selectedUserIndex])

  const data = useMemo(() =>
    filteredData.map(({ photoUrl, trigram, firstname, lastname, email, phone }) =>
      [{ photoUrl, email }, trigram, firstname, lastname, email, phone],
    ), [displayArchived, filteredData])

  const localState = window.localStorage.getItem(STORAGE_KEY) &&
    JSON.parse(window.localStorage.getItem(STORAGE_KEY))

  if (!users) return <Loading />
  else return (
    <Box sx={{ mt: 3, mx: 'auto', maxWidth: '1200px' }}>
      <MUIDataTable
        title='Member List'
        data={data}
        columns={[
          { name: '', options: { filter: false, sort: false, searchable: false, customBodyRender: user => <Avatar user={user} /> } },
          { name: 'Trigram', options: { sortThirdClickReset: true } },
          { name: 'Prénom', options: { sortThirdClickReset: true } },
          { name: 'Nom', options: { sortThirdClickReset: true } },
          {
            name: 'Email',
            options: {
              display: isUpMd,
              sortThirdClickReset: true,
            },
          },
          {
            name: 'Téléphone',
            options: {
              customBodyRender: data => <a href={'tel:' + data}>{data}</a>,
              sortThirdClickReset: true,
            },
          },
        ]}
        options={{
          filterType: 'textField',
          selectableRows: 'none',
          responsive: 'standard',
          onRowClick: (row, { dataIndex }) => selectUserIndex(dataIndex),
          rowHover: true,
          onTableChange: (action, tableState) => {
            if (action === 'propsUpdate') return
            window.localStorage.setItem(STORAGE_KEY, JSON.stringify(pick(tableState, ['columns', 'filterList', 'sortOrder', 'rowsPerPage'])))
          },
          sortOrder: localState?.sortOrder || {},
          rowsPerPage: localState?.rowsPerPage || 10,
          jumpToPage: true,
          rowsPerPageOptions: [10, 50, 100],
          customToolbar: () => (<>
            <Tooltip title='Show / Hide archived users'>
              <IconButton color={displayArchived ? 'primary' : 'grey.500'} onClick={() => setDisplayArchived(prev => !prev)}>
                <Archive />
              </IconButton>
            </Tooltip>
          </>),
        }}
      />
      {selectedUser && <EditUserDialog user={selectedUser} onClose={() => selectUserIndex(null)} />}
    </Box>
  )
}

export default UserTable
