/* eslint-disable react/jsx-filename-extension */
import md5 from 'md5'
import { AIRCRAFT_TYPE_IR, AIRCRAFT_TYPE_IRE, AIRCRAFT_TYPE_IS, AIRCRAFT_TYPE_ISE,
  ROLE_CAPTAIN, ROLE_ENGINEER, ROLE_MECHANICS, ROLE_PILOT, ROLE_VISITOR } from '../_constants/globals'
import { Engineering, EngineeringOutlined, Hail, HailOutlined, Handyman, HandymanOutlined, SportsMotorsports,
  SportsMotorsportsOutlined, Star, StarOutline } from '@mui/icons-material'
import { differenceInMinutes, format } from 'date-fns'


/**
 * get avatar from gravatar platform
 *
 * @param email
 * @returns {string}
 */
export const getGravatar = email => {
  const hash = email => md5(email || 'exemple@mail.com', { encoding: 'binary' })
  return `${window.location.protocol}//gravatar.com/avatar/${hash(email)}?d=identicon`
}

export const getRoleIcon = (role, checked = true) => {
  switch (role) {
    case ROLE_CAPTAIN: return checked ? <Star /> : <StarOutline /> // eslint-disable-line react/react-in-jsx-scope
    case ROLE_PILOT: return checked ? <SportsMotorsports /> : <SportsMotorsportsOutlined /> // eslint-disable-line react/react-in-jsx-scope
    case ROLE_ENGINEER: return checked ? <Engineering /> : <EngineeringOutlined /> // eslint-disable-line react/react-in-jsx-scope
    case ROLE_MECHANICS: return checked ? <Handyman /> : <HandymanOutlined /> // eslint-disable-line react/react-in-jsx-scope
    case ROLE_VISITOR: return checked ? <Hail /> : <HailOutlined /> // eslint-disable-line react/react-in-jsx-scope
  }
}

export const capitalize = str => str.charAt(0).toUpperCase() + str.slice(1)

export const getTypeLabel = type => {
  switch (type) {
    case AIRCRAFT_TYPE_IS: return 'Integral S'
    case AIRCRAFT_TYPE_IR: return 'Integral R'
    case AIRCRAFT_TYPE_ISE: return 'Integral SE'
    case AIRCRAFT_TYPE_IRE: return 'Integral RE'
  }
}

export const getDurationFromUtc = (from, to) => {
  const fromTime = new Date(0, 0, 0, from.slice(0, 2), from.slice(-2))
  const toTime = new Date(0, 0, 0, to.slice(0, 2), to.slice(-2))
  return differenceInMinutes(toTime, fromTime) / 60
}

export const getRecurrenceTip = (trigger, deadlineDate, limit) => {
  if (trigger === 'date' && deadlineDate instanceof Date) return `On ${format(deadlineDate, 'dd/MM/yyyy')}`
  if (trigger === 'yearly' && deadlineDate instanceof Date) return `Every year on ${format(deadlineDate, 'dd/MM')}`
  if (trigger === 'monthly' && deadlineDate instanceof Date) return `Every month the ${format(deadlineDate, 'dd')}th`
  if (trigger === 'weekly' && deadlineDate instanceof Date) return `Every week on ${format(deadlineDate, 'EEEE')}`
  if (trigger === 'flightTime' && limit) return `After ${limit} flight hours`
  if (trigger === 'recurring-hour' && limit) return `Every ${limit} flight hours`
  if (trigger === 'recurring-flight' && limit) return `Every ${limit} flights`
}
