import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Dialog, DialogTitle, useTheme, useMediaQuery, IconButton, DialogContent, DialogActions } from '@mui/material'
import WriteAircraftForm from './WriteAircraftForm'
import { Close } from '@mui/icons-material'
import CTAButton from '../_library/CTAButton'
import { useNavigate } from 'react-router-dom'
import { AIRCRAFTS } from '../../_constants/routes'


export const WriteAircraftDialog = ({ open, onClose, aircraft }) => {

  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))
  const formRef = useRef()
  const navigate = useNavigate()

  const [submitted, setSubmitted] = useState(false)

  return (
    <Dialog
      open={open || false}
      onClose={onClose}
      aria-labelledby='form-dialog-title'
      fullScreen={!isUpMd}
      sx={{
        '& .MuiDialog-paperWidthSm': {
          maxWidth: 700,
        },
        '& .MuiInput-underline::before': {
          borderBottomColor: 'rgba(0,0,0,.1)',
        },
      }}
    >
      <DialogTitle>
        {!aircraft.id && 'New aircraft'}
        &nbsp;
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'grey.500',
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ px: 0 }}>
        <WriteAircraftForm formRef={formRef} setSubmitted={setSubmitted} onComplete={id => navigate(AIRCRAFTS + '/' + id + '/overview')} aircraft={aircraft} />
      </DialogContent>
      <DialogActions>
        <CTAButton type='submit' fullWidth onClick={() => formRef.current.requestSubmit()} disabled={submitted}>
          {aircraft?.id ? 'Mettre à jour' : 'Créer'}
        </CTAButton>
      </DialogActions>
    </Dialog>
  )
}

WriteAircraftDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  aircraft: PropTypes.object,
}

export default WriteAircraftDialog
