import { storage } from '../firebase'
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage'

export const useStorage = folder => {
  
  const uploadFileAction = (file, name) =>
    uploadBytes(ref(storage, `${folder}/${name || file.name}`), file)
      .then(snapshot => getDownloadURL(ref(storage, snapshot.ref.fullPath)))

  return { uploadFileAction }
}
