/* eslint-disable react/display-name */
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { Box, Chip, IconButton, Link, Tooltip, useMediaQuery, useTheme } from '@mui/material'
import MUIDataTable from 'mui-datatables'
import Loading from '../_library/Loading'
import { format, differenceInSeconds } from 'date-fns'
import PropTypes from 'prop-types'
import { Add, Check, Clear, LocalGasStation } from '@mui/icons-material'
import EditItemDialog from './EditItemDialog'
import LiquidGraph from '../_library/LiquidGraph'
import ItemCell from './ItemCell'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'
import { AIRCRAFT_COLLECTION, ITEM_COLLECTION, TANK_COLLECTION, TEST_COLLECTION, USER_COLLECTION } from '../../_constants/globals'
import { mergeCollections } from '../../_helpers/jsHelper'
import { useSelector } from 'react-redux'


const ItemTable = ({ testId, aircraftId, showOpenItems }) => {

  const theme = useTheme()
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'))
  const userHooks = useFirestore(USER_COLLECTION)
  const users = userHooks.getDocs()
  const tankHooks = useFirestore(TANK_COLLECTION)
  const dbTanks = tankHooks.getDocs()
  const testHooks = useFirestore(TEST_COLLECTION)
  const tests = testHooks.getDocs()
  const itemHooks = useFirestore(ITEM_COLLECTION, { storeAs: 'ItemTable' })
  const items = itemHooks.getDocs()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const readOnlyMode = useSelector(state => state.data.readOnlyMode)

  const [selectedItem, setSelectedItem] = useState(null)

  const test = useMemo(() => tests.find(t => t.id === testId), [tests, testId])

  useEffect(() => {
    let where
    if (testId && showOpenItems) where = [['aircraftRef', '==', test.aircraftRef]]
    else if (testId) where = [['testRef', '==', testHooks.getDocRef(testId)]]
    else if (aircraftId) where = [['aircraftRef', '==', aircraftHooks.getDocRef(aircraftId)]]
    itemHooks.listen(pickBy({
      where,
      orderBy: [
        ['testStartDate', 'asc'],
        ['label', 'asc'],
      ],
    }))
    return itemHooks.unsubscribe
  }, [testId, aircraftId, showOpenItems, test?.aircraftRef])

  const sortedData = useMemo(() => {
    if (testId && showOpenItems)
      return items?.sort(i => i.type === 'fuelRequest' ? -1 : 1).filter(i => !i.testRef || i.testRef.id === testId)
    else if (testId)
      return items?.sort(i => i.type === 'fuelRequest' ? -1 : 1)
    else if (testId === null)
      return items?.sort(i => i.type === 'fuelRequest' ? -1 : 1).filter(i => !i.testRef)
    else return items
  }, [items])

  const selectItem = useCallback(id => sortedData && setSelectedItem( id === null ? null : sortedData[id] || pickBy({
    type: id,
    label: id === 'fuelRequest'
      ? `${test?.label.split('-')[2] || 'XXXX'}-Fuel and load request`
      : `XXXX-${test ? sortedData.filter(i => i.type === 'item').length + 1 : ''}`,
    testRef: testId && id === 'fuelRequest' && testHooks.getDocRef(testId),
  })), [sortedData])

  const data = useMemo(() =>
    sortedData?.map(({ test, defect, defectAuthor, defectDate, action, actionAuthor, actionDate, captainCheck, tanks, type, captainCheckAuthor, captainCheckDate, label, actionHil, defectHil, fulFilledRequest, actionAttachmentUrl }) => {
      const unionTanks = tanks ? mergeCollections(tanks, dbTanks, 'id') : null
      return [
        test?.label,
        { defect, defectAuthor, defectDate, tanks: unionTanks, type, label, defectHil },
        { action, actionAuthor, actionDate, actionHil, fulFilledRequest, actionAttachmentUrl },
        { captainCheck, captainCheckAuthor, captainCheckDate },
      ]
    }), [sortedData, users, dbTanks])

  if (!sortedData) return <Loading />
  else return (
    <Box sx={{ mx: 'auto', maxWidth: '1200px' }}>
      <MUIDataTable
        title={testId === null ? 'Open items' : 'TLB items'}
        data={data}
        columns={[
          {
            name: 'Test',
            options: {
              display: testId !== undefined ? 'false' : 'true',
            },
          },
          {
            name: 'Defect',
            options: {
              filter: false,
              customBodyRender: ({ defect, defectAuthor, defectDate, tanks, type, label, defectHil }) => <ItemCell
                title={label}
                user={defectAuthor}
                subheader={defectAuthor?.firstname + ' le ' + (!!defectDate && format(defectDate, 'dd-MM-yyyy'))}
                content={<>
                  {type === 'fuelRequest' && tanks && <LiquidGraph tanks={tanks} field='request' disabled/>}
                  {defectHil && <Chip size='medium' label={`Close HIL n°${defectHil.number} - ${defectHil.title}`} title={defectHil.description} sx={{ maxWidth: 400 }} />}
                  <Box my={1}>{defect}</Box>
                </>}
              />,
              sortCompare: order => (a, b) => differenceInSeconds(b.data.defectDate, a.data.defectDate) * (order === 'asc' ? 1 : -1),
            },
          },
          {
            name: 'Action',
            options: {
              filter: false,
              customBodyRender: ({ action, actionAuthor, actionDate, actionHil, fulFilledRequest, actionAttachmentUrl }) => (action || fulFilledRequest || actionHil || actionAttachmentUrl) && <ItemCell
                user={actionAuthor}
                subheader={actionAuthor?.firstname + ' le ' + (!!actionDate && format(actionDate, 'dd-MM-yyyy'))}
                content={<>
                  {fulFilledRequest && <Box><i>Fuel done</i></Box>}
                  {actionHil && <Chip size='medium' label={`Open HIL n°${actionHil.number} - ${actionHil.title}`} title={actionHil.description} sx={{ maxWidth: 400 }} />}
                  <Box my={1}>{action}</Box>
                  {actionAttachmentUrl && <Link
                    href={actionAttachmentUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    underline='always'
                    sx={{ color: 'grey.500', cursor: 'pointer', fontSize: '.8rem', fontStyle: 'italic' }}
                  >
                    {decodeURIComponent(actionAttachmentUrl.split('?')[0].split('actions%2F')[1])}
                  </Link>}
                </>}
              />,
              sortCompare: order => (a, b) => differenceInSeconds(b.data.actionDate, a.data.actionDate) * (order === 'asc' ? 1 : -1),
            },
          },
          {
            name: 'Captain check',
            options: {
              download: false,
              filter: false,
              sort: false,
              viewColumns: false,
              // eslint-disable-next-line
              customBodyRender: ({ captainCheck, captainCheckAuthor, captainCheckDate }) => captainCheckAuthor && <ItemCell
                user={captainCheckAuthor}
                subheader={captainCheckAuthor?.firstname + ' le ' + (!!captainCheckDate && format(captainCheckDate, 'dd-MM-yyyy'))}
                tooltip={captainCheck ? 'The captain has accepted the fix' : 'The captain has to accept fix before next test flight'}
                content={captainCheck ? <Check /> : <Clear />}
              />,
              sortCompare: order => (a, b) => differenceInSeconds(b.data.captainCheckDate, a.data.captainCheckDate) * (order === 'asc' ? 1 : -1),
            },
          },
        ]}
        options={{
          selectableRows: 'none',
          responsive: 'standard',
          onRowClick: (row, { dataIndex }) => !readOnlyMode && selectItem(dataIndex),
          download: false,
          filter: !testId,
          search: !testId,
          print: false,
          selectableRowsHeader: false,
          viewColumns: !testId,
          pagination: !testId,
          sort: !testId,
          textLabels: { body: { noMatch: 'No item' } },
          setRowProps: () => ({ sx: { verticalAlign: 'top', cursor: !readOnlyMode && 'pointer' } }),
          setTableProps: () => ({ size: isUpXl ? 'medium' : 'small', sx: { p: 1 } }),
          customToolbar: () => (<>
            <Tooltip title='Add an item'>
              <span>
                <IconButton color='primary' disabled={readOnlyMode} onClick={() => selectItem('item')}>
                  <Add/>
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip title='Load and fuel request'>
              <span>
                <IconButton color='primary' disabled={readOnlyMode} onClick={() => selectItem('fuelRequest')}>
                  <LocalGasStation/>
                </IconButton>
              </span>
            </Tooltip>
          </>),
          customSearch: (searchQuery, row) => row.some(cell => {
            if (!cell) return false
            else if (cell && typeof cell === 'string') return cell.includes(searchQuery)
            else if (cell.defect?.includes(searchQuery)) return true
            else if (cell.defectAuthor?.firstname.includes(searchQuery)) return true
            else if (cell?.label?.includes(searchQuery)) return true
            else if (cell?.defectHil?.number?.toString().includes(searchQuery)) return true
            else if (cell?.defectHil?.title?.includes(searchQuery)) return true
            else if (cell?.defectHil?.description?.includes(searchQuery)) return true
            else if (cell?.action?.includes(searchQuery)) return true
            else if (cell?.actionAuthor?.firstname?.includes(searchQuery)) return true
            else if (cell?.actionHil?.number?.toString().includes(searchQuery)) return true
            else if (cell?.actionHil?.title?.includes(searchQuery)) return true
            else if (cell?.actionHil?.description && cell.actionHil?.description.includes(searchQuery)) return true
            else return false
          }),
        }}
      />
      {selectedItem && <EditItemDialog item={selectedItem} onClose={() => selectItem(null)} />}
    </Box>
  )
}

ItemTable.propTypes = {
  testId: PropTypes.string,
  aircraftId: PropTypes.string,
  showOpenItems: PropTypes.bool,
}

export default ItemTable
