import { HIDE_NOTIFICATIONS, RESET_CACHE, SELECT_AIRCRAFT, SELECT_TEST, TOGGLE_ADMIN_MODE, TOGGLE_READ_ONLY_MODE, UPDATE_USER_ERROR, UPDATE_USER_SUCCESS } from '../types'
import { saveReduxState } from '../../_helpers/localStorageHelper'

export const INITIAL_STATE = {
  dataError: null,
  updateUserSuccess: null,
  adminMode: false,
}

const dataReducer = (state = INITIAL_STATE, action) => {
  switch(action.type){

    case HIDE_NOTIFICATIONS:
      return { ...state,
        dataError: null,
        createOrderAndSessionResult: null,
      }

    case RESET_CACHE:
      return {
        dataError: null,
        createOrderAndSessionResult: null,
      }

    case UPDATE_USER_ERROR:
      console.error('update user error', action.payload)
      return {
        ...state,
        updateUserSuccess: null,
        dataError: action.payload,
      }

    case UPDATE_USER_SUCCESS:
      console.log('update user success')
      return {
        ...state,
        updateUserSuccess: true,
        dataError: null,
      }

    case SELECT_AIRCRAFT:
      console.log('selectedAircraft', action.payload)
      return { ...state, selectedAircraftId: action.payload }
    
    case SELECT_TEST:
      console.log('selectedTest', action.payload)
      return { ...state, selectedTestId: action.payload }

    case TOGGLE_ADMIN_MODE:
      saveReduxState('data', 'adminMode', action.payload)
      return { ...state, adminMode: action.payload }

    case TOGGLE_READ_ONLY_MODE:
      return { ...state, readOnlyMode: action.payload }

    default:
      return state
  }
}

export default dataReducer
