import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, TextField, Dialog, DialogActions, DialogContent, Typography } from '@mui/material'
import { Edit } from '@mui/icons-material'


const InlineEditor = ({ handleSubmit, title, value, multiline, rows, type, inputProps, disabled, titleStyle, hint }) => {
  
  const [open, setOpen] = useState(false)
  const [update, setUpdate] = useState(value)
  const [error, setError] = useState(false)
  
  useEffect(() => {
    setUpdate(value)
  }, [value, setUpdate])
  
  const submit = () =>
    handleSubmit(update)
      .then(() => setOpen(false))
      .catch(err => {
        setError(true)
        console.error(err)
      })
  
  return (
    <div>
      <Typography color='textSecondary' gutterBottom sx={{
        color: 'rgba(0,0,0,.54)',
        display: 'inline-block',
        lineHeight: '20px',
        width: '200px',
        ...titleStyle,
      }}>{title}</Typography>
      <Typography color='textSecondary' gutterBottom sx={{ display: 'inline-block', color: 'black' }}>
        {type === 'password'
          ? value?.replace(/./g, '*')
          : value
        }
      </Typography>
      {!disabled && <Button onClick={() => setOpen(true)} sx={{
        color: 'rgba(0,0,0,.54)',
        ml: 1,
        '&:hover': {
          color: 'black',
          backgroundColor: 'initial',
          minWidth: 40,
        },
      }}>
        <Edit />
      </Button>}
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby='form-dialog-title' fullWidth>
        <DialogContent>
          <TextField
            id={title + 'Field'}
            type={type || 'text'}
            fullWidth
            variant='outlined'
            value={update || ''}
            onChange={e => setUpdate(e.target.value)}
            onKeyPress={e => e.key === 'Enter' && submit()}
            error={error}
            multiline={multiline}
            rows={rows}
            inputProps={inputProps}
            label={title}
            helperText={hint}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} style={{ fontWeight: 600, color: 'rgba(0,0,0,.54)' }}>
            Annuler
          </Button>
          <Button onClick={submit} color='primary' variant='contained' sx={{ fontWeight: 600 }}>
            Valider
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

InlineEditor.propTypes = {
  handleSubmit: PropTypes.func,
  title: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  type: PropTypes.string,
  inputProps: PropTypes.object,
  disabled: PropTypes.bool,
  titleStyle: PropTypes.object,
  hint: PropTypes.string,
}

export default InlineEditor
