import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Box, Card, CardActions, CardContent, CardHeader, Chip, IconButton, Link, Typography } from '@mui/material'
import { format } from 'date-fns'
import { AIRCRAFTS } from '../../_constants/routes'
import { ArrowForward, ExpandMore as ExpandMoreIcon } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { getTypeLabel } from '../../_helpers/dataHelper'
import LiquidGraph from '../_library/LiquidGraph'
import EditActionDialog from '../technicalItems/EditActionDialog'
import ChatBalloon from '../_library/ChatBalloon'
import ExpandMore from '../_library/ExpandMore'


export const AircraftViewerCard = ({ aircraft, items }) => {

  const navigate = useNavigate()

  const [selectedItem, setSelectedItem] = useState(null)
  const [expanded, setExpanded] = useState(false)

  const filteredItems = useMemo(
    () => items.filter(i => i.aircraftRef.id === aircraft.id),
    [items, expanded],
  )

  return (
    <Card key={aircraft.id} sx={{ minWidth: '275px', width: { xs: '100%', md: '33%', xl: '20%' }, mb: 2, mx: .5, position: 'relative' }}>
      <CardHeader
        action={<IconButton onClick={() => navigate(`${AIRCRAFTS}/${aircraft.id}/overview`)} sx={{ color: 'white' }}><ArrowForward /></IconButton>}
        title={aircraft.serial}
        subheader={getTypeLabel(aircraft.type)}
        sx={{
          background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.5)), url("${aircraft.coverUrl}")`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
        titleTypographyProps={{ sx: { color: 'white' } }}
        subheaderTypographyProps={{ sx: { color: 'white' } }}
      />
      <CardContent sx={{ p: 1 }}>
        {filteredItems?.length === 0
          ? <Typography variant='caption' align='center'>No TLB item</Typography>
          : <>
            {filteredItems?.filter(i => !i.actionDate).length
              ? <Typography variant='h5' align='center' bgcolor='warning.main'>ACTION REQUIRED</Typography>
              : <Typography variant='h5' align='center' bgcolor='success.light'>READY</Typography>
            }
            {filteredItems?.filter(i => expanded || !i.actionDate).map(item => <Box key={item.id}>
              <Typography align='center'>{item.label}</Typography>
              {item.defectDate && <ChatBalloon
                title={item.defectAuthor?.firstname + ' ' + item.defectAuthor?.lastname}
                user={item.defectAuthor}
                subtitle={format(item.defectDate, 'dd-MM-yyyy')}
                onClick={() => !item.actionDate && setSelectedItem(item)}
                content={<>
                  {item.type === 'fuelRequest' && item.tanks && <LiquidGraph tanks={item.tanks} field='request' disabled/>}
                  {item.defectHil && <Chip size='medium' label={`Close HIL n°${item.defectHil.number} - ${item.defectHil.title}`} title={item.defectHil.description} sx={{ maxWidth: 400 }} />}
                  <Box>{item.defect}</Box>
                </>}
                sx={{ cursor: !item.actionDate && 'pointer', '&:hover': !item.actionDate && { bgcolor: 'grey.300' } }}
              />}
              {item.actionDate && <ChatBalloon
                orientation='right'
                title={item.actionAuthor?.firstname + ' ' + item.actionAuthor?.lastname}
                user={item.actionAuthor}
                subtitle={format(item.actionDate, 'dd-MM-yyyy')}
                content={<>
                  {item.fulFilledRequest && <Box><i>Fuel done</i></Box>}
                  {item.actionHil && <Chip size='medium' label={`Open HIL n°${item.actionHil.number} - ${item.actionHil.title}`} title={item.actionHil.description} sx={{ maxWidth: 400 }} />}
                  <Box>{item.action}</Box>
                  {item.actionAttachmentUrl && <Link
                    href={item.actionAttachmentUrl}
                    target='_blank'
                    rel='noopener noreferrer'
                    underline='always'
                    sx={{ color: 'grey.500', cursor: 'pointer', fontSize: '.8rem', fontStyle: 'italic' }}
                  >
                    {decodeURIComponent(item.actionAttachmentUrl.split('?')[0].split('actions%2F')[1])}
                  </Link>}
                </>}
              />}
            </Box>)}
          </>
        }
      </CardContent>
      {filteredItems?.filter(i => i.actionDate).length > 0 && <CardActions disableSpacing>
        <ExpandMore
          expand={expanded}
          onClick={() => setExpanded(prev => !prev)}
          aria-expanded={expanded}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>}
      {selectedItem && <EditActionDialog item={selectedItem} onClose={() => setSelectedItem(null)} />}
    </Card>
  )
}

AircraftViewerCard.propTypes = {
  aircraft: PropTypes.object,
  items: PropTypes.array,
}

export default AircraftViewerCard
