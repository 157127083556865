import { ROLE_CAPTAIN, ROLE_ENGINEER, ROLE_MECHANICS } from './globals'

export const REGEX_MAIL = /\S+@\S+\.\S+/
export const FRANCAZAL_LAT_LNG = { lat: 43.54560089111328, lng: 1.3674999475479126 }
export const INTEGRAL_TANKS_STANDARD = [
  {
    order: 0,
    title: 'main',
    capacity: 75,
    quantity: 0,
    type: 'fuel',
    unit: 'L',
  },
  {
    order: 1,
    title: 'leftWing',
    capacity: 45,
    quantity: 0,
    type: 'fuel',
    unit: 'L',
  },
  {
    order: 2,
    title: 'rightWing',
    capacity: 45,
    quantity: 0,
    type: 'fuel',
    unit: 'L',
  },
  {
    order: 3,
    title: 'lubricant',
    capacity: 8,
    quantity: 0,
    type: 'lubricant',
    unit: 'qt',
  },
]
export const INTEGRAL_ELEC_TANKS_STANDARD = [
  {
    order: 0,
    title: 'left',
    capacity: 10,
    quantity: 0,
    type: 'water',
    unit: 'L',
  },
  {
    order: 1,
    title: 'right',
    capacity: 10,
    quantity: 0,
    type: 'water',
    unit: 'L',
  },
]
export const TASKS_TEMPLATE = [
  {
    majorRole: ROLE_ENGINEER,
    moment: 'before',
    order: 1,
    slug: 'test-order',
    title: 'Write test order',
    dialog: 'Did you write a test order ?',
  },
  {
    majorRole: ROLE_ENGINEER,
    moment: 'before',
    order: 2,
    slug: 'cer',
    title: 'CER request',
    dialog: 'Did you send CER request form ?',
  },
  {
    majorRole: ROLE_ENGINEER,
    moment: 'before',
    order: 3,
    slug: 'ppr',
    title: 'PPR deposit',
    dialog: 'Did you make PPR deposit ?',
  },
  {
    majorRole: ROLE_MECHANICS,
    moment: 'before',
    order: 4,
    slug: 'mechanic-preflight',
    title: 'Mechanic preflight',
    dialog: 'Have you got preflight checks completed ?',
  },
  {
    majorRole: ROLE_CAPTAIN,
    moment: 'before',
    order: 5,
    slug: 'captain-preflight',
    title: 'Captain preflight',
    dialog: 'Have you got preflight checks completed ?',
  },
  {
    majorRole: ROLE_CAPTAIN,
    moment: 'after',
    order: 6,
    slug: 'captain-final-check',
    title: 'Captain final check',
    dialog: 'Do you validate test flight ?',
  },
  {
    majorRole: ROLE_ENGINEER,
    moment: 'after',
    order: 7,
    slug: 'test-report',
    title: 'Write test report',
    dialog: 'Did you write a test report ?',
  },
]
export const EASA_FLIGHT_PURPOSES = [
  { code: 1, title: 'Development' },
  { code: 2, title: 'Showing compliance with regulations or certification specifications' },
  { code: 3, title: 'Design organisations or production organisations crew training' },
  { code: 4, title: 'Production flight testing of new production aircraft' },
  { code: 5, title: 'Flying aircraft under production between production facilities' },
  { code: 6, title: 'Flying the aircraft for customer acceptance' },
  { code: 7, title: 'Delivering or exporting the aircraft' },
  { code: 8, title: 'Flying the aircraft for Authority acceptance' },
  { code: 9, title: 'Market survey, including customer’s crew training' },
  { code: 10, title: 'Exhibition and air show' },
  { code: 11, title: 'Flying the aircraft to a location where maintenance or airworthiness review are to be performed, or to a place of storage' },
  { code: 12, title: 'Flying an aircraft at a weight in excess of its maximum certificated take-off weight for flight beyond the normal range over water, or over land areas where adequate landing facilities or appropriate fuel is not available' },
  { code: 13, title: 'Record breaking, air racing or similar competition' },
  { code: 14, title: 'Flying aircraft meeting the applicable airworthiness requirements before conformity to the environmental requirements is shown' },
  { code: 15, title: 'For non-commercial flying activity on individual non-complex aircraft or types for which a certificate of airworthiness or restricted certificate of airworthiness is not appropriate' },
  { code: 16, title: 'Flying an aircraft for troubleshooting purposes or to check the functioning of one or more systems, parts or appliances after maintenance.' },
]
