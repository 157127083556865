import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import DeleteButton from '../_library/DeleteButton'
import { AIRCRAFT_COLLECTION, OPERATION_COLLECTION } from '../../_constants/globals'
import Loading from '../_library/Loading'
import { pickBy } from 'lodash'
import { useFirestore } from '../../hooks/useFirestore'
import { useSelector } from 'react-redux'
import { DatePicker } from '@mui/x-date-pickers'
import { getRecurrenceTip } from '../../_helpers/dataHelper'


const EditOperationDialog = ({ operation, onClose }) => {

  const operationHooks = useFirestore(OPERATION_COLLECTION)
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)
  const selectedAircraftId = useSelector(state => state.data.selectedAircraftId)

  const [title, setTitle] = useState(operation.title)
  const [description, setDescription] = useState(operation.description)
  const [trigger, setTrigger] = useState(operation.trigger)
  const [deadlineDate, setDeadlineDate] = useState(operation.deadlineDate)
  const [limit, setLimit] = useState(operation.flightHourLimit)
  const [submitted, setSubmitted] = useState(false)

  const helperText = useMemo(() => getRecurrenceTip(trigger, deadlineDate, limit), [trigger, deadlineDate, limit])

  const handleSubmit = e => {
    e.preventDefault()
    setSubmitted(true)
    const obj = pickBy({
      ...operation,
      aircraftRef: aircraftHooks.getDocRef(selectedAircraftId),
      title,
      description,
      trigger,
      deadlineDate,
      flightHourLimit: limit,
    })
    const handler = data => operation.id ? operationHooks.updateDoc(operation.id, data) : operationHooks.addDoc(data)
    return handler(obj)
      .then(onClose)
      .finally(() => setSubmitted(false))
  }

  if (!operation) return <Loading />
  else return (
    <Dialog open onClose={onClose} component='form' onSubmit={handleSubmit}>
      <DialogTitle>Scheduled operation</DialogTitle>
      <DialogContent>
        <TextField
          variant='outlined'
          margin='dense'
          id='title'
          label='Title'
          fullWidth
          value={title || ''}
          onChange={e => setTitle(e.target.value)}
        />
        <TextField
          variant='outlined'
          margin='dense'
          id='description'
          label='Description'
          fullWidth
          multiline
          rows={4}
          value={description || ''}
          onChange={e => setDescription(e.target.value)}
        />
        <Paper sx={{ bgcolor: 'grey.100', p: 1 }}>
          <Typography>Recurrence</Typography>
          <FormControl fullWidth sx={{ mt: 1 }}>
            <InputLabel id='trigger-select-label'>Type of trigger</InputLabel>
            <Select
              labelId='trigger-select-label'
              id='trigger-select'
              value={trigger || ''}
              label='Type of trigger'
              onChange={e => setTrigger(e.target.value)}
            >
              <MenuItem value='date'>Date</MenuItem>
              <MenuItem value='yearly'>Yearly</MenuItem>
              <MenuItem value='monthly'>Monthly</MenuItem>
              <MenuItem value='weekly'>Weekly</MenuItem>
              <MenuItem value='flightTime'>Flight time</MenuItem>
              <MenuItem value='recurring-hour'>Every X hours</MenuItem>
              <MenuItem value='recurring-flight'>Every X flights</MenuItem>
            </Select>
          </FormControl>
          {['date', 'yearly', 'monthly', 'weekly'].includes(trigger) && <DatePicker
            inputFormat='dd-MM-yyyy'
            margin='dense'
            id='deadline-date'
            label='Date'
            views={trigger === 'date' ? ['day', 'month', 'year'] : trigger === 'yearly' ? ['day', 'month'] : ['day']}
            value={deadlineDate || null}
            onChange={setDeadlineDate}
            sx={{ mt: 1, width: '100%' }}
          />}
          {['flightTime', 'recurring-hour', 'recurring-flight'].includes(trigger) && <TextField
            variant='outlined'
            margin='dense'
            id='limit'
            label={trigger === 'recurring-flight' ? 'Flights' : 'Flight hours'}
            type='number'
            fullWidth
            value={limit || ''}
            onChange={e => setLimit(e.target.value)}
          />}
          <FormHelperText>{helperText}</FormHelperText>
        </Paper>
      </DialogContent>
      <DialogActions>
        <Button type='submit' disabled={submitted}>Submit</Button>
        {operation.id && <DeleteButton deleteAction={() => operationHooks.deleteDoc(operation.id).then(onClose)} text='Supprimer' />}
        <Button onClick={onClose} color='primary'>Fermer</Button>
      </DialogActions>
    </Dialog>
  )
}

EditOperationDialog.propTypes = {
  operation: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default EditOperationDialog
