import React from 'react'
import PropTypes from 'prop-types'
import { Typography, Card, CardContent, Box, CardHeader, IconButton, CardMedia, CardActions, Tooltip, Button } from '@mui/material'
import { format, formatDistanceToNow } from 'date-fns'
import { History, Announcement, EventAvailable, EventBusy, ArrowForward, Archive, Unarchive } from '@mui/icons-material'
import PotentialGraph from './PotentialGraph'
import { AIRCRAFT_COLLECTION } from '../../_constants/globals'
import { getTypeLabel } from '../../_helpers/dataHelper'
import { AIRCRAFTS } from '../../_constants/routes'
import { useNavigate } from 'react-router-dom'
import { useFirestore } from '../../hooks/useFirestore'


export const AircraftBigCard = ({ aircraft, sx = {} }) => {
  
  const navigate = useNavigate()
  const aircraftHooks = useFirestore(AIRCRAFT_COLLECTION)

  const { serial, type, coverUrl, bookable, id, archived } = aircraft
  
  return (
    <Card sx={{ minWidth: '275px', mb: 4, p: 1, position: 'relative', ...sx }}>
      <CardHeader
        action={<IconButton aria-label='settings' onClick={() => navigate(`${AIRCRAFTS}/${id}/overview`)}><ArrowForward /></IconButton>}
        title={serial}
        subheader={getTypeLabel(type)}
      />
      <CardMedia component='img' height='194' image={coverUrl} alt={serial} />
      <CardContent>
        {aircraft.comments && <Box sx={{ bgcolor: 'grey.100', p: 1, borderRadius: 1 }}>
          <Typography variant='body1'><strong><Announcement sx={{ verticalAlign: 'bottom' }} />&nbsp;Remarques</strong></Typography>
          <Typography variant='body1'>{aircraft.comments}</Typography>
        </Box>}
        
        {aircraft.followUp && <Box sx={{ m: 1, mb: 4 }}>
          <Typography variant='body1' mb={1}><strong>Potentiel</strong></Typography>
          <PotentialGraph aircraft={aircraft} />
        </Box>}
        
        {aircraft.lastFlight && <Box sx={{ bgcolor: 'grey.100', p: 1, borderRadius: 1 }}>
          <Typography variant='body1'><strong><History sx={{ verticalAlign: 'bottom' }} />&nbsp;Dernier
            vol</strong></Typography>
          <Typography variant='body1'>
            {formatDistanceToNow(aircraft.lastFlight.startDate.toDate(), { addSuffix: true }) + ' (' + format(aircraft.lastFlight.startDate.toDate(), 'dd-MM-yyyy') + ')'}
          </Typography>
          <Typography variant='body1'>
            {aircraft.lastFlight.pilot}{aircraft.lastFlight.instructor && ` avec ${aircraft.lastFlight.instructor}`}
          </Typography>
          {aircraft.lastFlight.type && <Typography variant='body1'>{aircraft.lastFlight.type.name}</Typography>}
          {aircraft.lastFlight.arrival && <Typography variant='body1'>à destination de {aircraft.lastFlight.arrival}</Typography>}
        </Box>}
        {aircraft.particularitiesUrl && <Button component='a' target='_blank' rel='noopener noreferrer' href={aircraft.particularitiesUrl}>Aircraft Particularities</Button> }
      </CardContent>
      <CardActions disableSpacing>
        <Tooltip title={bookable ? 'The aircraft is currently bookable for tests' : 'The aircraft is currently not bookable for tests'}>
          <IconButton onClick={() => aircraftHooks.updateDoc(id, { bookable: !bookable })}>
            {bookable ? <EventAvailable /> : <EventBusy />}
          </IconButton>
        </Tooltip>
        <Tooltip title={archived ? 'The aircraft is archived' : 'The aircraft is not archived'}>
          <IconButton onClick={() => aircraftHooks.updateDoc(id, { archived: !archived })}>
            {archived ? <Archive /> : <Unarchive />}
          </IconButton>
        </Tooltip>
      </CardActions>
    </Card>
  )
}

AircraftBigCard.propTypes = {
  aircraft: PropTypes.object.isRequired,
  sx: PropTypes.object,
}

export default AircraftBigCard
